import CustomColorPicker from '@/pages/app/components/CustomColorPicker';
import PlaceFields from '@/pages/app/components/PlaceFields';
import ToolFields from '@/pages/app/components/ToolFields';
import { IAttribut } from '@/types/attribut.model';
import { IField } from '@/types/field.model';
import { IResource } from '@/types/resource.model';
import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import Axios from 'axios';
import { keyBy, mapValues } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  departmentId?: string;
  resource?: IResource;
  updateResource: (updatedResource: IResource) => void;
}

const Parameters: React.FC<Props> = ({ className, resource, updateResource, departmentId }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = useForm();
  const [customFields, setCustomFields] = useState<IField[]>([]);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [resource]);

  const onFinish = (values: { [index: string]: any }) => {
    if (resource?.attributes?.length) {
      setIsSaving(true);
      Axios.patch(
        `${process.env.REACT_APP_API_URL}/v3/resources/${resource!.id!}/${resource!.attributes[0]!.id!}`,
        {
          departmentId,
          ...values,
        },
        {
          params: {
            departmentId,
          },
        },
      )
        .then((response) => {
          const updatedAttribut = response.data.resource.attributes[0] as IAttribut;
          const attributesCopy = [...(resource?.attributes || [])];
          const attributIndex = attributesCopy.findIndex((x) => x.id === updatedAttribut.id);
          if (~attributIndex) {
            attributesCopy[attributIndex] = updatedAttribut;
            const updatedResource = {
              ...resource,
              attributes: attributesCopy,
            };
            updateResource(updatedResource);
          }
          setIsSaving(false);
        })
        .catch((error) => {
          message.error(t('SETTINGS.MESSAGE_UPDATING_ERROR'));
          console.error(error);
          setIsSaving(false);
        });
    }
  };

  const onValuesChange = (changedValues: Store, values: Store) => {
    setFormHasChanged(true);
  };

  const onReset = () => {
    form.resetFields();
    let fieldsValue: any = {};
    if (resource?.attributes?.length) {
      const attribut = resource?.attributes[0];
      fieldsValue = mapValues(keyBy(attribut.fields, 'name'), 'value');
      const { lat, lng, codeType, code, address, background = '#000000' } = attribut;
      fieldsValue = {
        ...fieldsValue,
        lat,
        lng,
        codeType,
        code,
        background,
        'search-input': address,
      };
    }
    form.setFieldsValue(fieldsValue);
    setCustomFields(resource?.fields?.filter((x) => !x.locked) || []);
    setFormHasChanged(false);
  };

  return (
    <div className={className}>
      <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish} size="large">
        <div style={{ marginBottom: 20 }}>
          <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {resource?.resourceType === 'PLACE' && (
            <PlaceFields
              form={form}
              onChange={() => {
                setFormHasChanged(true);
              }}
            />
          )}
          {resource?.resourceType === 'TOOL' && <ToolFields form={form} />}
        </div>
        {!!customFields.length && (
          <div>
            {customFields.map((field, index) => {
              return (
                <Form.Item
                  key={`field_${field.id}`}
                  name={field.name}
                  label={field.label == 'CIAO' ? `${field.label} (${t('GLOBAL.CIAO_FORMAT_MESSAGE')})` : field.label}
                  rules={[{ required: field.mandatory, message: 'Missing name' }]}
                >
                  {field.label == 'CIAO' ? (
                    <Input pattern="^[A-HJ-NP-Z0-9]{13}$" title={t('GLOBAL.CIAO_FORMAT_MESSAGE')} />
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              );
            })}
          </div>
        )}
        <Form.Item
          label={t('GLOBAL.COLOR')}
          shouldUpdate={(prevValues, curValues) => prevValues.background !== curValues.background}
        >
          {() => {
            const background = form.getFieldValue('background');
            return (
              <React.Fragment>
                <Form.Item name="background" style={{ display: 'none' }}>
                  <Input hidden id="skill-background" />
                </Form.Item>
                <CustomColorPicker form={form} color={background} property="background" />
              </React.Fragment>
            );
          }}
        </Form.Item>
        {resource && (
          <React.Fragment>
            <Form.Item className={`actions ${formHasChanged ? 'active' : ''}`}>
              <Button loading={isSaving} type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
                {t('GLOBAL.SAVE')}
              </Button>
              <Button type="default" className="grey" onClick={onReset}>
                {t('GLOBAL.CANCEL')}
              </Button>
            </Form.Item>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};

const ParametersStyled = styled(Parameters)`
  form {
    .ant-row {
      display: block;
      > .ant-col {
        > div {
          display: block;
          padding-bottom: 0;
          text-align: left;
        }
      }
    }
    > .actions {
      position: fixed;
      width: calc(100vw - 740px);
      background: white;
      bottom: -80px;
      margin: 0;
      margin-left: -20px;
      display: flex;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 3px 3px 0 0;
      transition: bottom 0.2s;

      &.active {
        transition-timing-function: ease-in;
        bottom: 0px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export default ParametersStyled;
