import DisplayNameColumn from '@/pages/app/components/DisplayNameColumn';
import Tag from '@/pages/app/components/Tag';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IAttribut } from '@/types/attribut.model';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { ISection } from '@/types/section.model';
import { IShift } from '@/types/shift.model';
import { ISkill } from '@/types/skill.model';
import { IUser } from '@/types/user.model';
import { handleError, isFeatureEnabled, isNullOrUndefined } from '@/utils';
import { CheckSquareFilled, CloseOutlined, CloseSquareFilled } from '@ant-design/icons';
import { Button, Checkbox, Form, Modal, Space, Table, Tooltip, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IShyftMarket } from '..';
moment.tz.setDefault('Atlantic/Reykjavik');

interface IShyftMarketDetail {
  id: string;
  start: number;
  end: number;
  status: string;
  section?: ISection;
  skills: ISkill[];
  attributes: IAttribut[];
  showDimona: boolean;
  users: IUser[];
  count: number;
  count_id?: string;
}

interface Props {
  className?: string;
  activeDepartment?: IDepartment;
  shift: IShyftMarket | null;
  onClose: (requestHasPending?: boolean) => void;
  onAssign: () => void;
}

const ModalDetail: React.FC<Props> = ({ className, activeDepartment, shift, onClose, onAssign }) => {
  const { i18n, t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { features, activeSection, users },
  } = useContext(AppContext);
  const [shiftDetail, setShiftDetail] = useState<IShyftMarketDetail | null>(null);
  const [tags, setTags] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [planned, setPlanned] = useState<any>(null);
  const [loadingPlanned, setLoadingPlanned] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [plannedColumns, setPlannedColumns] = useState<any[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (shift) {
      let mounted = true;
      const cancelTokenSource = axios.CancelToken.source();
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/shyfts-market/${shift.id}`, {
          cancelToken: cancelTokenSource.token,
          params: {
            departmentId: activeDepartment?.id,
          },
        })
        .then(({ data }) => {
          if (mounted) {
            setShiftDetail(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
            console.error(error);
          }
          if (mounted) {
            setLoading(false);
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [shift]);

  useEffect(() => {
    if (!shift || !shiftDetail) return;
    setLoadingPlanned(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/shifts`, {
        params: {
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
          startDate: shift.start,
          endDate: shift.end,
          picker: 'day',
        },
      })
      .then(({ data }) => {
        setPlanned(
          data.shifts
            .filter((shift: IShift) => shift.userRecordId != null)
            .filter((shift: IShift) => shift.shyftType == 1)
            .filter((shift: IShift) =>
              moment
                .unix(shift.start!)
                .isBetween(moment.unix(shiftDetail.start), moment.unix(shiftDetail.end), undefined, '[)'),
            ),
        );
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingPlanned(false);
      });
  }, [shift, shiftDetail]);

  useEffect(() => {
    setPlannedColumns([
      {
        title: t('GLOBAL.NAME'),
        key: 'displayName',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShift) => {
          const user = users.find((user) => user.recordId == record.userRecordId);
          if (!user) return null;
          return (
            <>
              {user.displayName} {record.homeworking && <i className="icon-home" />}
            </>
          );
        },
      },
      {
        title: t('GLOBAL.START'),
        key: 'start',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShift) => {
          return <>{moment.unix(record.start!).format('HH:mm')}</>;
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShift) => {
          return <>{moment.unix(record.end!).format('HH:mm')}</>;
        },
      },
      ...(isFeatureEnabled(features, FEATURES.SECTIONS)
        ? [
            {
              title: t('GLOBAL.SECTION'),
              key: 'section',
              // eslint-disable-next-line react/display-name
              render: (_text: string, record: IShift) => {
                if (!record.section) return;
                return (
                  <Tag
                    key={`shift_${record.id}_${record.section.id}`}
                    size="large"
                    color={record.section.color}
                    background={record.section.background}
                    name={record.section.name}
                  />
                );
              },
            },
          ]
        : []),
      ...(isFeatureEnabled(features, FEATURES.SKILLS)
        ? [
            {
              title: t('GLOBAL.SKILLS'),
              key: 'skills',
              // eslint-disable-next-line react/display-name
              render: (_text: string, record: IShift) => {
                if (!record.skills || record.skills.length == 0) return;
                return record.skills.map((skill) => (
                  <Tag
                    key={`shift_${record.id}_${skill.id}`}
                    size="large"
                    color={skill.color}
                    background={skill.background}
                    name={skill.name}
                  />
                ));
              },
            },
          ]
        : []),
      ...(isFeatureEnabled(features, FEATURES.ATTRIBUTES)
        ? [
            {
              title: t('GLOBAL.ATTRIBUTES'),
              key: 'attributes',
              // eslint-disable-next-line react/display-name
              render: (_text: string, record: IShift) => {
                if (!record.attributes || record.attributes.length == 0) return;
                return record.attributes.map((attribute) => (
                  <Tag
                    key={`shift_${record.id}_${attribute.id}`}
                    size="large"
                    color={attribute.color}
                    background={attribute.background}
                    name={attribute.name}
                  />
                ));
              },
            },
          ]
        : []),
    ]);
  }, [planned, users]);

  useEffect(() => {
    form.resetFields();
    if (shiftDetail) {
      const { section, skills, attributes } = shiftDetail;
      const tags = [];
      if (section) {
        tags.push(section);
      }
      if (skills?.length) {
        tags.push(...skills);
      }
      if (attributes?.length) {
        tags.push(...attributes);
      }
      setTags(tags);
    }
    return () => {
      setTags([]);
    };
  }, [shiftDetail]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    const columns: any[] = [
      {
        title: t('GLOBAL.NAME'),
        key: 'displayName',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IUser) => {
          return <DisplayNameColumn user={record} />;
        },
        sorter: (a: IUser, b: IUser) => a.displayName?.localeCompare(b.displayName || ''),
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'category',
        dataIndex: 'categoryName',
        sorter: (a: any, b: any) => a.categoryName?.localeCompare(b.categoryName || ''),
      },
      {
        title: t('GLOBAL.COST'),
        key: 'price',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IUser) => {
          return (
            <Space>
              {!isNullOrUndefined(record?.price) &&
                new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: activeDepartment?.currency || 'EUR',
                }).format(record.price || 0)}
            </Space>
          );
        },
        sorter: (a: IUser, b: IUser) => (a.price || 0) - (b.price || 0),
      },
      {
        align: 'center',
        title: t('GLOBAL.QUARTER'),
        key: 'quarter',
        dataIndex: ['stats', 'quarter'],
        sorter: (a: any, b: any) => (a.stats?.quarter || 0) - (b.stats?.quarter || 0),
      },
      {
        align: 'center',
        title: t('GLOBAL.WEEK'),
        key: 'week',
        dataIndex: ['stats', 'week'],
        sorter: (a: any, b: any) => (a.stats?.week || 0) - (b.stats?.week || 0),
      },
      {
        align: 'center',
        title: t('GLOBAL.SHIFTS'),
        key: 'shifts',
        dataIndex: ['stats', 'shifts'],
        sorter: (a: any, b: any) => (a.stats?.shifts || 0) - (b.stats?.shifts || 0),
      },
      ...(isFeatureEnabled(features, FEATURES.SKILLS)
        ? [
            {
              title: t('GLOBAL.SKILLS'),
              key: 'skills',
              render: (_text: string, record: IUser) => {
                const { recordId, skills } = record;
                return skills?.map(({ id, color, background, name }) => (
                  <Tag
                    key={`shyftMarketUser_${recordId}_${id}`}
                    size="large"
                    color={color}
                    background={background}
                    name={name}
                  />
                ));
              },
            },
          ]
        : []),
      ...((shiftDetail?.count || 1) > 1
        ? [
            {
              key: 'actions',
              // eslint-disable-next-line react/display-name
              render: (_text: string, record: IUser) => {
                if (record.denied) {
                  return <span style={{ color: colors.red }}>{t('GLOBAL.REFUSED')}</span>;
                }
                if (record.deleted) {
                  return <span style={{ color: colors.red }}>{t('SHYFTS_MARKET.APPLICATION_DELETED')}</span>;
                }

                return (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                    <Checkbox value={`${record.recordId}`} />
                    <Tooltip overlay={<span>{t('GLOBAL.REFUSE')}</span>}>
                      <div style={{ backgroundColor: colors.red, display: 'flex', alignItems: 'center', padding: 2 }}>
                        <CloseOutlined
                          className="white"
                          onClick={(e) => onWantToRefuse(record)}
                          style={{ fontSize: '14px', borderRadius: 3 }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                );
              },
            },
          ]
        : shiftDetail?.status == 'PENDING'
        ? [
            {
              key: 'actions',
              align: 'right',
              // eslint-disable-next-line react/display-name
              render: (text: any, record: IUser) => {
                if (record.denied) {
                  return <span style={{ color: colors.red }}>{t('GLOBAL.REFUSED')}</span>;
                }
                if (record.deleted) {
                  return <span style={{ color: colors.red }}>{t('SHYFTS_MARKET.APPLICATION_DELETED')}</span>;
                }
                return (
                  <>
                    <Tooltip overlay={<span>{t('GLOBAL.ASSIGN')}</span>}>
                      <CheckSquareFilled className="green" onClick={(e) => onWantAssign(record)} />
                    </Tooltip>
                    <Tooltip overlay={<span>{t('GLOBAL.REFUSE')}</span>}>
                      <CloseSquareFilled className="red" onClick={(e) => onWantToRefuse(record)} />
                    </Tooltip>
                  </>
                );
              },
            },
          ]
        : []),
    ];
    if (shiftDetail?.showDimona) {
      columns.splice(4, 0, {
        align: 'center',
        title: 'Dimona',
        key: 'dimona',
        dataIndex: ['stats', 'dimona'],
        sorter: (a: any, b: any) => (a.stats?.dimona || 0) - (b.stats?.dimona || 0),
      });
    }
    setColumns(columns);
  }, [i18n.language, shiftDetail]);

  const onWantAssign = (user: IUser) => {
    Modal.confirm({
      title: t('SHYFTS_MARKET.MODAL_ASSIGN_CONFIRMATION.TITLE'),
      icon: null,
      content: t('SHYFTS_MARKET.MODAL_ASSIGN_CONFIRMATION.CONTENT', { user: user.displayName }),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('SHYFTS_MARKET.MODAL_ASSIGN_CONFIRMATION.CONFIRM_BTN'),
      onOk: () => {
        if (!shift) return;
        return axios
          .patch(
            `${process.env.REACT_APP_API_URL}/v3/shyfts-market/${shift.id}`,
            {
              data: {
                userRecordId: user.recordId,
              },
            },
            {
              params: {
                departmentId: activeDepartment?.id,
              },
              headers: {
                'X-Locale': i18n.language,
              },
            },
          )
          .then(() => {
            onAssign();
            onClose();
          })
          .catch((error) => {
            if (error.response?.data?.message) {
              message.error(error.response?.data?.message);
            } else {
              message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
            }
          });
      },
      onCancel: () => {},
    });
  };

  const onWantToRefuse = (user: IUser) => {
    Modal.confirm({
      title: t('SHYFTS_MARKET.MODAL_REFUSE_CONFIRMATION.TITLE'),
      icon: null,
      content: t('SHYFTS_MARKET.MODAL_REFUSE_CONFIRMATION.CONTENT', { user: user.displayName }),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('SHYFTS_MARKET.MODAL_REFUSE_CONFIRMATION.REFUSE_BTN'),
      okType: 'danger',
      onOk: () => {
        if (!shift) return;
        return axios
          .delete(`${process.env.REACT_APP_API_URL}/v3/shyfts-market/${shift.id}/${user.recordId}`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
          .then(({ data }) => {
            // onAssign();
            onClose(data.requestHasPending);
          })
          .catch((error) => {
            if (error.response?.data?.message) {
              message.error(error.response?.data?.message);
            } else {
              message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
            }
          });
      },
      onCancel: () => {},
    });
  };

  const onWantAssignMultiple = (users: string[]) => {
    Modal.confirm({
      title: t('SHYFTS_MARKET.MODAL_ASSIGN_MULTI_CONFIRMATION.TITLE'),
      icon: null,
      content: t('SHYFTS_MARKET.MODAL_ASSIGN_MULTI_CONFIRMATION.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('SHYFTS_MARKET.MODAL_ASSIGN_MULTI_CONFIRMATION.CONFIRM_BTN'),
      onOk: () => {
        if (!shift) return;
        return axios
          .patch(
            `${process.env.REACT_APP_API_URL}/v3/shyfts-market/${shift.id}`,
            {
              data: {
                userRecordIds: users,
                count_id: shiftDetail?.count_id,
              },
            },
            {
              params: {
                departmentId: activeDepartment?.id,
              },
              headers: {
                'X-Locale': i18n.language,
              },
            },
          )
          .then(() => {
            onAssign();
            onClose();
          })
          .catch((error) => {
            if (error.response?.data?.message) {
              message.error(error.response?.data?.message);
            } else {
              message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
            }
          });
      },
      onCancel: () => {},
    });
  };

  const onFinish = async (values: any) => {
    if (values?.users?.length) {
      onWantAssignMultiple(values?.users);
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      className={className}
      visible={!!shift}
      onCancel={() => onClose(shift?.requestHasPending)}
      width={'80%'}
      title={
        shiftDetail ? (
          <div>
            <h1>
              {moment.unix(shiftDetail.start).format('dddd LL')}{' '}
              {t('GLOBAL.FROM_HOUR_TO_HOUR', {
                hour1: moment.unix(shiftDetail.start).format('HH:mm'),
                hour2: moment.unix(shiftDetail.end).format('HH:mm'),
              }).toLocaleLowerCase()}{' '}
              {`(${shiftDetail?.count || 1})`}
            </h1>
            {tags.map(({ color, name, background }, i) => (
              <Tag key={`shyftMarketDetail__${i}`} size="large" color={color} background={background} name={name} />
            ))}
          </div>
        ) : null
      }
      getContainer={false}
      forceRender
      footer={
        (shiftDetail?.count || 1) > 1
          ? [
              <Button key={1} type="primary" htmlType="submit" onClick={() => form.submit()}>
                {t('SHYFTS_MARKET.MODAL_ASSIGN_MULTI_CONFIRMATION.CONFIRM_BTN')}
              </Button>,
            ]
          : null
      }
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="users">
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={(values) => {
              if (values?.length > (shiftDetail?.count || 1)) {
                values.pop();
                form.setFieldsValue({ users: values });
              }
            }}
          >
            <Table
              style={{ width: '100%' }}
              loading={loading}
              dataSource={shiftDetail?.users || []}
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </Checkbox.Group>
        </Form.Item>
      </Form>
      <h2>{t('GLOBAL.ALREADY_PLANNED')}</h2>
      <Table loading={loadingPlanned} dataSource={planned} columns={plannedColumns} pagination={false} />
    </Modal>
  );
};

export default styled(ModalDetail)`
  .green {
    font-size: 34px;
    cursor: pointer;
    color: ${colors.green};
  }

  .red {
    font-size: 34px;
    cursor: pointer;
    color: ${colors.red};
  }

  .white {
    cursor: pointer;
    color: white;
  }

  .ant-checkbox-inner {
    border-color: ${colors.blue};
  }
`;
