import AppContext from '@/pages/app/context';
import { Button, Input } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  userRecordId?: string;
  disabled: boolean;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<Props> = ({ className, userRecordId, disabled, setFilterSearch }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <header className={className}>
      <div className="left">{!userRecordId && <h2>{t('DOCUMENTS.DOCUMENTS.TITLE')}</h2>}</div>
      <div className="right">
        <div className="actions">
          <Input
            className="input"
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            onChange={(e) => {
              setFilterSearch(e.target.value);
            }}
          />
          {activeDepartment?.accountType !== 'RGPD' && (
            <Button
              className="add"
              type="primary"
              onClick={() => {
                history.push('/app/documents/documents/new');
              }}
            >
              {t('GLOBAL.ADD')}
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default styled(Header)`
  .input {
    width: 190px;
  }
  .add {
    width: 160px;
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .left {
      h2 {
        margin-left: 0 !important;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .add {
        width: 100%;
        margin-left: 0 !important;
      }

      .input {
        width: 100%;
      }
    }
  }
`;
