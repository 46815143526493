import Error404 from '@/components/Error404';
import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Templates from '../templates';
import Document from './components/Document';
import DocumentsList from './components/DocumentsList';

const AppDocuments: React.FC = () => {
  const { path, url } = useRouteMatch();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
      <Route path={`${path}/all`}>
        <ContainerView>
          <DocumentsList />
        </ContainerView>
      </Route>
      {activeDepartment?.accountType !== 'RGPD' && (
        <Route path={`${path}/templates`}>
          <Templates documentType="DOCUMENT" />
        </Route>
      )}
      {activeDepartment?.accountType !== 'RGPD' && (
        <Route path={`${path}/:documentId`}>
          <Document />
        </Route>
      )}
      <Route component={() => <Error404 />} />
    </Switch>
  );
};

export default AppDocuments;
