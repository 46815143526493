import Error404 from '@/components/Error404';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { IDepartment } from '@/types/department.model';
import { ISettingsRoutes } from '@/types/settings/routes.model';
import { getWindowSize, handleError, isAdmin, isAdminOrHr, isCountry, isFeatureEnabled, isFree } from '@/utils';
import { Alert, Layout, message, Spin } from 'antd';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Menu from './components/Menu';

/** INFORMATIONS */
import ChangePassword from './information/change-password';
import Profile from './information/profile';

/** GENERAL */
import CustomFields from './general/custom-fields';
import CustomReports from './general/custom-reports';
import DeactivatedUsers from './general/deactivated-users';
import GeneralNotifications from './general/notifications';
import GeneralRegistrationForms from './general/registration-forms';
import UserStatus from './general/user-status';

/** SCHEDULES */
import Breaks from './schedule/breaks';
import ScheduleNotifications from './schedule/notifications';
import PdfSchedules from './schedule/pdf-schedules';
import PublicSchedules from './schedule/public-schedules';
import ScheduleTemplates from './schedule/schedule-templates';
import ScheduleSettings from './schedule/settings';
import ShiftSwapSettings from './schedule/shift-swap';
import Shortcuts from './schedule/shortcuts';
import ScheduleStaff from './schedule/staff';

/** SECTIONs */
import SectionsList from './sections/list';
import SectionSettings from './sections/settings';

/** SKILLS */
import SkillsList from './skills/list';

/** TASKS */
import TasksList from './tasks/list';

/** ATTRIBUTES */
import AttributesList from './attributes/list';
import AttributesRegistrationForms from './attributes/registration-forms';

/** FREESHIFTS */
import FreeShiftsSettings from './freeshifts/settings';

/** HR */
import HrDayoffs from './hr/dayoffs';
import HrRules from './hr/hr-rules';
import HrPayrollReports from './hr/payroll-reports';
import HrSettings from './hr/settings';
import HrSocSec from './hr/soc-sec';
import HrStaff from './hr/staff';

/** CLOCKINGS */
import ClockingCosts from './clocking/costs';
import ClockingNotifications from './clocking/notifications';
import ClockingPos from './clocking/pos';
import ClockingSettings from './clocking/settings';
import ClockingStaff from './clocking/staff';

/** DIMONA */
import DimonaSettings from './dimona/settings';

/** CONTRACTS */
import ContractPos from './contracts/pos';
import ContractSettings from './contracts/settings';
import ContractStaff from './contracts/staff';

/** MARKETPLACE */
import Marketplace from './marketplace';

/** INSIGHTS */
import Balance from './insights/balance';
import DailyTurnover from './insights/daily-turnover';
import Quota from './insights/quota';
import QuotaTemplates from './insights/quota/quota-templates';
import InsightSettings from './insights/settings';

/** BILLING */
import { FEATURES, IFeature } from '@/types/features.model';
import { IMarketplaceAppCategory } from '@/types/marketplace/marketplace-app-category.model';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import axios from 'axios';
import PendingAttributes from './attributes/pending-attributes';
import AccountDetails from './billing/account-details';
import BillingDetails from './billing/billing-details';
import CreditNotes from './billing/credit-notes';
import Invoices from './billing/invoices';
import PaymentDetails from './billing/payment-details';
import Container from './marketplace/components/modal/Container';

const { Sider, Content } = Layout;

interface Props {
  className?: string;
}

const Settings: React.FC<Props> = ({ className }) => {
  const { path, url } = useRouteMatch();
  const {
    state: { activeDepartment, features, hrPartners, marketplaceApps },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const {
    state: { userDetails },
    dispatch: authContextDispatch,
  } = useContext(AuthContext);

  const history = useHistory();
  const { pathname } = useLocation();
  const [currentSettings, setCurrentSettings] = useState<IDepartmentSettings | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [activeAppName, setActiveAppName] = useState<string | null>(null);
  const [activeApp, setActiveApp] = useState<IMarketplaceApp | null>(null);
  const [activePage, setActivePage] = useState<'content' | 'auth' | 'success' | 'uninstalled'>('content');
  const [appInstalledAlert, setAppInstalledAlert] = useState<string | null>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const isRefreshNeeded = (newSettings: IDepartmentSettings, currentSettings: IDepartmentSettings | null) => {
    return (
      newSettings.hr?.custom_hr_codes !== currentSettings?.hr?.custom_hr_codes ||
      newSettings.general?.dimona_shyft_clocking_correction !==
        currentSettings?.general?.dimona_shyft_clocking_correction ||
      newSettings.general?.counters_values !== currentSettings?.general?.counters_values ||
      newSettings.schedule?.swap_shift?.active !== currentSettings?.schedule?.swap_shift?.active ||
      newSettings?.general?.enable_section !== currentSettings?.general?.enable_section ||
      newSettings?.account?.clockin_account !== currentSettings?.account?.clockin_account ||
      newSettings?.user?.lang !== currentSettings?.user?.lang ||
      newSettings.general?.enable_availability !== currentSettings?.general?.enable_availability ||
      newSettings.schedule?.order_by_week !== currentSettings?.schedule?.order_by_week ||
      newSettings.schedule?.order_by_day !== currentSettings?.schedule?.order_by_day ||
      newSettings.schedule?.validate_shifts !== currentSettings?.schedule?.validate_shifts ||
      newSettings.schedule?.shyfts_market !== currentSettings?.schedule?.shyfts_market ||
      newSettings.subscribe?.subscribe_active !== currentSettings?.subscribe?.subscribe_active ||
      newSettings.insights?.productivity_clocking !== currentSettings?.insights?.productivity_clocking ||
      newSettings.general?.enable_clocking_costs !== currentSettings?.general?.enable_clocking_costs ||
      newSettings.schedule?.colored_shifts !== currentSettings?.schedule?.colored_shifts ||
      newSettings.schedule?.schedule_views !== currentSettings?.schedule?.schedule_views ||
      newSettings.general?.enable_clocking_costs !== currentSettings?.general?.enable_clocking_costs
    );
  };

  const updateCurrentSettings = (settings: IDepartmentSettings): Promise<boolean> => {
    settings.insights = {
      ...currentSettings?.insights,
      ...settings.insights,
    };
    if (Array.isArray(settings.insights) && settings.insights.length == 0) {
      settings = { ...settings, insights: undefined };
    }
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      Axios.patch(`${process.env.REACT_APP_API_URL}/v3/settings`, settings, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
        .then((response) => {
          const newSettings = response.data as IDepartmentSettings;

          if (isRefreshNeeded(newSettings, currentSettings)) {
            Axios.get(`${process.env.REACT_APP_API_URL}/v3/departments`)
              .then((response) => {
                const departments = response.data.departments as IDepartment[];
                const department = departments?.find((x) => x.id === activeDepartment?.id);
                appContextDispatch({
                  type: 'SET_DEPARTMENTS',
                  payload: departments,
                });

                if (department) {
                  appContextDispatch({
                    type: 'SET_ACTIVE_DEPARTMENT',
                    payload: department?.id,
                  });
                  authContextDispatch({
                    type: 'SET_TRIAL_END_DATE',
                    payload: department?.trialEndDate || null,
                  });
                  authContextDispatch({
                    type: 'SET_BLOCKED',
                    payload: department?.accountType === 'BLOCKED' ? true : false,
                  });
                }
              })
              .catch((error) => {
                appContextDispatch({
                  type: 'SET_DEPARTMENTS',
                  payload: [],
                });
                handleError(error);
              });
          } else {
            setCurrentSettings(response.data);
          }
          resolve(true);
          setIsLoading(false);
        })
        .catch((error) => {
          handleError(error);
          reject();
          setIsLoading(false);
        });
    });
  };

  const containsApps = (category: IMarketplaceAppCategory) => {
    return (
      marketplaceApps.filter(
        (app) =>
          app.category == category &&
          (app.countries.includes(activeDepartment!.country!) || app.countries.includes('ALL')),
      ).length > 0
    );
  };

  /** currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} */
  const routes: ISettingsRoutes = {
    information: {
      key: 'information',
      title: t('SETTINGS.MENU.INFORMATIONS.TITLE'),
      icon: 'icon-info-circled',
      visible: true,
      children: [
        {
          title: t('SETTINGS.MENU.INFORMATIONS.PROFILE'),
          route: 'profile',
          component: (
            <Profile currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} />
          ),
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.INFORMATIONS.CHANGE_PASSWORD'),
          route: 'change-password',
          component: <ChangePassword />,
          visible: true,
        },
      ],
    },
    general: {
      key: 'general',
      title: t('SETTINGS.MENU.GENERAL.TITLE'),
      icon: 'icon-cog-alt',
      visible: true,
      children: [
        {
          title: t('SETTINGS.MENU.GENERAL.USER_STATUS'),
          route: 'user-status',
          component: <UserStatus />,
          visible: !isFree(activeDepartment!),
        },
        {
          title: t('SETTINGS.MENU.GENERAL.CUSTOM_FIELDS'),
          route: 'custom-fields',
          component: <CustomFields />,
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.GENERAL.NOTIFICATIONS'),
          route: 'notifications',
          component: (
            <GeneralNotifications
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!) && !isFree(activeDepartment!),
        },
        {
          title: t('SETTINGS.MENU.GENERAL.DEACTIVATED_USERS'),
          route: 'deactivated-users',
          component: <DeactivatedUsers />,
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.GENERAL.CUSTOM_REPORTS'),
          route: 'custom-reports',
          component: <CustomReports />,
          visible:
            !isFree(activeDepartment!) &&
            (isFeatureEnabled(features, FEATURES.SCHEDULE) ||
              (isFeatureEnabled(features, FEATURES.CLOCKING) && isAdmin(activeDepartment!.role!))),
        },
        {
          title: t('SETTINGS.MENU.GENERAL.REGISTRATION_FORM'),
          route: 'registration-forms',
          component: (
            <GeneralRegistrationForms
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: !isFree(activeDepartment!),
        },
      ],
    },
    sections: {
      key: 'sections',
      title: t('SETTINGS.MENU.SECTIONS.TITLE'),
      icon: 'icon-sections',
      visible: isFeatureEnabled(features, FEATURES.SECTIONS),
      children: [
        {
          title: t('SETTINGS.MENU.SECTIONS.MANAGE_SECTIONS'),
          route: 'manage',
          component: <SectionsList />,
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.SECTIONS.SETTINGS'),
          route: 'settings',
          component: (
            <SectionSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!) && isFeatureEnabled(features, FEATURES.SCHEDULE),
        },
      ],
    },
    skills: {
      key: 'skills',
      title: t('SETTINGS.MENU.SKILLS.TITLE'),
      icon: 'icon-skills',
      visible: isFeatureEnabled(features, FEATURES.SKILLS),
      children: [
        {
          title: t('SETTINGS.MENU.SKILLS.MANAGE_SKILLS'),
          route: 'manage',
          component: <SkillsList />,
          visible: true,
        },
      ],
    },
    tasks: {
      key: 'tasks',
      title: t('SETTINGS.MENU.TASKS.TITLE'),
      icon: 'icon-tasks',
      visible: isFeatureEnabled(features, FEATURES.TASKS),
      children: [
        {
          title: t('SETTINGS.MENU.TASKS.MANAGE_TASKS'),
          route: 'manage',
          component: <TasksList />,
          visible: true,
        },
      ],
    },
    attributes: {
      key: 'attributes',
      title: t('SETTINGS.MENU.ATTRIBUTES.TITLE'),
      icon: 'icon-attributes',
      visible: isFeatureEnabled(features, FEATURES.ATTRIBUTES),
      children: [
        {
          title: t('SETTINGS.MENU.ATTRIBUTES.MANAGE_ATTRIBUTES'),
          route: 'manage',
          extra: ':id/:attributeId',
          component: <AttributesList />,
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.ATTRIBUTES.REGISTRATION_FORMS'),
          route: 'registration-forms',
          component: (
            <AttributesRegistrationForms
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.ATTRIBUTES.PENDING_ATTRIBUTES'),
          route: 'pending-attributes',
          component: <PendingAttributes />,
          visible: true,
        },
      ],
    },
    schedule: {
      key: 'schedule',
      title: t('SETTINGS.MENU.SCHEDULE.TITLE'),
      icon: 'icon-calendar-alt',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE),
      hidden: !activeDepartment?.access?.shifts,
      children: [
        {
          title: t('SETTINGS.MENU.SCHEDULE.SCHEDULE_TEMPLATES'),
          route: 'schedule-templates',
          component: <ScheduleTemplates />,
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.SCHEDULE.SHORTCUTS'),
          route: 'shortcuts',
          component: <Shortcuts />,
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.SCHEDULE.SHIFT_SWAP'),
          route: 'shift-swap',
          component: (
            <ShiftSwapSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
        {
          title: t('SETTINGS.MENU.SCHEDULE.BREAKS'),
          route: 'breaks',
          component: (
            <Breaks currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
        {
          title: t('SETTINGS.MENU.SCHEDULE.NOTIFICATIONS'),
          route: 'notifications',
          component: (
            <ScheduleNotifications
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
        {
          title: t('SETTINGS.MENU.SCHEDULE.PDF_SCHEDULES'),
          route: 'pdf-schedules',
          component: <PdfSchedules currentSettings={currentSettings || undefined} />,
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.SCHEDULE.PUBLIC_SCHEDULES'),
          route: 'public-schedules',
          component: (
            <PublicSchedules
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
        {
          title: 'Shyfter Staff',
          route: 'staff',
          component: (
            <ScheduleStaff
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
        {
          title: t('SETTINGS.MENU.SCHEDULE.SETTINGS'),
          route: 'settings',
          component: (
            <ScheduleSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
      ],
    },
    freeshifts: {
      key: 'freeshifts',
      title: t('SETTINGS.MENU.FREESHIFTS.TITLE'),
      icon: 'icon-freeshifts',
      visible: isAdmin(activeDepartment!.role!) && isFeatureEnabled(features, FEATURES.FREESHIFTS),
      hidden: !isAdmin(activeDepartment!.role!) || !activeDepartment?.access?.shifts,
      children: [
        {
          title: t('SETTINGS.MENU.FREESHIFTS.SETTINGS'),
          route: 'settings',
          component: (
            <FreeShiftsSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: true,
        },
      ],
    },
    clocking: {
      key: 'clocking',
      title: t('SETTINGS.MENU.CLOCKING.TITLE'),
      icon: 'icon-stopwatch',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING),
      hidden: !activeDepartment?.access?.clockings,
      children: [
        {
          title: t('SETTINGS.MENU.CLOCKING.MANAGE_COSTS'),
          route: 'costs',
          component: <ClockingCosts />,
          visible: currentSettings?.general?.enable_clocking_costs || false,
        },
        {
          title: 'Shyfter POS',
          route: 'pos',
          component: (
            <ClockingPos currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} />
          ),
          visible: true,
        },
        {
          title: 'Shyfter Staff',
          route: 'staff',
          component: (
            <ClockingStaff
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
        {
          title: t('SETTINGS.MENU.CLOCKING.SETTINGS'),
          route: 'settings',
          component: (
            <ClockingSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!),
        },
        {
          title: t('SETTINGS.MENU.CLOCKING.NOTIFICATIONS'),
          route: 'notifications',
          component: (
            <ClockingNotifications
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isAdmin(activeDepartment!.role!) && isFeatureEnabled(features, FEATURES.SCHEDULE),
        },
      ],
    },
    hr: {
      key: 'hr',
      title: t('SETTINGS.MENU.HR.TITLE'),
      icon: 'icon-hr',
      visible: isAdminOrHr(activeDepartment!.role!) && !isFree(activeDepartment!),
      hidden: !isAdminOrHr(activeDepartment!.role!) || isFree(activeDepartment!),
      children: [
        {
          title: t('SETTINGS.MENU.HR.DAYOFFS'),
          route: 'dayoffs',
          component: <HrDayoffs />,
          visible: isFeatureEnabled(features, FEATURES.SCHEDULE),
        },
        {
          title: t('SETTINGS.MENU.HR.SOCSEC_CODES'),
          route: 'soc-sec',
          component: <HrSocSec />,
          visible:
            isCountry(activeDepartment!, ['BE']) &&
            hrPartners.length > 0 &&
            isFeatureEnabled(features, FEATURES.HR_PARTNER),
        },
        {
          title: t('SETTINGS.MENU.HR.HR_RULES'),
          route: 'hr-rules',
          component: (
            <HrRules currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} />
          ),
          visible: isCountry(activeDepartment!, ['BE']) && isFeatureEnabled(features, FEATURES.HR_PARTNER),
        },
        {
          title: t('SETTINGS.MENU.HR.PAYROLL_REPORTS'),
          route: 'payroll-reports',
          component: (
            <HrPayrollReports
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible:
            (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
            isCountry(activeDepartment!, ['FR']),
        },
        {
          title: 'Shyfter Staff',
          route: 'staff',
          component: (
            <HrStaff currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} />
          ),
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.HR.SETTINGS'),
          route: 'settings',
          component: (
            <HrSettings currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} />
          ),
          visible: true,
        },
      ],
    },
    dimona: {
      key: 'dimona',
      title: t('SETTINGS.MENU.DIMONA.TITLE'),
      icon: 'icon-onss-rsz',
      visible:
        isCountry(activeDepartment!, ['BE']) &&
        isAdmin(activeDepartment!.role!) &&
        isFeatureEnabled(features, FEATURES.DIMONA) &&
        (isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CLOCKING)),
      hidden: !isAdmin(activeDepartment!.role!) || !activeDepartment?.access?.dimona,
      children: [
        {
          title: t('SETTINGS.MENU.DIMONA.SETTINGS'),
          route: 'settings',
          component: (
            <DimonaSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: true,
        },
      ],
    },
    contracts: {
      key: 'contracts',
      title: t('SETTINGS.MENU.CONTRACTS.TITLE'),
      icon: 'icon-doc-text-inv',
      visible: isAdmin(activeDepartment!.role!) && isFeatureEnabled(features, FEATURES.CONTRACTS),
      hidden: !isAdmin(activeDepartment!.role!) || !activeDepartment?.access?.documents,
      children: [
        {
          title: 'Shyfter POS',
          route: 'pos',
          component: (
            <ContractPos currentSettings={currentSettings || undefined} updateCurrentSettings={updateCurrentSettings} />
          ),
          visible: true,
        },
        {
          title: 'Shyfter Staff',
          route: 'staff',
          component: (
            <ContractStaff
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.CONTRACTS.SETTINGS'),
          route: 'settings',
          component: (
            <ContractSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS),
        },
      ],
    },
    insights: {
      key: 'insights',
      title: t('SETTINGS.MENU.INSIGHTS.TITLE'),
      icon: 'icon-chart-bar',
      visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY) || isFeatureEnabled(features, FEATURES.QUOTAS),
      children: [
        {
          title: t('SETTINGS.MENU.INSIGHTS.SETTINGS'),
          route: 'settings',
          component: (
            <InsightSettings
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY),
        },
        {
          title: t('SETTINGS.MENU.INSIGHTS.DAILY_TURNOVER'),
          route: 'daily-turnover',
          component: <DailyTurnover />,
          visible:
            (currentSettings?.general?.enable_turnover || false) && isFeatureEnabled(features, FEATURES.PRODUCTIVITY),
        },
        {
          title: t('SETTINGS.MENU.INSIGHTS.BALANCE_TURNOVER'),
          route: 'balance',
          component: <Balance />,
          visible:
            (currentSettings?.general?.enable_turnover || false) &&
            isFeatureEnabled(features, FEATURES.PRODUCTIVITY) &&
            isFeatureEnabled(features, FEATURES.QUOTAS),
        },
        {
          title: t('SETTINGS.MENU.INSIGHTS.QUOTA_TURNOVER'),
          route: 'quota',
          component: <Quota />,
          visible:
            (currentSettings?.general?.enable_turnover || false) &&
            !currentSettings?.insights?.custom_quota_templates &&
            isFeatureEnabled(features, FEATURES.QUOTAS) &&
            isFeatureEnabled(features, FEATURES.PRODUCTIVITY),
        },
        {
          title: t('SETTINGS.MENU.INSIGHTS.STAFF_QUOTA'),
          route: 'quota-templates',
          extra: ':templateId',
          component: <QuotaTemplates />,
          visible:
            isFeatureEnabled(features, FEATURES.QUOTAS) && (currentSettings?.insights?.custom_quota_templates || false),
        },
      ],
    },
    marketplace: {
      key: 'marketplace',
      title: t('SETTINGS.MENU.MARKETPLACE.TITLE'),
      icon: 'icon-basket',
      visible: true,
      children: [
        {
          title: t('SETTINGS.MENU.MARKETPLACE.MODULES'),
          route: 'modules',
          component: (
            <Marketplace
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
              category={IMarketplaceAppCategory.MODULES}
            />
          ),
          visible: containsApps(IMarketplaceAppCategory.MODULES),
        },
        {
          title: t('SETTINGS.MENU.MARKETPLACE.PRODUCTIVITY'),
          route: 'productivity',
          component: (
            <Marketplace
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
              category={IMarketplaceAppCategory.PRODUCTIVITY}
            />
          ),
          visible: containsApps(IMarketplaceAppCategory.PRODUCTIVITY),
        },
        {
          title: t('SETTINGS.MENU.MARKETPLACE.PAYROLL'),
          route: 'payroll',
          component: (
            <Marketplace
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
              category={IMarketplaceAppCategory.PAYROLL}
            />
          ),
          visible: containsApps(IMarketplaceAppCategory.PAYROLL),
        },
        {
          title: t('SETTINGS.MENU.MARKETPLACE.DATA_SYNC'),
          route: 'data-sync',
          component: (
            <Marketplace
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
              category={IMarketplaceAppCategory.DATA_SYNC}
            />
          ),
          visible: containsApps(IMarketplaceAppCategory.DATA_SYNC),
        },
        {
          title: t('SETTINGS.MENU.MARKETPLACE.ONSS'),
          route: 'onss',
          component: (
            <Marketplace
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
              category={IMarketplaceAppCategory.ONSS}
            />
          ),
          visible: containsApps(IMarketplaceAppCategory.ONSS),
        },
        {
          title: t('SETTINGS.MENU.MARKETPLACE.TRAINING'),
          route: 'training',
          component: (
            <Marketplace
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
              category={IMarketplaceAppCategory.TRAINING}
            />
          ),
          visible: containsApps(IMarketplaceAppCategory.TRAINING),
        },
      ],
    },
    billing: {
      key: 'billing',
      title: t('SETTINGS.MENU.BILLING.TITLE'),
      icon: 'icon-money',
      visible: isAdmin(activeDepartment!.role!),
      hidden: !isAdmin(activeDepartment!.role!),
      children: [
        {
          title: t('SETTINGS.MENU.BILLING.PAYMENT_DETAILS'),
          route: 'payment-details',
          component: (
            <PaymentDetails currentSettings={currentSettings || undefined} setCurrentSettings={setCurrentSettings} />
          ),
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.BILLING.BILLING_DETAILS'),
          route: 'billing-details',
          component: (
            <BillingDetails
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.BILLING.ACCOUNT_DETAILS'),
          route: 'account-details',
          component: (
            <AccountDetails
              currentSettings={currentSettings || undefined}
              updateCurrentSettings={updateCurrentSettings}
            />
          ),
          visible: true,
        },
        {
          title: t('SETTINGS.MENU.BILLING.INVOICES'),
          route: 'invoices',
          component: <Invoices />,
          visible:
            activeDepartment && activeDepartment.accountType
              ? ['PAID', 'ONBOARDING', 'ONBOARDING FINISHED'].includes(activeDepartment.accountType)
              : false,
        },
        {
          title: t('SETTINGS.MENU.BILLING.CREDIT_NOTES'),
          route: 'credit-notes',
          component: <CreditNotes />,
          visible:
            activeDepartment && activeDepartment.accountType
              ? ['PAID', 'ONBOARDING', 'ONBOARDING FINISHED'].includes(activeDepartment.accountType)
              : false,
        },
      ],
    },
  };

  useEffect(() => {
    setAppInstalledAlert(null);
  }, [pathname, activeAppName]);

  useEffect(() => {
    if (!pathname.includes('-locked') || marketplaceApps.length == 0) return;
    const parts = pathname.split('/');
    const key = parts[parts.length - 1];
    let name = key.replace('-locked', '');
    if (key == 'insights-locked') {
      name = 'productivity';
    }
    setActiveAppName(name);
    if (!activeAppName) return;
    const found = marketplaceApps.find((app) => app.name == name);
    if (!found) return;
    setActiveApp(found);
  }, [pathname, activeAppName, marketplaceApps]);

  const getFeatures = () => {
    appContextDispatch({
      type: 'SET_LOADING_FEATURES',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/get-features`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        appContextDispatch({
          type: 'SET_FEATURES',
          payload: data as IFeature[],
        });
        if (pathname.includes('-locked')) {
          const parts = pathname.split('/');
          const key = parts[3].replace('-locked', '');
          let keyToChange = key;
          if (parts[3] == 'insights-locked') {
            keyToChange = 'productivity';
          }
          const app = marketplaceApps.find((app: IMarketplaceApp) => app.name == keyToChange);
          if (app) {
            const result = app?.dependency ? app?.dependency.slice(1, -1).split('],[') : [];
            const dependencies = result.map((subArray) =>
              subArray.split(',').map((id) => (id ? marketplaceApps.find((item) => item.id === id.trim()) : null)),
            );
            const hasAllActive =
              dependencies.length == 0
                ? true
                : dependencies.some((subArray) => subArray.every((item) => item && item.installed));
            if (hasAllActive) {
              if (routes[key]) {
                if (
                  routes[key].children
                    ?.filter((element) => element.visible)
                    .map((element) => element.route)
                    .includes('settings')
                ) {
                  history.push(`/app/settings/${key}/settings`);
                } else {
                  if (routes[key].children) {
                    history.push(`/app/settings/${key}/${routes[key].children?.[0].route}`);
                  } else {
                    history.push(`/app/settings/${key}`);
                  }
                }
              }
            } else {
              setActiveApp(app);
            }
          }
          if (activeApp) {
            setAppInstalledAlert(activeApp.confirmation_popup);
          }
        }
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        appContextDispatch({
          type: 'SET_LOADING_FEATURES',
          payload: false,
        });
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    appContextDispatch({
      type: 'SET_LOADING_SETTINGS',
      payload: true,
    });
    setCurrentSettings(null);
    if (activeDepartment) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/settings`, {
        params: {
          departmentId: activeDepartment?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          if (mounted) {
            setCurrentSettings(response.data);
            setIsLoading(false);
            appContextDispatch({
              type: 'SET_LOADING_SETTINGS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (mounted) {
            setIsLoading(false);
            appContextDispatch({
              type: 'SET_LOADING_SETTINGS',
              payload: false,
            });
            message.error(t('SETTINGS.MESSAGE_LOADING_ERROR'));
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [activeDepartment]);

  const orderedRoutes = Object.fromEntries(
    Object.entries(routes).sort(([keyA, valueA], [keyB, valueB]) => {
      return (valueB.visible ? 1 : 0) - (valueA.visible ? 1 : 0);
    }),
  );

  const getApps = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/marketplace`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        getFeatures();
        const apps = data
          .filter(
            (app: IMarketplaceApp) =>
              app.countries.includes('ALL') || app.countries.includes(activeDepartment!.country!),
          )
          .sort((a: IMarketplaceApp, b: IMarketplaceApp) => {
            // If both have x: true or both have x: false, no change in order
            if (a.preferred_partner === b.preferred_partner) {
              return 0;
            }
            // If a.x is true, move it before b
            if (a.preferred_partner) {
              return -1;
            }
            // If b.x is true, move it before a
            return 1;
          });
        appContextDispatch({
          type: 'SET_MARKETPLACE_APPS',
          payload: apps,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <Layout className={className}>
      <Helmet>
        <title>{t('GLOBAL.SETTINGS')} - Shyfter</title>
      </Helmet>
      <Layout className="scrollbar-hidden" style={{ margin: '25px' }}>
        <Sider
          className="scrollbar-hidden"
          width={290}
          style={{ display: windowSize.innerWidth > 900 ? 'block' : 'none' }}
        >
          <Menu path={path} routes={orderedRoutes} />
        </Sider>
        <Content>
          <Spin spinning={isLoading}>
            {appInstalledAlert && (
              <Alert
                className="alert"
                type="success"
                closable
                onClose={() => setAppInstalledAlert(null)}
                message={
                  <>
                    <h2>{t('GLOBAL.CONGRATULATIONS')} 🙂</h2>
                    <p dangerouslySetInnerHTML={{ __html: appInstalledAlert.replaceAll('<br>', '') }}></p>
                  </>
                }
              />
            )}
            <Switch>
              <Route exact path={path} render={() => <Redirect to={`${url}/information/profile`} />} />
              {Object.values(orderedRoutes).map(({ children, element, visible, hidden }: any, index) => {
                const key = Object.keys(orderedRoutes)[index];
                if (!visible && !hidden) {
                  if (!activeApp) return;
                  return (
                    <Route
                      key={`${key}-locked`}
                      path={`${path}/${key}-locked`}
                      children={
                        <Container
                          activeApp={activeApp}
                          activePage={activePage}
                          category={IMarketplaceAppCategory.MODULES}
                          getApps={getApps}
                          setActiveApp={setActiveApp}
                          setActivePage={setActivePage}
                          getFeatures={getFeatures}
                          contentStyle={{
                            backgroundColor: '#fff',
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                          }}
                          headerStyle={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                        />
                      }
                    />
                  );
                }
                if (element) {
                  return <Route key={key} path={`${path}/${key}`} children={element.component} />;
                }
                return children.map(({ route, component, extra, visible }: any) => {
                  if (!visible) return null;
                  return (
                    <Route
                      key={route}
                      path={`${path}/${key}${
                        children.length > 1
                          ? `/${route}/${
                              extra
                                ? `${extra
                                    .split('/')
                                    .map((element: string) => `${element}?`)
                                    .join('/')}`
                                : ''
                            }`
                          : ''
                      }`}
                      children={component}
                    />
                  );
                });
              })}
              <Route component={() => <Error404 />} />
            </Switch>
          </Spin>
        </Content>
      </Layout>
    </Layout>
  );
};

const SettingsStyled = styled(Settings)`
  position: relative;
  height: calc(100vh - 50px);
  overflow: hidden;
  background: rgb(240, 245, 250);

  .alert {
    margin-bottom: 25px;
    border-radius: 10px;
    background-color: #f6ffed;
    border: none;
    padding: 25px;
    align-items: flex-start;
  }

  h2 {
    font-weight: bold;
    font-size: 22px;
  }

  > header {
    background: rgb(240, 245, 250);
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid ${Colors.blueLight};

    h1 {
      margin: 0;
    }
  }

  .ant-layout-content {
    h3 {
      padding-top: 10px;
      color: ${colors.green};
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
      color: #00a651;
    }
  }

  > section {
    > aside {
      background: rgb(240, 245, 250);
      width: auto;
      .ant-layout-sider-children {
        overflow: auto;
      }
    }
    > main {
      background: rgb(240, 245, 250);
      padding: 0px 30px;
      min-height: 100%;
      overflow-y: auto;

      .ant-spin-nested-loading {
        max-width: 1024px;
      }

      .table-view {
        > header {
          padding: 10px 0 20px;
        }
        .ant-table-wrapper {
          border: 1px solid ${Colors.blueLight};
          border-radius: 4px;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    overflow: scroll;
    .ant-layout-has-sider {
      flex-direction: column;

      .ant-layout-sider {
        flex: 0 !important;
      }

      .ant-layout-content {
        padding: 0;
        padding-top: 25px;
        width: auto;
        min-height: auto;
      }
    }
  }

  .ant-layout-side-children {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default SettingsStyled;
