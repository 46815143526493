import { Button, Divider, Form, InputNumber, message, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment-timezone';
import { WEEKDAYS } from '@/utils';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import colors from '@/styles/colors';
import AppContext from '@/pages/app/context';
moment.tz.setDefault('Atlantic/Reykjavik');
const { Option } = Select;

interface Props {
  className?: string;
}

const InsightQuota: React.FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [weekdays, setWeekdays] = useState<string[]>(WEEKDAYS);
  const [totals, setTotals] = useState<number[]>(TOTALS);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);

  useEffect(() => {
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setWeekdays(moment.weekdays(true));
  }, [i18n.language]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    form.resetFields();
    setFormHasChanged(false);
    setTotals(TOTALS);

    if (!activeDepartmentId) {
      return;
    }

    setIsLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/insights/quota`, {
      params: {
        departmentId: activeDepartmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then(({ data }) => {
        if (mounted) {
          setFieldsValue(data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId]);

  const setFieldsValue = (data: any) => {
    form.setFieldsValue({ ...data });
    const totals = WEEKDAYS.map((weekday) => {
      return data[weekday]?.reduce((a: number, b: any) => a + b?.percentage || 0, 0) || 0;
    });
    setTotals(totals);
  };

  const onValuesChange = () => {
    const data = form.getFieldsValue();
    const totals = WEEKDAYS.map((weekday) => {
      return data[weekday]?.reduce((a: number, b: any) => a + b?.percentage || 0, 0) || 0;
    });
    setTotals(totals);
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setIsSaving(true);
    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/insights/quota`,
      {
        ...values,
      },
      {
        params: {
          departmentId: activeDepartmentId,
        },
      },
    )
      .then(({ data }) => {
        setFieldsValue(data);
        setIsSaving(false);
        setFormHasChanged(false);
      })
      .catch((error) => {
        message.error(t('GLOBAL.MESSAGE_SAVING_ERROR'));
        setIsSaving(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.QUOTA.TITLE')}</h2>
      <Spin spinning={isLoading || isSaving}>
        <Form
          layout="vertical"
          size="large"
          form={form}
          onValuesChange={onValuesChange}
          requiredMark={false}
          onFinish={onFinish}
        >
          {weekdays?.map((day: string, index: number) => (
            <div key={day}>
              <Divider orientation="left" plain>
                {day}
              </Divider>
              <Form.List name={WEEKDAYS[index]}>
                {(taskItems, { add, remove }) => {
                  return (
                    <div>
                      {taskItems.map((taskItem) => {
                        return (
                          <div className="form-list" key={taskItem.key}>
                            <Form.Item
                              name={[taskItem.name, 'start']}
                              fieldKey={[taskItem.fieldKey, 'start']}
                              style={{ display: 'inline-block', flex: '1', marginRight: '10px' }}
                              rules={[{ required: true, message: '' }]}
                            >
                              <Select
                                placeholder={t('SETTINGS.INSIGHTS.FROM')}
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                              >
                                {[...new Array(25)].map((_item, index) => (
                                  <Option key={`start_hour_${index}`} value={index}>
                                    {index}:00
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[taskItem.name, 'end']}
                              fieldKey={[taskItem.fieldKey, 'end']}
                              style={{ display: 'inline-block', flex: '1', marginRight: '10px' }}
                              rules={[{ required: true, message: '' }]}
                            >
                              <Select
                                placeholder={t('SETTINGS.INSIGHTS.TO')}
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                              >
                                {[...new Array(25)].map((_item, index) => (
                                  <Option key={`start_hour_${index}`} value={index}>
                                    {index}:00
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[taskItem.name, 'percentage']}
                              fieldKey={[taskItem.fieldKey, 'percentage']}
                              style={{ display: 'inline-block', flex: '1', marginRight: '30px' }}
                              rules={[{ required: true, message: '' }]}
                            >
                              <InputNumber
                                min={0}
                                max={100}
                                formatter={(value) => `${value}%`}
                                parser={(value) => (value ? (value.replace('%', '') as any) : 0)}
                              />
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(taskItem.name);
                              }}
                            />
                          </div>
                        );
                      })}
                      <div className="total">{totals[index]}%</div>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> {t('SETTINGS.INSIGHTS.QUOTA.ADD_NEW_QUOTA')}
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </div>
          ))}
          <Form.Item className={`actions ${formHasChanged ? 'active' : ''}`}>
            <Button type="primary" htmlType="submit" loading={isSaving} style={{ width: '100%' }}>
              {t('GLOBAL.SAVE')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default styled(InsightQuota)`
  form {
    margin-bottom: 50px;
    .form-list {
      position: relative;
      display: flex;
      .ant-row.ant-form-item {
        padding-bottom: 10px;
        flex: 1;
        margin-bottom: 0;
        width: 100%;
        .ant-input-number.ant-input-number-lg {
          width: 100%;
        }
      }
      .anticon.anticon-minus-circle {
        position: absolute;
        margin-bottom: 25px;
        bottom: 0;
        right: 0;
        width: 20px;
      }
      .ant-form-item-explain.ant-form-item-explain-error {
        display: none;
      }
    }
    .total {
      width: calc(100% - 30px);
      font-size: 16px;
      padding: 0px 0 10px;
      padding-left: calc(66% + 4px);
      color: ${colors.red};
      font-weight: bold;
    }
    > .actions {
      position: fixed;
      width: 100%;
      max-width: 1024px;
      background: white;
      bottom: -80px;
      margin: 0;
      display: flex;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 3px 3px 0 0;
      transition: bottom 0.2s;
      z-index: 2;

      &.active {
        transition-timing-function: ease-in;
        bottom: 0px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const TOTALS = [0, 0, 0, 0, 0, 0, 0];
