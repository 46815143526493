import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { IShift } from '@/types/shift.model';
import { isFeatureEnabled } from '@/utils';
import { Button, Checkbox, Form, InputNumber, message, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import axios from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import ModalLateDimonaDelete from './ModalLateDimonaDelete';
interface Props {
  className?: string;
  visible: boolean;
  shiftIds?: string[] | null;
  onClose: () => void;
  onSubmit?: () => void;
}

const ModalDeleteShiftConfirmation: React.FC<Props> = ({ className, visible, shiftIds, onClose, onSubmit }) => {
  const {
    state: { activeDepartment, activeSection, skills, userCategories, features, users },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { shiftsMap, startDate, endDate, picker, usersMap } = useSelector(
    ({ shiftsMap, startDate, endDate, picker, usersMap }: InitialStateType) => ({
      shiftsMap,
      usersMap,
      startDate,
      endDate,
      picker,
    }),
    shallowEqual,
  );

  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasFutureShiftWithUserRecordId, setHasFutureShiftWithUserRecordId] = useState<boolean>(false);
  const [hasShiftWithRecurrence, setHasShiftWithRecurrence] = useState<boolean>(false);
  const [shiftCount, setShiftCount] = useState<number>(1);
  const [hasShiftWithCount, setHasShiftWithCount] = useState<boolean>(false);
  const [wantsToDeleteMultipleFreeShifts, setWantsToDeleteMultipleFreeShifts] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [lateDimonaVisible, setLateDimonaVisible] = useState<boolean>(false);
  const [shiftsWithSingleDay, setShiftsWithSingleDay] = useState<string[]>([]);
  const [shiftsToDeleteRemoved, setShiftsToDeleteRemoved] = useState<string[]>([]);

  useEffect(() => {
    form.resetFields();
    setShiftCount(1);
    setWantsToDeleteMultipleFreeShifts(false);
    if (shiftIds) {
      setHasShiftWithRecurrence(false);
      setHasShiftWithCount(false);
      setHasFutureShiftWithUserRecordId(false);
      const now = moment().unix();
      for (let i = 0; i < shiftIds.length; i++) {
        const shift = shiftsMap.get(shiftIds[i]);
        if (shift) {
          if (shift.recurring) {
            setHasShiftWithRecurrence(true);
          }
          if (shift.userRecordId && !shift.dayoff && shift.shyftType !== 3) {
            if (shift.start! <= now && shift.end! <= now) {
              setHasFutureShiftWithUserRecordId(true);
            }
          }
          if (shift.count && shift.count > 1) {
            setHasShiftWithCount(true);
          }
          if (shift.count || 1 > 1) {
            setShiftCount(shift.count || 1);
          }
        }
      }
    }
  }, [shiftIds]);

  const onFinish = async (values: any, skip: boolean = false) => {
    setIsSaving(true);

    const { openShift, recurringDelete, seriesDelete, items } = values;
    const shiftsToPatch: string[] = [];
    let shiftsToDelete: string[] = [];
    let hasError = false;

    if (openShift) {
      for (let i = 0; i < shiftIds!.length; i++) {
        const shift = shiftsMap.get(shiftIds![i]);
        if (shift) {
          if (!shift.userRecordId || shift.dayoff) {
            shiftsToDelete.push(shiftIds![i]);
          } else {
            shiftsToPatch.push(shiftIds![i]);
          }
        }
      }
    } else {
      shiftsToDelete = shiftIds!;
    }

    if (shiftsToDelete.length) {
      if (shiftsToDelete.length == 1) {
        const shift = shiftsMap.get(shiftsToDelete[0] as string);
        if (shift) {
          const user = users.find((user) => user.recordId == shift.userRecordId);
          if (
            !skip &&
            moment(shift.startDate).isBefore(moment()) &&
            user &&
            shift.dimonaSingleDay &&
            user.default_dimona_status &&
            ['FLX', 'EXT', 'STU'].includes(user.default_dimona_status)
          ) {
            setShiftsWithSingleDay([shiftsToDelete[0]]);
            setLateDimonaVisible(true);
            setIsSaving(false);
            return;
          }
        }
      } else if (shiftsToDelete.length > 1) {
        shiftsToDelete = shiftsToDelete.filter((shiftId) => {
          const shift = shiftsMap.get(shiftId);
          if (!shift) return false;
          const user = users.find((user) => user.recordId == shift.userRecordId);
          if (
            moment(shift.startDate).isBefore(moment()) &&
            user &&
            shift.dimonaSingleDay &&
            user.default_dimona_status &&
            ['FLX', 'EXT', 'STU'].includes(user.default_dimona_status)
          ) {
            setShiftsToDeleteRemoved([...shiftsToDeleteRemoved, shiftId]);
            return false;
          }
          return true;
        });
      }
      batch(() => {
        hoursDispatch({
          type: 'UPDATE_SHIFTS',
          payload: {
            newShiftsDifferences: shiftsToDelete.map((id) => ({
              id,
              optimistic: true,
            })),
          },
        });
        hoursDispatch({
          type: 'UPDATE_FILTERED_SHIFTS',
          payload: { department: activeDepartment },
        });
      });
      await axios
        .delete(`${process.env.REACT_APP_API_URL}/v3/shifts${items && items > 1 ? '-multiple' : ''}`, {
          data:
            items && items > 1
              ? {
                  id: shiftsToDelete[0],
                  recurringDelete,
                  seriesDelete,
                  series: items && items > 1,
                  items: items ? (items > 1 ? items : undefined) : undefined,
                }
              : shiftsToDelete!.map((id) => ({
                  id,
                  recurringDelete,
                  seriesDelete,
                  series: items && items > 1,
                  items: items ? (items > 1 ? items : undefined) : undefined,
                })),
          params: {
            picker,
            startDate: startDate.unix(),
            endDate: endDate.unix(),
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
            forceDeleteDimona: shiftsWithSingleDay.length == 0 ? undefined : true,
          },
        })
        .then((response) => {
          if (response.data.errors) {
            // TODO: approve only the shift with no error
          } else {
            const {
              shifts,
              users,
              days = [],
              hours = [],
              comparisonTemplate = null,
              totalBudget = null,
              cycleNumber = null,
              scheduleModel = false,
              productivityIndex = null,
              productivityIndexReal = null,
            } = response.data;
            const filteredShifts = [...shifts].filter(
              (shift: IShift) => moment.unix(shift.start!).day() == startDate.day() && shift.shyftType == 1,
            );
            const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
              const shiftStartDate = moment.unix(currentShift.start);
              const shiftEndDate = moment.unix(currentShift.end);

              // Check if the start date is earlier than the current earliest shift
              if (shiftStartDate.isBefore(moment.unix(earliestShift.start))) {
                return currentShift;
              }

              return earliestShift;
            }, filteredShifts[0]);
            const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
              const shiftStartDate = moment.unix(currentShift.start);
              const shiftEndDate = moment.unix(currentShift.end);

              // Check if the start date is today and the end date is greater than the current latest shift
              if (shiftEndDate.isAfter(moment.unix(latestShift.end))) {
                return currentShift;
              }

              return latestShift;
            }, filteredShifts[0]);
            if (shiftsToDelete.length == 1) {
              const shift = shiftsMap.get(shiftsToDelete[0]);
              if (shift && shift.dimonaSingleDay && shift.start! <= moment().unix()) {
                Modal.confirm({
                  className: 'modal-constraints',
                  title: t('GLOBAL.WARNING'),
                  icon: null,
                  content: t('DIMONA.PAST_DIMONA_DELETED', { dimonaReference: shift.dimonaNbr }),
                  cancelButtonProps: { hidden: true },
                  onCancel: () => {},
                });
              }
            }
            if (shiftsToDelete.length > 1) {
              const shifts = Array.from(shiftsMap.values()).filter(
                (shift) =>
                  shiftsToDelete.includes(shift.id!) && shift.dimonaSingleDay && shift.start! <= moment().unix(),
              );
              if (shifts && shifts.length > 0) {
                Modal.confirm({
                  className: 'modal-constraints',
                  title: t('GLOBAL.WARNING'),
                  icon: null,
                  content: (
                    <div>
                      <p>{t('DIMONA.PAST_MULTIPLE_DIMONA_DELETED')}</p>
                      <ul>
                        {shifts.map((shift) => {
                          if (!shift.userRecordId) return;
                          const user = usersMap.get(shift.userRecordId!);
                          if (!user) return;
                          return (
                            <li>
                              {user.displayName} ({moment.unix(shift.start!).format('LL')})
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ),
                  cancelButtonProps: { hidden: true },
                  onCancel: () => {},
                });
              }
            }
            batch(() => {
              const startHour = activeDepartment?.scheduleParams?.startHour || 0;
              const endHour = activeDepartment?.scheduleParams?.endHour || 24;
              const dynamicHours = activeDepartment?.scheduleParams?.dynamicHour;

              let nextDay = false;
              if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
                nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
              }

              const start = dynamicHours
                ? earliestShift && earliestShift.start
                  ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
                  : 9
                : startHour;
              const end = dynamicHours
                ? latestShift && latestShift.end
                  ? moment.unix(latestShift.end).add(1, 'hour').hours()
                  : 24
                : endHour;
              const tz = activeDepartment?.timezone || 'Europe/Brussels';
              const isMidnight =
                earliestShift && earliestShift.startDate
                  ? earliestShift.startDate.split(' ')[1].startsWith('00:')
                  : false;

              hoursDispatch({
                type: 'SET_SHIFTS',
                payload: {
                  skills,
                  userStatus: userCategories,
                  picker,
                  activeDepartment,
                  shifts,
                  users,
                  days,
                  hours:
                    picker == 'day'
                      ? hours.filter(
                          (hour: IScheduleHour) =>
                            hour.date >= moment(startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                            hour.date <=
                              moment(
                                startDate
                                  .clone()
                                  .add(1, 'day')
                                  .toDate()
                                  .setHours(end + 1),
                              ).unix(),
                        )
                      : hours,
                  totalBudget,
                  comparisonTemplate,
                  cycleNumber,
                  productivityIndex,
                  productivityIndexReal,
                  scheduleModel,
                  activeSection,
                },
              });
              hoursDispatch({
                type: 'UPDATE_FILTERED_SHIFTS',
                payload: { department: activeDepartment },
              });
              if (picker == 'month') {
                hoursDispatch({
                  type: 'SET_LOADING',
                  payload: false,
                });
                hoursDispatch({
                  type: 'SET_LOADING_ELEMENTS',
                  payload: null,
                });
                hoursDispatch({
                  type: 'SET_SELECTED_SHIFTS',
                  payload: [],
                });
                hoursDispatch({
                  type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
                  payload: null,
                });
                hoursDispatch({
                  type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                  payload: [],
                });
                hoursDispatch({
                  type: 'SET_MONTHLY_SELECTING_ELEMENTS',
                  payload: false,
                });
              }
            });
            if (shiftsToDeleteRemoved.length > 0) {
              message.error(t('DIMONA.LATE_DIMONA_DELETE_MESSAGE'));
            }
          }
        })
        .catch((error) => {
          hasError = true;
          batch(() => {
            hoursDispatch({
              type: 'UPDATE_SHIFTS',
              payload: {
                newShiftsDifferences: shiftsToDelete!.map((id) => ({
                  id,
                  optimistic: false,
                })),
              },
            });
            hoursDispatch({
              type: 'UPDATE_FILTERED_SHIFTS',
              payload: { department: activeDepartment },
            });
            if (picker == 'month') {
              hoursDispatch({
                type: 'SET_LOADING',
                payload: false,
              });
              hoursDispatch({
                type: 'SET_LOADING_ELEMENTS',
                payload: null,
              });
              hoursDispatch({
                type: 'SET_SELECTED_SHIFTS',
                payload: [],
              });
              hoursDispatch({
                type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
                payload: null,
              });
              hoursDispatch({
                type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                payload: [],
              });
              hoursDispatch({
                type: 'SET_MONTHLY_SELECTING_ELEMENTS',
                payload: false,
              });
            }
          });
          message.error(t('SHIFTS.MODAL_DELETE_SHIFT.MESSAGE_DELETION_AND_FREE_SHIFT_CREATION_ERROR'));
        });
    }

    if (shiftsToPatch.length) {
      batch(() => {
        hoursDispatch({
          type: 'UPDATE_SHIFTS',
          payload: {
            newShiftsDifferences: shiftsToPatch.map((id) => ({
              id,
              optimistic: true,
            })),
          },
        });
        hoursDispatch({
          type: 'UPDATE_FILTERED_SHIFTS',
          payload: { department: activeDepartment },
        });
      });
      await axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/shifts`,
          {
            shifts: [
              ...shiftsToPatch!.map((id) => ({
                id,
                userRecordId: null,
              })),
            ],
          },
          {
            params: {
              picker,
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              departmentId: activeDepartment?.id,
              sectionId: activeSection,
            },
          },
        )
        .then((response) => {
          if (response.data.errors) {
            // TODO: approve only the shift with no error
          } else {
            batch(() => {
              hoursDispatch({
                type: 'UPDATE_SHIFTS',
                payload: {
                  newShiftsDifferences: shiftsToPatch!.map((id) => ({
                    id,
                    userRecordId: null,
                    optimistic: false,
                    approved: false,
                  })),
                },
              });
              hoursDispatch({
                type: 'UPDATE_FILTERED_SHIFTS',
                payload: { department: activeDepartment },
              });
            });
          }
        })
        .catch((error) => {
          hasError = true;
          console.error(error);
          batch(() => {
            hoursDispatch({
              type: 'UPDATE_SHIFTS',
              payload: {
                newShiftsDifferences: shiftsToPatch!.map((id) => ({
                  id,
                  optimistic: false,
                })),
              },
            });
            hoursDispatch({
              type: 'UPDATE_FILTERED_SHIFTS',
              payload: { department: activeDepartment },
            });
          });
          message.error(t('SHIFTS.MODAL_DELETE_SHIFT.MESSAGE_DELETION_ERROR'));
        });
    }
    setIsSaving(false);
    if (!hasError) {
      onClose();
    }
  };

  const onSave = () => {
    form.submit();
    if (onSubmit) {
      onSubmit();
    }
  };

  const onMultipleFreeShiftDelete = (e: CheckboxChangeEvent) => {
    setWantsToDeleteMultipleFreeShifts(e.target.checked);
  };

  if (shiftIds) {
    if (shiftIds.length > 1) {
      return (
        <Modal
          forceRender={true}
          maskClosable={false}
          destroyOnClose={true}
          visible={visible}
          onCancel={onClose}
          className={'modal-danger'}
          title={t('SHIFTS.MODAL_DELETE_SHIFT.TITLE')}
          footer={[
            <Button key="back" onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>,
            <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
              {t('SHIFTS.MODAL_DELETE_SHIFT.CONFIRM_BUTTON_FOR_MULTIPLE')}
            </Button>,
          ]}
        >
          <Form className={className} layout="vertical" form={form} onFinish={onFinish}>
            <p>{t('SHIFTS.MODAL_DELETE_SHIFT.DESCRIPTION_FOR_MULTIPLE')}</p>
            {hasFutureShiftWithUserRecordId === true && !!isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
              <Form.Item name="openShift" valuePropName="checked" noStyle>
                <Checkbox>{t('SHIFTS.MODAL_DELETE_SHIFT.CREATE_FREE_SHIFTS_IN_REPLACEMENT')}</Checkbox>
              </Form.Item>
            )}
          </Form>
        </Modal>
      );
    } else {
      return (
        <Modal
          visible={visible}
          onCancel={onClose}
          className={'modal-danger'}
          title={t('SHIFTS.MODAL_DELETE_SHIFT.TITLE')}
          footer={[
            <Button key="back" onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>,
            <Button key="submit" type="primary" loading={isSaving} onClick={onSave}>
              {t('SHIFTS.MODAL_DELETE_SHIFT.CONFIRM_BUTTON_FOR_ONE')}
            </Button>,
          ]}
        >
          <Form className={className} layout="vertical" form={form} onFinish={onFinish}>
            <p>{t('SHIFTS.MODAL_DELETE_SHIFT.DESCRIPTION_FOR_ONE')}</p>
            {hasFutureShiftWithUserRecordId === true && !!isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
              <Form.Item name="openShift" valuePropName="checked">
                <Checkbox>{t('SHIFTS.MODAL_DELETE_SHIFT.CREATE_FREE_SHIFT_IN_REPLACEMENT')}</Checkbox>
              </Form.Item>
            )}
            {hasShiftWithRecurrence === true && (
              <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.openShift !== curValues.openShift}>
                {(form) => {
                  const openShift = form.getFieldValue('openShift');
                  if (!openShift) {
                    return (
                      <Form.Item name="recurringDelete" valuePropName="checked">
                        <Checkbox>{t('SHIFTS.MODAL_DELETE_SHIFT.DELETE_NEXT_OCCURRENCES')}</Checkbox>
                      </Form.Item>
                    );
                  } else {
                    return null;
                  }
                }}
              </Form.Item>
            )}
            {hasShiftWithCount === true && (
              <>
                <Form.Item name="seriesDelete" valuePropName="checked">
                  <Checkbox>{t('SHIFTS.MODAL_DELETE_SHIFT.DELETE_ALL_SERIES')}</Checkbox>
                </Form.Item>
                <Form.Item name="multiple" valuePropName="checked">
                  <Checkbox onChange={onMultipleFreeShiftDelete}>
                    {t('SHIFTS.MULTIPLE_FREE_SHIFTS.DELETE_PART')}
                  </Checkbox>
                </Form.Item>
                {wantsToDeleteMultipleFreeShifts && (
                  <Form.Item
                    label={t('SHIFTS.MULTIPLE_FREE_SHIFTS.NUMBER_OF_EDITS')}
                    name="items"
                    style={{ marginTop: 10 }}
                  >
                    <InputNumber
                      type="number"
                      placeholder={t('SHIFTS.MULTIPLE_FREE_SHIFTS.NUMBER_OF_EDITS')}
                      min={1}
                      max={shiftCount}
                      step={1}
                      precision={0}
                      size="large"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </Form>
          <ModalLateDimonaDelete
            visible={lateDimonaVisible}
            setVisible={setLateDimonaVisible}
            form={form}
            onFinish={onFinish}
          />
        </Modal>
      );
    }
  } else {
    return <Form form={form} style={{ display: 'none' }} />;
  }
};

export default styled(ModalDeleteShiftConfirmation)`
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
`;
