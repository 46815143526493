import { setToken } from '@/actions';
import { usePusher } from '@/components/PusherProvider';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import Amex from '@/pages/app/super-admin/accounting/customers/customer/components/amex';
import Bank from '@/pages/app/super-admin/accounting/customers/customer/components/bank';
import Mastercard from '@/pages/app/super-admin/accounting/customers/customer/components/mastercard';
import Sepa from '@/pages/app/super-admin/accounting/customers/customer/components/sepa';
import Visa from '@/pages/app/super-admin/accounting/customers/customer/components/visa';
import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { IAccountPricing } from '@/types/pricing.model';
import { formatPrice, handleError, isTrial } from '@/utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, message, Modal, Popconfirm, Spin, Tooltip } from 'antd';
import { default as Axios, default as axios } from 'axios';
import { Channel } from 'pusher-js';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { store as dashboardStore } from '../../../dashboard/redux/store';
import { store as manageStore } from '../../../hours/manage/redux/store';
import CheckoutForm from './CheckoutForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  setCurrentSettings: React.Dispatch<React.SetStateAction<IDepartmentSettings | null>>;
}

const Payement: React.FC<Props> = ({ className, currentSettings, setCurrentSettings }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [loadingChangePayementMethod, setloadingChangePayementMethod] = useState<boolean>(false);
  const {
    dispatch: authContextDispatch,
    state: { impersonate },
  } = useContext(AuthContext);
  const history = useHistory();
  const {
    state: { activeDepartment },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const [loadingPricing, setLoadingPricing] = useState<boolean>(false);
  const [pricing, setPricing] = useState<IAccountPricing[]>([]);
  const useloc = useLocation();
  const searchParams = new URLSearchParams(useloc.search);
  const redirectStatus = searchParams.get('redirect_status');

  const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [loadingDeletePmId, setLoadingDeletePmId] = useState('');
  const [loadingMarkDefaultPmId, setLoadingMarkDefaultPmId] = useState('');
  const [showPmLoading, setShowPmLoading] = useState(false);
  const pusher = usePusher();

  useEffect(() => {
    if (redirectStatus && redirectStatus == 'succeeded' && currentSettings && currentSettings.account) {
      history.replace('/app/settings/billing/payment-details');
      if (!currentSettings.account.hasPaymentMethods) {
        setShowPmLoading(true);
      }
    }
  }, [redirectStatus, currentSettings]);

  useEffect(() => {
    let channel: Channel | null = null;

    if (
      pusher &&
      activeDepartment &&
      currentSettings &&
      currentSettings.account &&
      currentSettings.account.paymentMethods
    ) {
      channel = pusher.channel(`private-department-${activeDepartment?.id}`);

      if (!channel) return;

      const callbacks = channel.callbacks;

      if (!callbacks.get('accounting.pm-updated')) {
        channel.bind('accounting.pm-updated', async (message: any) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/v3/settings`, {
              params: {
                departmentId: activeDepartment?.id,
              },
            })
            .then(({ data }) => {
              setCurrentSettings(data);
              setShowPmLoading(false);
            })
            .catch((error) => {
              handleError(error);
            });
        });
      }
    }

    return () => {
      if (channel) {
        channel.unbind();
      }
    };
  }, [pusher, activeDepartment, currentSettings]);

  useEffect(() => {
    if (!activeDepartment) return;

    setLoadingPricing(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/pricing-plan`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        setPricing(data);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingPricing(false);
      });
  }, [activeDepartment]);

  const onWantToDeleteDemoData = () => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('ACCOUNT.MODAL_DELETE_DEMO_DATA.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteDemoData();
      },
      onCancel: () => {},
    });
  };

  const onDeleteDemoData = () => {
    Axios.post(`${process.env.REACT_APP_API_URL}/v3/operations/delete-demo-content`, null, {
      params: {
        departmentId: activeDepartment?.id,
      },
    }).then(() => {
      location.reload();
    });
  };

  const onAddPaymentMethod = () => {
    setUpdatingPaymentMethod(!updatingPaymentMethod);
    if (!updatingPaymentMethod) {
      createPaymentIntent(true);
    }
  };

  const onPauseAccount = () => {
    Modal.confirm({
      className: 'modal-constraints',
      closable: true,
      maskClosable: true,
      width: 520,
      title: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.PAUSE_ACCOUNT_TITLE'),
      icon: null,
      content: (
        <p
          dangerouslySetInnerHTML={{
            __html: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.PAUSE_ACCOUNT_DESCRIPTION'),
          }}
        ></p>
      ),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.PAUSE_ACCOUNT'),
      onOk: () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v3/operations/pause-account`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {});
      },
    });
  };

  const onCloseAccount = () => {
    Modal.confirm({
      className: 'modal-danger',
      closable: true,
      maskClosable: true,
      width: 520,
      title: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.CLOSE_ACCOUNT_TITLE'),
      icon: null,
      content: (
        <p
          dangerouslySetInnerHTML={{
            __html: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.CLOSE_ACCOUNT_DESCRIPTION'),
          }}
        ></p>
      ),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('SETTINGS.BILLING.PAYEMENT.CLOCKING_ACCOUNT.CLOSE_ACCOUNT'),
      onOk: () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v3/operations/cancel-account`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
          .then(({ data }) => {
            console.log(data);
            const accessToken = localStorage.getItem('accessToken');
            if (impersonate && accessToken) {
              sessionStorage.removeItem('impersonateToken');
              history.push('/app');
              setToken(authContextDispatch, accessToken);
            } else {
              history.push('/app');
              localStorage.clear();
              manageStore.dispatch({
                type: 'RESET',
              });
              dashboardStore.dispatch({
                type: 'RESET',
              });
              authContextDispatch({
                type: 'RESET',
              });
              appContextDispatch({
                type: 'RESET',
              });
            }
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {});
      },
    });
  };

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY!));
  }, []);

  useEffect(() => {
    if (!currentSettings) return;
    createPaymentIntent();
  }, [currentSettings]);

  const createPaymentIntent = (force: boolean = false) => {
    if (currentSettings?.account?.hasPaymentMethods && !updatingPaymentMethod && !force) return;
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/billing/stripe/create-setup-intent`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const markAsDefault = (pm: any) => {
    setLoadingMarkDefaultPmId(pm.id);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/billing/stripe/payment-methods/${pm.id}/default`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(({ data }) => {
        message.success(
          t('SETTINGS.BILLING.PAYMENT_DETAILS.PAYMENT_METHOD_MARKED_DEFAULT', {
            last4: pm.bank_last4 || pm.card_last4,
          }),
        );
        setCurrentSettings({
          ...currentSettings,
          account: {
            ...currentSettings?.account,
            paymentMethods: currentSettings?.account?.paymentMethods
              ? currentSettings?.account?.paymentMethods.map((paymentMethod) =>
                  paymentMethod.id !== pm.id ? { ...paymentMethod, default: 0 } : { ...paymentMethod, default: 1 },
                )
              : [],
          },
        });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingMarkDefaultPmId('');
      });
  };

  const deletePaymentMethod = (pm: any) => {
    setLoadingDeletePmId(pm.id);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/billing/stripe/payment-methods/${pm.id}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        message.success(
          t('SETTINGS.BILLING.PAYMENT_DETAILS.PAYMENT_METHOD_REMOVED', { last4: pm.bank_last4 || pm.card_last4 }),
        );
        setCurrentSettings({
          ...currentSettings,
          account: {
            ...currentSettings?.account,
            paymentMethods: currentSettings?.account?.paymentMethods
              ? currentSettings?.account?.paymentMethods.filter((paymentMethod) => paymentMethod.id !== pm.id)
              : [],
          },
        });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingDeletePmId('');
      });
  };

  const checkoutVisible =
    ((currentSettings && currentSettings.account && !currentSettings.account.hasPaymentMethods) ||
      updatingPaymentMethod) &&
    !showPmLoading;

  return (
    <div className={className}>
      <h2>{t('SETTINGS.BILLING.PAYEMENT.TITLE')}</h2>
      <div style={{ display: 'flex', gap: 10, alignItems: 'flex-start' }}>
        <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, flex: 1 }}>
          <h3 style={{ paddingTop: 0 }}>
            {isTrial(activeDepartment!)
              ? t('SETTINGS.BILLING.PAYEMENT.TRIAL')
              : t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.TITLE')}
          </h3>
          {!isTrial(activeDepartment!) && <p>{t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.DESCRIPTION')}</p>}
          <div className="container">
            <div className="header">
              <div
                style={{ display: 'flex', flexDirection: 'column', marginBottom: isTrial(activeDepartment!) ? 0 : 10 }}
              >
                {loadingPricing ? (
                  <Spin />
                ) : (
                  <>
                    {pricing.map((pricing) => (
                      <span>
                        <span style={{ fontWeight: 'bold' }}>{formatPrice(pricing.price)}</span>
                        <span> - {pricing.desc}</span>
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          {!(
            (!currentSettings?.account?.hasPaymentMethods ||
              (currentSettings.account.hasPaymentMethods && updatingPaymentMethod)) &&
            !showPmLoading
          ) && (
            <Button
              loading={loadingChangePayementMethod}
              type="primary"
              className="ant-btn-md"
              onClick={onAddPaymentMethod}
            >
              {t('SETTINGS.BILLING.PAYMENT_DETAILS.ADD_PAYMENT_METHOD')}
            </Button>
          )}
          {(showPmLoading ||
            (currentSettings?.account?.hasPaymentMethods && currentSettings?.account?.paymentMethods)) && (
            <div>
              {currentSettings?.account?.hasPaymentMethods && currentSettings?.account?.paymentMethods ? (
                <div style={{ marginTop: 25 }}>
                  <h3>{t('SETTINGS.BILLING.PAYMENT_DETAILS.PAYMENT_METHODS')}</h3>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 15 }}>
                    {currentSettings.account.paymentMethods.map((pm) => (
                      <div
                        style={{
                          backgroundColor: '#f7f7f7',
                          border: '1px solid #EFEFEf',
                          width: updatingPaymentMethod ? '100%' : undefined,
                          borderRadius: 12,
                          padding: '15px 25px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 15,
                          position: 'relative',
                        }}
                      >
                        {pm.default == 0 && currentSettings.account!.paymentMethods!.length > 0 && (
                          <Popconfirm
                            title={t('GLOBAL.ARE_YOU_SURE?')}
                            okText={t('GLOBAL.YES')}
                            cancelText={t('GLOBAL.NO')}
                            onConfirm={(e) => deletePaymentMethod(pm)}
                          >
                            <Tooltip overlay={t('GLOBAL.DELETE')}>
                              <Button
                                size="small"
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  padding: 5,
                                  cursor: 'pointer',
                                  border: 'none',
                                }}
                                type="ghost"
                                loading={loadingDeletePmId == String(pm.id)}
                              >
                                <i className="icon-cancel" />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                        )}
                        {pm.method_type == 'sepa_debit' ? (
                          <Sepa />
                        ) : pm.card_brand && pm.card_brand == 'visa' ? (
                          <Visa />
                        ) : pm.card_brand && pm.card_brand == 'amex' ? (
                          <Amex />
                        ) : pm.card_brand && pm.card_brand == 'mastercard' ? (
                          <Mastercard />
                        ) : (
                          <Bank />
                        )}
                        <div>
                          <div style={{ fontSize: 16, display: 'flex', alignItems: 'center', gap: 5 }}>
                            <span>
                              {pm.method_type == 'sepa_debit' ? 'SEPA' : pm.card_brand && pm.card_brand.toUpperCase()}
                            </span>
                            <span>••••</span>
                            <span>{pm.bank_last4 || pm.card_last4}</span>
                            {pm.default == 1 && (
                              <span
                                className="badge-uppercase"
                                style={{
                                  backgroundColor: colors.blueLightPastel,
                                  border: '1px solid #afe6ff',
                                  padding: '2px 5px',
                                  marginLeft: 5,
                                }}
                              >
                                {t('GLOBAL.DEFAULT')}
                              </span>
                            )}
                          </div>
                          {pm.card_exp_month && pm.card_exp_year && (
                            <div>
                              <span>
                                {t('GLOBAL.EXPIRES')} {pm.card_exp_month}/{pm.card_exp_year}
                              </span>
                            </div>
                          )}
                          {pm.default == 0 && currentSettings.account!.paymentMethods!.length > 0 && (
                            <Popconfirm
                              title={t('GLOBAL.ARE_YOU_SURE?')}
                              okText={t('GLOBAL.YES')}
                              cancelText={t('GLOBAL.NO')}
                              onConfirm={(e) => markAsDefault(pm)}
                            >
                              <Button
                                size="small"
                                style={{
                                  marginTop: 5,
                                }}
                                type="primary"
                                loading={loadingMarkDefaultPmId == String(pm.id)}
                              >
                                {t('SETTINGS.BILLING.PAYMENT_DETAILS.MARK_DEFAULT')}
                              </Button>
                            </Popconfirm>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div style={{ marginTop: 25 }}>
                  <h3>{t('SETTINGS.BILLING.PAYMENT_DETAILS.PAYMENT_METHODS')}</h3>
                  <p>Your payment method is getting added...</p>
                </div>
              )}
            </div>
          )}
        </div>
        {checkoutVisible && (
          <>
            {loading ? (
              <div
                style={{
                  backgroundColor: '#FFF',
                  padding: 25,
                  borderRadius: 8,
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin spinning={loading} />
              </div>
            ) : (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm setupLoading={loading} />
              </Elements>
            )}
          </>
        )}
      </div>
      {currentSettings?.account?.demoData && (
        <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 15 }}>
          <p>{t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.HAS_DEMO_DATA')}</p>
          <Button
            type="primary"
            className="ant-btn-md"
            danger
            style={{ display: 'block', marginBottom: 10 }}
            onClick={() => {
              onWantToDeleteDemoData();
            }}
          >
            {t('SETTINGS.BILLING.PAYEMENT.PRO_ACCOUNT.REMOVE_DEMO_DATA')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default styled(Payement)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .features {
    background-color: #eeeeee;
    padding: 25px;
    display: inline-block;
    text-align: start;
    margin: 15px 0;
    margin-top: 5px;
  }

  .subtitle {
    margin-top: 1em;
    font-weight: bold;

    .accent {
      color: ${colors.green};
      font-weight: bold;
    }
  }
`;
