import Constraints from '@/pages/app/components/Constraints';
import { IDimona } from '@/types/dimona.model';
import { IScheduleDay } from '@/types/schedule-day.model';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { message, Modal } from 'antd';
import Axios, { AxiosResponse } from 'axios';
import React from 'react';
import i18n from '../../../../../i18n';

import { IDepartment } from '@/types/department.model';
import { IProductivityIndex } from '@/types/productivity-index.model';
import { IShiftBlock } from '@/types/shift-block.model';
import { ISkill } from '@/types/skill.model';
import { IUserStatus } from '@/types/user-status.model';
import { IWeeklyView } from '@/types/weekly-view.model';
import { WritableDraft } from 'immer/dist/internal';
import moment from 'moment';
import { batch } from 'react-redux';
import { PickerType } from './store';

interface RESET {
  type: 'RESET';
}

interface SET_FREE_SHIFT_TO_PASTE_MULTIPLE {
  type: 'SET_FREE_SHIFT_TO_PASTE_MULTIPLE';
  payload: IShift | null;
}
interface SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE {
  type: 'SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE';
  payload: boolean;
}
interface SET_SHIFT_TO_ADD_OCCURRENCES {
  type: 'SET_SHIFT_TO_ADD_OCCURRENCES';
  payload: IShift | null;
}
interface SET_ADD_OCCURRENCES_MODAL_VISIBLE {
  type: 'SET_ADD_OCCURRENCES_MODAL_VISIBLE';
  payload: boolean;
}
interface SET_ASSIGN_MODAL_VISIBLE {
  type: 'SET_ASSIGN_MODAL_VISIBLE';
  payload: boolean;
}
interface SET_SHIFT_DRAWER_VISIBLE {
  type: 'SET_SHIFT_DRAWER_VISIBLE';
  payload: boolean;
}

interface SET_MULTIPLE_SHIFTS_DRAWER_VISIBLE {
  type: 'SET_MULTIPLE_SHIFTS_DRAWER_VISIBLE';
  payload: boolean;
}
interface SET_SELECTED_SHIFTS {
  type: 'SET_SELECTED_SHIFTS';
  payload: string[];
}

interface ADD_SELECTED_SHIFT {
  type: 'ADD_SELECTED_SHIFT';
  payload: string;
}

interface ADD_SELECTED_SHIFTS {
  type: 'ADD_SELECTED_SHIFTS';
  payload: string[];
}

interface REMOVE_SELECTED_SHIFT {
  type: 'REMOVE_SELECTED_SHIFT';
  payload: string;
}

interface SET_SHIFT_IDS_TO_PUBLISH {
  type: 'SET_SHIFT_IDS_TO_PUBLISH';
  payload: string[] | null;
}

interface SET_SHIFTS_ONLY {
  type: 'SET_SHIFTS_ONLY';
  payload: {
    shiftsMap: Map<string, WritableDraft<IShift>>;
    users: IUser[];
    days: IScheduleDay[];
    totalBudget: number | null;
    cycleNumber: number | null;
    scheduleModel: boolean;
  };
}

interface SET_SHIFTS_TO_TRANSFER {
  type: 'SET_SHIFTS_TO_TRANSFER';
  payload: {
    ids: string[];
    userRecordId: string;
  } | null;
}

// CONTEXT

interface SET_IS_LOADING {
  type: 'SET_IS_LOADING';
  payload: boolean;
}

interface SET_HAS_ERROR {
  type: 'SET_HAS_ERROR';
  payload: boolean;
}

interface SET_PICKER {
  type: 'SET_PICKER';
  payload: PickerType;
}

interface SET_PICKER_AND_SELECTED_DATE {
  type: 'SET_PICKER_AND_SELECTED_DATE';
  payload: {
    picker: PickerType;
    selectedDate: number;
    startDate: number;
    endDate: number;
  };
}

interface SET_SCROLL_Y {
  type: 'SET_SCROLL_Y';
  payload: number;
}

interface SET_SHIFTS {
  type: 'SET_SHIFTS';
  payload: {
    shifts: IShift[];
    users: IUser[];
    days: IScheduleDay[];
    hours: IScheduleHour[];
    totalBudget: number | null;
    cycleNumber: number | null;
    scheduleModel: boolean;
    comparisonTemplate: null | Partial<{
      id: string;
      name: string;
    }>;
    productivityIndex: IProductivityIndex | null;
    productivityIndexReal: IProductivityIndex | null;
    activeSection?: string | null;
    activeDepartment?: IDepartment | null;
    picker: PickerType;
    skills: ISkill[];
    userStatus: IUserStatus[];
  };
}

interface RESET_SHIFTS_BUT_KEEP_USERS {
  type: 'RESET_SHIFTS_BUT_KEEP_USERS';
}

interface MOVE_SHIFT {
  type: 'MOVE_SHIFT';
  payload: {
    source: {
      shiftId: string;
      userRecordId: string | null;
      start: number;
      end: number;
    };
    destination: {
      userRecordId: string | null;
      newStart: number;
      newEnd: number;
    };
  };
}

interface SET_TASK_SCHEDULER {
  type: 'SET_TASK_SCHEDULER';
  payload: boolean;
}

interface MOVE_TASK {
  type: 'MOVE_TASK';
  payload: {
    source: {
      shiftId: string;
      taskId: string;
      start: number;
      end: number;
    };
    destination: {
      shiftId: string;
      newStart: number;
      newEnd: number;
    };
  };
}

interface UPDATE_SHIFT {
  type: 'UPDATE_SHIFT';
  payload: IShift;
}

interface UPDATE_USERS {
  type: 'UPDATE_USERS';
  payload: IUser[];
}

interface ADD_SHIFTS {
  type: 'ADD_SHIFTS';
  payload: IShift[];
}

interface SET_SHIFT_OPTIMISTIC {
  type: 'SET_SHIFT_OPTIMISTIC';
  payload: {
    shiftId: string;
    optimistic: boolean;
  };
}

interface SET_SHIFTS_OPTIMISTIC {
  type: 'SET_SHIFTS_OPTIMISTIC';
  payload: {
    shiftIds: string[];
    optimistic: boolean;
  };
}

interface SET_SELECTED_SHIFT {
  type: 'SET_SELECTED_SHIFT';
  payload: IShift | null;
}

interface ASSIGN_SHIFT {
  type: 'ASSIGN_SHIFT';
  payload: {
    data: string[];
  };
}
interface COPY_SHIFTS {
  type: 'COPY_SHIFTS';
  payload: {
    ids: string[];
  };
}

interface COPY_DAY_SHIFTS {
  type: 'COPY_DAY_SHIFTS';
  payload: {
    ids: string[];
  };
}
interface REMOVE_SHIFTS {
  type: 'REMOVE_SHIFTS';
  payload: {
    ids: string[];
  };
}

interface UPDATE_SHIFTS {
  type: 'UPDATE_SHIFTS';
  payload: {
    newShiftsDifferences: IShift[];
  };
}

interface DELETE_SHIFTS {
  type: 'DELETE_SHIFTS';
  payload: {
    ids: string[];
  };
}

interface SET_SHOW_SHIFTS {
  type: 'SET_SHOW_SHIFTS';
  payload: boolean;
}

interface SET_SHOW_ABSENCES {
  type: 'SET_SHOW_ABSENCES';
  payload: boolean;
}

interface SET_SHOW_OPEN_SHIFTS {
  type: 'SET_SHOW_OPEN_SHIFTS';
  payload: boolean;
}

interface SET_SHOW_SKILLS {
  type: 'SET_SHOW_SKILLS';
  payload: boolean;
}

interface SET_SHOW_COMMENT {
  type: 'SET_SHOW_COMMENT';
  payload: boolean;
}

interface SET_SHOW_HR_CODE {
  type: 'SET_SHOW_HR_CODE';
  payload: boolean;
}

interface SET_SHOW_DETAILS {
  type: 'SET_SHOW_DETAILS';
  payload: boolean;
}

interface SET_SHOW_PRICES {
  type: 'SET_SHOW_PRICES';
  payload: boolean;
}

interface SET_SHOW_HEAD_COUNT {
  type: 'SET_SHOW_HEAD_COUNT';
  payload: boolean;
}

interface SET_SHOW_TASKS {
  type: 'SET_SHOW_TASKS';
  payload: boolean;
}

interface SET_SHOW_ATTRIBUTES {
  type: 'SET_SHOW_ATTRIBUTES';
  payload: boolean;
}

interface SET_SHOW_SECTIONS {
  type: 'SET_SHOW_SECTIONS';
  payload: boolean;
}

interface SET_SHOW_OTHER_DEPARTMENTS {
  type: 'SET_SHOW_OTHER_DEPARTMENTS';
  payload: boolean;
}

interface SET_SHOW_PRODUCTIVITY_INDEX {
  type: 'SET_SHOW_PRODUCTIVITY_INDEX';
  payload: boolean;
}

interface SET_SHOW_CLOCKING {
  type: 'SET_SHOW_CLOCKING';
  payload: boolean;
}

interface SET_SHOW_MONTHLY_HOURS {
  type: 'SET_SHOW_MONTHLY_HOURS';
  payload: boolean;
}

interface SET_SHOW_MONTHLY_START {
  type: 'SET_SHOW_MONTHLY_START';
  payload: boolean;
}

interface SET_FILTER_OPTIONS {
  type: 'SET_FILTER_OPTIONS';
  payload: string[];
}

interface UPDATE_FILTERED_SHIFTS {
  type: 'UPDATE_FILTERED_SHIFTS';
  payload?: {
    department?: IDepartment;
    reset?: boolean;
  };
}

interface SET_SHIFT_IDS_TO_DELETE {
  type: 'SET_SHIFT_IDS_TO_DELETE';
  payload: string[] | null;
}

interface SET_NEW_DIMONA {
  type: 'SET_NEW_DIMONA';
  payload: Partial<IDimona> | null;
}

interface SET_MONTHLY_SELECTING_ELEMENTS {
  type: 'SET_MONTHLY_SELECTING_ELEMENTS';
  payload: boolean;
}

interface SET_MONTHLY_SELECTED_ELEMENTS {
  type: 'SET_MONTHLY_SELECTED_ELEMENTS';
  payload: any[];
}

interface SET_ERRORS {
  type: 'SET_ERRORS';
  payload: any[];
}

interface SET_CREATE_NEW_SHIFT_BLOCK {
  type: 'SET_CREATE_NEW_SHIFT_BLOCK';
  payload: boolean;
}

interface SET_SHIFT_BLOCKS {
  type: 'SET_SHIFT_BLOCKS';
  payload: IShiftBlock[];
}

interface SET_SHIFT_BLOCKS_LOADING {
  type: 'SET_SHIFT_BLOCKS_LOADING';
  payload: boolean;
}

interface SET_ACTIVE_SHIFT_BLOCK {
  type: 'SET_ACTIVE_SHIFT_BLOCK';
  payload: IShiftBlock | null;
}

interface SET_EDITING_SHIFT_BLOCK {
  type: 'SET_EDITING_SHIFT_BLOCK';
  payload: boolean;
}

interface SET_SELECTED_SHIFTS_ON_SHIFT_KEY {
  type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY';
  payload: IShift[] | null;
}
interface SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER {
  type: 'SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER';
  payload: string | null;
}

interface SET_CLIPBOARD_HISTORY {
  type: 'SET_CLIPBOARD_HISTORY';
  payload: null;
}

interface SET_MONTH_START_POINT_ELEMENT {
  type: 'SET_MONTH_START_POINT_ELEMENT';
  payload: string | null;
}

interface SET_MONTHLY_SELECTING_DIRECTION {
  type: 'SET_MONTHLY_SELECTING_DIRECTION';
  payload: 'row' | 'column' | null;
}

interface SET_LOADING_ELEMENTS {
  type: 'SET_LOADING_ELEMENTS';
  payload: string[] | null;
}

interface SET_ACTIVE_WEEKLY_VIEW {
  type: 'SET_ACTIVE_WEEKLY_VIEW';
  payload: IWeeklyView | null;
}

interface SET_COPIED_SHIFT {
  type: 'SET_COPIED_SHIFT';
  payload: IShift | null;
}

interface SET_MONTHLY_SHIFTS_TO_REPLACE {
  type: 'SET_MONTHLY_SHIFTS_TO_REPLACE';
  payload: string[] | null;
}

interface SET_MONTHLY_SHIFTS_TO_REPLACE_SHIFT_BLOCK {
  type: 'SET_MONTHLY_SHIFTS_TO_REPLACE_SHIFT_BLOCK';
  payload: string | null;
}

interface SET_LOADING {
  type: 'SET_LOADING';
  payload: boolean;
}

interface SET_AY_SHIFT_IDS {
  type: 'SET_AY_SHIFT_IDS';
  payload: string[];
}

interface SET_AY_SHIFT_IDS_LOADING {
  type: 'SET_AY_SHIFT_IDS_LOADING';
  payload: boolean;
}

interface SET_AY_ERRORS {
  type: 'SET_AY_ERRORS';
  payload: any;
}

export type ActionType =
  | RESET
  | SET_FREE_SHIFT_TO_PASTE_MULTIPLE
  | SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE
  | SET_SHIFT_TO_ADD_OCCURRENCES
  | SET_ADD_OCCURRENCES_MODAL_VISIBLE
  | SET_ASSIGN_MODAL_VISIBLE
  | SET_SHIFT_DRAWER_VISIBLE
  | SET_MULTIPLE_SHIFTS_DRAWER_VISIBLE
  | ADD_SELECTED_SHIFT
  | REMOVE_SELECTED_SHIFT
  | SET_SELECTED_SHIFTS
  | SET_SHIFT_IDS_TO_PUBLISH
  | SET_SHIFTS_TO_TRANSFER
  | SET_PICKER
  | SET_PICKER_AND_SELECTED_DATE
  | SET_SCROLL_Y
  | SET_IS_LOADING
  | SET_HAS_ERROR
  | SET_SHIFTS_ONLY
  | SET_SHIFTS
  | UPDATE_FILTERED_SHIFTS
  | RESET_SHIFTS_BUT_KEEP_USERS
  | SET_SHIFT_OPTIMISTIC
  | SET_SHIFTS_OPTIMISTIC
  | MOVE_SHIFT
  | UPDATE_SHIFT
  | UPDATE_SHIFTS
  | UPDATE_USERS
  | DELETE_SHIFTS
  | ADD_SHIFTS
  | ADD_SELECTED_SHIFT
  | ADD_SELECTED_SHIFTS
  | REMOVE_SELECTED_SHIFT
  | SET_SELECTED_SHIFT
  | COPY_DAY_SHIFTS
  | ASSIGN_SHIFT
  | COPY_SHIFTS
  | REMOVE_SHIFTS
  | SET_FILTER_OPTIONS
  | SET_SHOW_HEAD_COUNT
  | SET_SHOW_SHIFTS
  | SET_SHOW_ABSENCES
  | SET_SHOW_OPEN_SHIFTS
  | SET_SHOW_SKILLS
  | SET_SHOW_TASKS
  | SET_SHOW_SECTIONS
  | SET_SHOW_ATTRIBUTES
  | SET_SHOW_DETAILS
  | SET_SHOW_PRICES
  | SET_SHOW_OTHER_DEPARTMENTS
  | SET_SHOW_PRODUCTIVITY_INDEX
  | SET_SHOW_HR_CODE
  | SET_SHOW_COMMENT
  | SET_SHOW_CLOCKING
  | SET_SHOW_MONTHLY_HOURS
  | SET_SHOW_MONTHLY_START
  | MOVE_TASK
  | SET_SHIFT_IDS_TO_DELETE
  | SET_NEW_DIMONA
  | SET_TASK_SCHEDULER
  | SET_MONTHLY_SELECTING_ELEMENTS
  | SET_MONTHLY_SELECTED_ELEMENTS
  | SET_ERRORS
  | SET_CREATE_NEW_SHIFT_BLOCK
  | SET_SHIFT_BLOCKS
  | SET_SHIFT_BLOCKS_LOADING
  | SET_ACTIVE_SHIFT_BLOCK
  | SET_EDITING_SHIFT_BLOCK
  | SET_SELECTED_SHIFTS_ON_SHIFT_KEY
  | SET_LOADING_ELEMENTS
  | SET_LOADING
  | SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER
  | SET_CLIPBOARD_HISTORY
  | SET_MONTH_START_POINT_ELEMENT
  | SET_MONTHLY_SELECTING_DIRECTION
  | SET_COPIED_SHIFT
  | SET_ACTIVE_WEEKLY_VIEW
  | SET_MONTHLY_SHIFTS_TO_REPLACE
  | SET_MONTHLY_SHIFTS_TO_REPLACE_SHIFT_BLOCK
  | SET_AY_SHIFT_IDS
  | SET_AY_SHIFT_IDS_LOADING
  | SET_AY_ERRORS;

export const updateShiftAndContext = (
  dispatch: React.Dispatch<ActionType>,
  oldValues: IShift,
  newValues: IShift,
  params: {
    picker: PickerType;
    startDate: moment.Moment;
    endDate: number;
    sectionId?: string;
    departmentId?: string;
    userRecordId?: string;
    department?: IDepartment;
    activeSection?: string;
    skills: ISkill[];
    userStatus: IUserStatus[];
  },
  onFinish?: () => void,
): void => {
  dispatch({
    type: 'UPDATE_SHIFT',
    payload: {
      ...newValues,
      optimistic: true,
    },
  });

  dispatch({
    type: 'UPDATE_FILTERED_SHIFTS',
  });

  Axios.patch(
    `${process.env.REACT_APP_API_URL}/v3/shifts/${oldValues.id!}`,
    { ...newValues },
    {
      params: {
        ...params,
        startDate: params.startDate.unix(),
        department: undefined,
        skills: undefined,
        userStatus: undefined,
      },
    },
  )
    .then((response) => {
      const {
        shifts,
        users,
        days = [],
        hours = [],
        totalBudget = null,
        comparisonTemplate = null,
        cycleNumber = null,
        scheduleModel = false,
        productivityIndex = null,
        productivityIndexReal = null,
      } = response.data;
      const filteredShifts = [...shifts].filter(
        (shift: IShift) => moment.unix(shift.start!).day() == params.startDate.day() && shift.shyftType == 1,
      );
      const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
        const shiftStartDate = moment.unix(currentShift.start);
        const shiftEndDate = moment.unix(currentShift.end);

        // Check if the start date is earlier than the current earliest shift
        if (shiftStartDate.isBefore(moment.unix(earliestShift.start))) {
          return currentShift;
        }

        return earliestShift;
      }, filteredShifts[0]);
      const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
        const shiftStartDate = moment.unix(currentShift.start);
        const shiftEndDate = moment.unix(currentShift.end);

        // Check if the start date is today and the end date is greater than the current latest shift
        if (shiftEndDate.isAfter(moment.unix(latestShift.end))) {
          return currentShift;
        }

        return latestShift;
      }, filteredShifts[0]);
      const startHour = params.department?.scheduleParams?.startHour || 0;
      const endHour = params.department?.scheduleParams?.endHour || 24;
      const dynamicHours = params.department?.scheduleParams?.dynamicHour;

      let nextDay = false;
      if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
        nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
      }

      const start = dynamicHours
        ? earliestShift && earliestShift.start
          ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
          : 9
        : startHour;
      const end = dynamicHours
        ? latestShift && latestShift.end
          ? moment.unix(latestShift.end).add(1, 'hour').hours()
          : 24
        : endHour;
      const tz = params.department?.timezone || 'Europe/Brussels';
      const isMidnight =
        earliestShift && earliestShift.startDate ? earliestShift.startDate.split(' ')[1].startsWith('00:') : false;

      dispatch({
        type: 'SET_SHIFTS',
        payload: {
          skills: params.skills,
          userStatus: params.userStatus,
          picker: params.picker,
          activeDepartment: params.department,
          shifts,
          users,
          days,
          hours:
            params.picker == 'day'
              ? hours.filter(
                  (hour: IScheduleHour) =>
                    hour.date >= moment(params.startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                    hour.date <=
                      moment(
                        params.startDate
                          .clone()
                          .add(1, 'day')
                          .toDate()
                          .setHours(end + 1),
                      ).unix(),
                )
              : hours,
          totalBudget,
          comparisonTemplate,
          cycleNumber,
          scheduleModel,
          productivityIndex,
          productivityIndexReal,
          activeSection: params.activeSection,
        },
      });
      dispatch({
        type: 'UPDATE_FILTERED_SHIFTS',
      });
      onFinish ? onFinish() : null;
    })
    .catch((error) => {
      let message = error.response?.data?.message as any[];
      if (error.response && error.response.data && error.response.data.errors) {
        message = Object.values(error.response.data.errors).flat(1)[0] as any;
      }
      const forceable = typeof message == 'string' ? false : message?.some((m) => m.forceBtn === true);
      if (forceable) {
        Modal.confirm({
          className: 'modal-constraints',
          icon: null,
          title: i18n.t('SCHEDULE.CONSTRAINTS.TITLE'),
          content: <Constraints message={message || []} />,
          cancelText: i18n.t('GLOBAL.CANCEL'),
          okText: i18n.t('GLOBAL.FORCE'),
          onOk: () => {
            updateShiftAndContext(dispatch, { ...oldValues, force: true }, { ...newValues, force: true }, params);
          },
          onCancel: () => {
            dispatch({
              type: 'UPDATE_SHIFT',
              payload: {
                ...oldValues,
                optimistic: false,
              },
            });
            dispatch({
              type: 'UPDATE_FILTERED_SHIFTS',
              payload: {
                reset: true,
              },
            });
          },
        });
      } else {
        Modal.info({
          className: 'modal-constraints',
          icon: null,
          title: i18n.t('SCHEDULE.CONSTRAINTS.TITLE'),
          content: <Constraints message={message || []} oneMessage={typeof message == 'string'} />,
          okText: 'Ok',
          okType: 'default',
          onOk: () => {
            dispatch({
              type: 'UPDATE_SHIFT',
              payload: {
                ...oldValues,
                optimistic: false,
              },
            });
            dispatch({
              type: 'UPDATE_FILTERED_SHIFTS',
              payload: {
                reset: true,
              },
            });
          },
          onCancel: () => {
            dispatch({
              type: 'UPDATE_SHIFT',
              payload: {
                ...oldValues,
                optimistic: false,
              },
            });
            dispatch({
              type: 'UPDATE_FILTERED_SHIFTS',
              payload: {
                reset: true,
              },
            });
          },
        });
      }
    });
};

export const updateShiftsAndContextWithShiftResponse = (
  dispatch: React.Dispatch<ActionType>,
  response: AxiosResponse<any>,
  params: {
    department: IDepartment;
    picker: PickerType;
    startDate: moment.Moment;
    endDate: moment.Moment;
    activeSection?: string;
    skills: ISkill[];
    userStatus: IUserStatus[];
  },
): void => {
  const {
    shifts,
    users,
    days = [],
    hours = [],
    totalBudget = null,
    comparisonTemplate = null,
    cycleNumber = null,
    productivityIndex = null,
    productivityIndexReal = null,
    scheduleModel = false,
  } = response.data;
  const filteredShifts = [...shifts].filter(
    (shift: IShift) => moment.unix(shift.start!).day() == params.startDate.day() && shift.shyftType == 1,
  );
  const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
    const shiftStartDate = moment.unix(currentShift.start);
    const shiftEndDate = moment.unix(currentShift.end);

    // Check if the start date is earlier than the current earliest shift
    if (shiftStartDate.isBefore(moment.unix(earliestShift.start))) {
      return currentShift;
    }

    return earliestShift;
  }, filteredShifts[0]);
  const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
    const shiftStartDate = moment.unix(currentShift.start);
    const shiftEndDate = moment.unix(currentShift.end);

    // Check if the start date is today and the end date is greater than the current latest shift
    if (shiftEndDate.isAfter(moment.unix(latestShift.end))) {
      return currentShift;
    }

    return latestShift;
  }, filteredShifts[0]);
  const startHour = params.department?.scheduleParams?.startHour || 0;
  const endHour = params.department?.scheduleParams?.endHour || 24;
  const dynamicHours = params.department?.scheduleParams?.dynamicHour;

  let nextDay = false;
  if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
    nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
  }

  const start = dynamicHours
    ? earliestShift && earliestShift.start
      ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
      : 9
    : startHour;
  const end = dynamicHours
    ? latestShift && latestShift.end
      ? moment.unix(latestShift.end).add(1, 'hour').hours()
      : 24
    : endHour;
  const tz = params.department?.timezone || 'Europe/Brussels';
  const isMidnight =
    earliestShift && earliestShift.startDate ? earliestShift.startDate.split(' ')[1].startsWith('00:') : false;

  batch(() => {
    dispatch({
      type: 'SET_SHIFTS',
      payload: {
        skills: params.skills,
        userStatus: params.userStatus,
        picker: params.picker,
        activeDepartment: params.department,
        shifts,
        users,
        days,
        hours:
          params.picker == 'day'
            ? hours.filter(
                (hour: IScheduleHour) =>
                  hour.date >= moment(params.startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                  hour.date <=
                    moment(
                      params.startDate
                        .clone()
                        .add(1, 'day')
                        .toDate()
                        .setHours(end + 1),
                    ).unix(),
              )
            : hours,
        totalBudget,
        comparisonTemplate,
        cycleNumber,
        scheduleModel,
        productivityIndex,
        productivityIndexReal,
        activeSection: params.activeSection,
      },
    });

    dispatch({
      type: 'UPDATE_FILTERED_SHIFTS',
      payload: { department: params.department },
    });
  });
};

export const assignShift = (
  distpatch: React.Dispatch<ActionType>,
  payload: {
    ids: string[];
  },
) => {
  // distpatch({
  //   type: 'ASSIGN_SHIFT',
  //   payload,
  // });
  message.info('Shift attribué à x');
};

export const copyShifts = (
  distpatch: React.Dispatch<ActionType>,
  payload: {
    ids: string[];
  },
) => {
  distpatch({
    type: 'COPY_SHIFTS',
    payload,
  });
  message.info(i18n.t('SCHEDULE.X_SHIFT(S)_COPIED', { count: payload.ids.length }));
};

export const copyDayShifts = (
  distpatch: React.Dispatch<ActionType>,
  payload: {
    ids: string[];
  },
) => {
  distpatch({
    type: 'COPY_DAY_SHIFTS',
    payload,
  });
  message.info(i18n.t('SCHEDULE.X_SHIFT(S)_COPIED', { count: payload.ids.length }));
};

export const moveTask = (
  shiftsMap: Map<string, IShift>,
  usersMap: Map<string, IUser>,
  dispatch: React.Dispatch<ActionType>,
  payload: MOVE_TASK['payload'],
  params: {
    picker: PickerType;
    startDate: moment.Moment;
    endDate: number;
    sectionId?: string;
    departmentId?: string;
    department?: IDepartment;
    activeSection?: string;
    skills: ISkill[];
    userStatus: IUserStatus[];
  },
): void => {
  const { source, destination } = payload;

  if (source.shiftId === destination.shiftId) {
    if (source.start === destination.newStart && source.end === destination.newEnd) {
      return;
    }
  }

  dispatch({
    type: 'MOVE_TASK',
    payload: payload,
  });

  if (source.shiftId) {
    dispatch({
      type: 'SET_SHIFT_OPTIMISTIC',
      payload: {
        optimistic: true,
        shiftId: source.shiftId,
      },
    });
  }

  dispatch({
    type: 'SET_SHIFT_OPTIMISTIC',
    payload: {
      optimistic: true,
      shiftId: destination.shiftId,
    },
  });

  dispatch({
    type: 'UPDATE_FILTERED_SHIFTS',
  });

  if (source.shiftId) {
    Axios.patch(
      `${process.env.REACT_APP_API_URL}/v3/shift-tasks/${source.taskId}`,
      {
        start: destination.newStart,
        end: destination.newEnd,
        shiftId: destination.shiftId,
      },
      {
        params: {
          ...params,
          startDate: params.startDate.unix(),
          department: undefined,
          skills: undefined,
          userStatus: undefined,
        },
      },
    )
      .then(({ data }) => {
        const days = (data.days as IScheduleDay[]) || [];
        const hours = (data.hours as IScheduleHour[]) || [];
        const {
          totalBudget,
          comparisonTemplate,
          cycleNumber = null,
          scheduleModel = false,
          productivityIndex = null,
          productivityIndexReal = null,
        } = data;
        const newShifts = data.shifts as IShift[];
        const newShiftsMap = new Map(shiftsMap);
        for (let i = 0; i < newShifts.length; i++) {
          const newShift = newShifts[i];
          newShiftsMap.set(newShift.id!, newShift);
        }
        const filteredShifts = [...newShifts].filter(
          (shift: IShift) => moment.unix(shift.start!).day() == params.startDate.day() && shift.shyftType == 1,
        );
        const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start!);
          const shiftEndDate = moment.unix(currentShift.end!);

          // Check if the start date is earlier than the current earliest shift
          if (shiftStartDate.isBefore(moment.unix(earliestShift.start!))) {
            return currentShift;
          }

          return earliestShift;
        }, filteredShifts[0]);
        const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start!);
          const shiftEndDate = moment.unix(currentShift.end!);

          // Check if the start date is today and the end date is greater than the current latest shift
          if (shiftEndDate.isAfter(moment.unix(latestShift.end!))) {
            return currentShift;
          }

          return latestShift;
        }, filteredShifts[0]);
        const startHour = params.department?.scheduleParams?.startHour || 0;
        const endHour = params.department?.scheduleParams?.endHour || 24;
        const dynamicHours = params.department?.scheduleParams?.dynamicHour;

        let nextDay = false;
        if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
          nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
        }

        const start = dynamicHours
          ? earliestShift && earliestShift.start
            ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
            : 9
          : startHour;
        const end = dynamicHours
          ? latestShift && latestShift.end
            ? moment.unix(latestShift.end).add(1, 'hour').hours()
            : 24
          : endHour;
        const tz = params.department?.timezone || 'Europe/Brussels';
        const isMidnight =
          earliestShift && earliestShift.startDate ? earliestShift.startDate.split(' ')[1].startsWith('00:') : false;

        dispatch({
          type: 'SET_SHIFTS',
          payload: {
            skills: params.skills,
            userStatus: params.userStatus,
            picker: params.picker,
            activeDepartment: params.department,
            shifts: [...newShiftsMap.values()],
            users: [...usersMap.values()],
            days,
            hours:
              params.picker == 'day'
                ? hours.filter(
                    (hour: IScheduleHour) =>
                      hour.date >= moment(params.startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                      hour.date <=
                        moment(
                          params.startDate
                            .clone()
                            .add(1, 'day')
                            .toDate()
                            .setHours(end + 1),
                        ).unix(),
                  )
                : hours,
            totalBudget,
            comparisonTemplate,
            cycleNumber,
            scheduleModel,
            productivityIndex,
            productivityIndexReal,
            activeSection: params.activeSection,
          },
        });

        dispatch({
          type: 'UPDATE_FILTERED_SHIFTS',
        });
      })
      .catch((error) => {
        console.error(error);
        message.error(i18n.t('SCHEDULE.TASK_COULD_NOT_MOVE'));
        dispatch({
          type: 'MOVE_TASK',
          payload: {
            source: {
              taskId: source.taskId,
              shiftId: destination.shiftId,
              start: destination.newStart,
              end: destination.newEnd,
            },
            destination: {
              shiftId: source.shiftId,
              newStart: source.start,
              newEnd: source.end,
            },
          },
        });
        dispatch({
          type: 'SET_SHIFT_OPTIMISTIC',
          payload: {
            optimistic: false,
            shiftId: source.shiftId,
          },
        });
        dispatch({
          type: 'SET_SHIFT_OPTIMISTIC',
          payload: {
            optimistic: false,
            shiftId: destination.shiftId,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: 'UPDATE_FILTERED_SHIFTS',
        });
      });
  } else {
    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/shift-tasks/${source.taskId}`,
      {
        start: destination.newStart,
        end: destination.newEnd,
        shiftId: destination.shiftId,
      },
      {
        params: {
          ...params,
          startDate: params.startDate.unix(),
          department: undefined,
          skills: undefined,
          userStatus: undefined,
        },
      },
    )
      .then(({ data }) => {
        const days = (data.days as IScheduleDay[]) || [];
        const hours = (data.hours as IScheduleHour[]) || [];
        const {
          totalBudget,
          comparisonTemplate,
          cycleNumber = null,
          scheduleModel = false,
          productivityIndex = null,
          productivityIndexReal = null,
        } = data;
        const newShifts = data.shifts as IShift[];
        const newShiftsMap = new Map(shiftsMap);
        for (let i = 0; i < newShifts.length; i++) {
          const newShift = newShifts[i];
          newShiftsMap.set(newShift.id!, newShift);
        }
        const filteredShifts = [...newShifts].filter(
          (shift: IShift) => moment.unix(shift.start!).day() == params.startDate.day() && shift.shyftType == 1,
        );
        const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start!);
          const shiftEndDate = moment.unix(currentShift.end!);

          // Check if the start date is earlier than the current earliest shift
          if (shiftStartDate.isBefore(moment.unix(earliestShift.start!))) {
            return currentShift;
          }

          return earliestShift;
        }, filteredShifts[0]);
        const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start!);
          const shiftEndDate = moment.unix(currentShift.end!);

          // Check if the start date is today and the end date is greater than the current latest shift
          if (shiftEndDate.isAfter(moment.unix(latestShift.end!))) {
            return currentShift;
          }

          return latestShift;
        }, filteredShifts[0]);
        const startHour = params.department?.scheduleParams?.startHour || 0;
        const endHour = params.department?.scheduleParams?.endHour || 24;
        const dynamicHours = params.department?.scheduleParams?.dynamicHour;

        let nextDay = false;
        if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
          nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
        }

        const start = dynamicHours
          ? earliestShift && earliestShift.start
            ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
            : 9
          : startHour;
        const end = dynamicHours
          ? latestShift && latestShift.end
            ? moment.unix(latestShift.end).add(1, 'hour').hours()
            : 24
          : endHour;
        const tz = params.department?.timezone || 'Europe/Brussels';
        const isMidnight =
          earliestShift && earliestShift.startDate ? earliestShift.startDate.split(' ')[1].startsWith('00:') : false;

        dispatch({
          type: 'SET_SHIFTS',
          payload: {
            skills: params.skills,
            userStatus: params.userStatus,
            picker: params.picker,
            activeDepartment: params.department,
            shifts: [...newShiftsMap.values()],
            users: [...usersMap.values()],
            days,
            hours:
              params.picker == 'day'
                ? hours.filter(
                    (hour: IScheduleHour) =>
                      hour.date >= moment(params.startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                      hour.date <=
                        moment(
                          params.startDate
                            .clone()
                            .add(1, 'day')
                            .toDate()
                            .setHours(end + 1),
                        ).unix(),
                  )
                : hours,
            totalBudget,
            comparisonTemplate,
            cycleNumber,
            scheduleModel,
            productivityIndex,
            productivityIndexReal,
            activeSection: params.activeSection,
          },
        });

        dispatch({
          type: 'UPDATE_FILTERED_SHIFTS',
        });
      })
      .catch((error) => {
        console.error(error);
        message.error(i18n.t('SCHEDULE.TASK_COULD_NOT_MOVE'));
        dispatch({
          type: 'SET_SHIFT_OPTIMISTIC',
          payload: {
            optimistic: false,
            shiftId: destination.shiftId,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: 'UPDATE_FILTERED_SHIFTS',
        });
      });
  }
};
