import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPcCategory } from '@/types/hr/pc-category.model';
import { IUser } from '@/types/user.model';
import { getBase64, handleError } from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber, Select, Spin, Upload, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { SelectValue } from 'antd/es/select';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormActions from '../../FormActions';
import HrField from './HrField';
import ModalError from './ModalError';

interface Props {
  className?: string;
  user?: IUser;
}

const AbsoluteYou: React.FC<Props> = ({ className, user }) => {
  const { i18n, t } = useTranslation();
  const {
    state: {
      activeDepartment,
      hrFields,
      loadingHrFields,
      loadingPcCategories,
      pcCategories,
      loadingUserCategories,
      userCategories,
      loadingAyWorkerTypes,
      ayWorkerTypes,
    },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);
  const [loadingSyncAy, setLoadingSyncAy] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [filesListRecto, setFilesListRecto] = useState<UploadFile[]>([]);
  const [filesListVerso, setFilesListVerso] = useState<UploadFile[]>([]);
  const [idRecto, setIdRecto] = useState<RcFile | null>(null);
  const [idVerso, setIdVerso] = useState<RcFile | null>(null);
  const [activePc, setActivePc] = useState<IPcCategory | null>(null);
  const [modalErrorVisible, setModalErrorVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const locale = i18n.language || 'fr';

  useEffect(() => {
    if (!user) return;
    getAyParams();
  }, [user]);

  useEffect(() => {
    if (!userData) {
      setUserData(null);
      onReset();
      return;
    }
    if (pcCategories.length == 0) return;
    if (userData.category_id) {
      const pc = pcCategories.find((category) => category.id == userData.category_id);
      if (!pc) return;
      setActivePc(pc);
    }
  }, [userData, pcCategories]);

  const getAyParams = () => {
    setLoading(true);
    onReset();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/ay/user/${user?.recordId}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        form.setFieldsValue({
          ...data.ay,
          id_recto_file: data.id_recto,
          id_verso_file: data.id_verso,
          ...data,
        });
        setUserData(data.ay);
        if (data.id_recto) {
          setFilesListRecto([
            {
              uid: '-1',
              name: data.id_recto,
              url: data.id_recto,
            },
          ]);
        } else {
          setFilesListRecto([]);
        }
        if (data.id_verso) {
          setFilesListVerso([
            {
              uid: '-1',
              name: data.id_verso,
              url: data.id_verso,
            },
          ]);
        } else {
          setFilesListVerso([]);
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = (values: any) => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/ay/user/${user?.recordId}`,
        {
          ay: {
            ...values,
            id_recto: undefined,
            id_recto_file: undefined,
            id_verso: undefined,
            id_verso_file: undefined,
          },
          id_recto: values.id_recto,
          id_verso: values.id_verso,
        },
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(() => {
        message.success(t('USERS.AY_USERS_SYNCED'));
        setFormHasChanged(false);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSync = () => {
    setLoadingSyncAy(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/ay/sync-user`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
            recordId: user?.recordId,
          },
        },
      )
      .then(() => {
        message.success(t('USERS.AY_USERS_SYNCED'));
      })
      .catch((error) => {
        if (error && error.response && error.response && error.response.data.statusCode == 422) {
          setModalErrorVisible(true);
          setErrors(error.response.data.errors);
        } else {
          handleError(error);
        }
      })
      .finally(() => {
        setLoadingSyncAy(false);
      });
  };

  const beforeUpload = (file: RcFile, side: 'recto' | 'verso') => {
    if (side == 'recto') {
      setIdRecto(file);
      getBase64(file).then((base64) => {
        form.setFieldsValue({
          id_recto: base64,
        });
      });
      setFilesListRecto([
        {
          ...file,
          originFileObj: file,
        },
      ]);
    } else {
      setIdVerso(file);
      getBase64(file).then((base64) => {
        form.setFieldsValue({
          id_verso: base64,
        });
      });
      setFilesListVerso([
        {
          ...file,
          originFileObj: file,
        },
      ]);
    }
    return false;
  };

  const onClearFile = (side: 'recto' | 'verso') => {
    if (side == 'recto') {
      setIdRecto(null);
      setFilesListRecto([]);
    }
    if (side == 'verso') {
      setIdVerso(null);
      setFilesListVerso([]);
    }
  };

  const onReset = () => {
    setFormHasChanged(false);
    setIdRecto(null);
    setIdVerso(null);
    form.resetFields();
    if (userData) {
      form.setFieldsValue(userData);
      if (userData && userData.id_recto) {
        setFilesListRecto([
          {
            uid: '-1',
            name: userData.id_recto,
            url: userData.id_recto,
          },
        ]);
      }
      form.setFieldsValue(userData);
      if (userData && userData.id_verso) {
        setFilesListVerso([
          {
            uid: '-1',
            name: userData.id_verso,
            url: userData.id_verso,
          },
        ]);
      }
    }
  };

  const onPcChange = (value: SelectValue) => {
    const pc = pcCategories.find((category) => category.id == value);
    if (!pc) return;
    setActivePc(pc);
  };

  let previousField = '';

  return (
    <div className={className}>
      <Spin spinning={loadingHrFields || loading}>
        <Form
          className="container"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onValuesChange={() => setFormHasChanged(true)}
          style={{ paddingBottom: formHasChanged ? 50 : 0 }}
        >
          <h4>Ref. AbsoluteYOU : {form.getFieldValue('ay_id') || '----'}</h4>
          <div className="content">
            <div className="left">
              <div style={{ display: 'none' }}>
                <Form.Item label="ID" name={'ay_id'}>
                  <Input size="large" placeholder="ID" readOnly disabled />
                </Form.Item>
              </div>
              <Form.Item label={t('GLOBAL.USER_CATEGORY')} name={'user_type'}>
                <Select
                  style={{ width: '100%' }}
                  getPopupContainer={(trigger) => trigger}
                  allowClear={true}
                  loading={loadingUserCategories}
                  disabled={loadingUserCategories}
                  placeholder={t('FORMS.USER_CATEGORY_PLACEHOLDER')}
                  size="large"
                >
                  {userCategories
                    ?.filter((x) => x.active)
                    ?.map((userCategory) => (
                      <Select.Option key={`status_${userCategory.id}`} value={userCategory.id!}>
                        {userCategory.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('GLOBAL.WORKER_TYPE')} name="employee_type" rules={[{ required: true }]}>
                <Select
                  style={{ width: '100%' }}
                  getPopupContainer={(trigger) => trigger}
                  allowClear={true}
                  loading={loadingAyWorkerTypes}
                  disabled={loadingAyWorkerTypes}
                  placeholder={t('GLOBAL.WORKER_TYPE')}
                  size="large"
                >
                  {ayWorkerTypes.map((type) => (
                    <Select.Option key={`status_${type.id}`} value={type.id!}>
                      {type[`name_${locale}`]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {!loadingPcCategories && pcCategories.length > 0 && (
                <>
                  <div>
                    <Form.Item label={t('GLOBAL.JOB_CATEGORY')} name={'category_id'} rules={[{ required: true }]}>
                      <Select
                        placeholder={t('GLOBAL.JOB_CATEGORY')}
                        size="large"
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onPcChange}
                      >
                        {pcCategories.map((category) => (
                          <Select.Option value={category.id}>{(category as any)[`name_${locale}`]}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item label={t('GLOBAL.FUNCTION')} name={'function_id'} rules={[{ required: true }]}>
                    <Select
                      placeholder={t('GLOBAL.FUNCTION')}
                      size="large"
                      showSearch
                      allowClear
                      filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={onPcChange}
                    >
                      {activePc &&
                        activePc.functions.map((category) => (
                          <Select.Option value={category.id}>{(category as any)[`name_${locale}`]}</Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              )}

              <Form.Item name="manual_wage" valuePropName="checked">
                <Checkbox>{t('GLOBAL.AY_MANUAL_WAGE')}</Checkbox>
              </Form.Item>

              <Form.Item
                shouldUpdate={(prevValues, currentValues) => prevValues.manual_wage !== currentValues.manual_wage}
              >
                {({ getFieldValue }) =>
                  getFieldValue('manual_wage') && (
                    <Form.Item label={t('GLOBAL.HOURLY_WAGE')} name="hourly_wage" rules={[{ required: true }]}>
                      <InputNumber
                        size="large"
                        min={0}
                        max={1000}
                        step={0.01}
                        placeholder={t('GLOBAL.HOURLY_WAGE')}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>

              {hrFields.map((field, index) => {
                previousField = hrFields[index - 1] ? hrFields[index - 1].name : '';
                if (previousField == 'dependent_spouse') {
                  previousField = 'dependent_children';
                  return (
                    <>
                      <Form.Item
                        label={t('GLOBAL.DEPENDENT_CHILDREN')}
                        name={'dependent_children'}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          size="large"
                          placeholder={t('GLOBAL.DEPENDENT_CHILDREN')}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                      <HrField className="field" field={field} />
                    </>
                  );
                }
                return <HrField className="field" field={field} />;
              })}
            </div>
            <div className="right">
              <div className="identity-card">
                <Form.Item className="id-hidden" name="id_recto">
                  <Input hidden />
                </Form.Item>
                <Form.Item label={t('GLOBAL.IDENTITY_CARD_FRONT')} name="id_recto_file" rules={[{ required: true }]}>
                  <Upload
                    beforeUpload={(file: RcFile) => beforeUpload(file, 'recto')}
                    listType="picture-card"
                    accept="image/png, image/jpeg, image/jpg"
                    maxCount={1}
                    onRemove={() => onClearFile('recto')}
                    fileList={filesListRecto}
                    multiple={false}
                  >
                    {!idRecto && filesListRecto.length < 1 && <UploadOutlined />}
                  </Upload>
                </Form.Item>
                <Form.Item className="id-hidden" name="id_verso">
                  <Input hidden />
                </Form.Item>
                <Form.Item label={t('GLOBAL.IDENTITY_CARD_BACK')} name="id_verso_file" rules={[{ required: true }]}>
                  <Upload
                    beforeUpload={(file: RcFile) => beforeUpload(file, 'verso')}
                    listType="picture-card"
                    accept="image/png, image/jpeg, image/jpg"
                    maxCount={1}
                    onRemove={() => onClearFile('verso')}
                    fileList={filesListVerso}
                    multiple={false}
                  >
                    {!idVerso && filesListVerso.length < 1 && <UploadOutlined />}
                  </Upload>
                </Form.Item>
              </div>
              <Button
                size="large"
                htmlType="button"
                loading={loadingSyncAy}
                type="primary"
                className="sync-button"
                onClick={onSync}
              >
                {t('GLOBAL.SYNC_TO_AY')}
              </Button>
            </div>
          </div>
          <FormActions saving={loading} onReset={onReset} active={formHasChanged} />
        </Form>
      </Spin>
      <ModalError errors={errors} setErrors={setErrors} visible={modalErrorVisible} setVisible={setModalErrorVisible} />
    </div>
  );
};

export default styled(AbsoluteYou)`
  .container {
    display: flex;
    flex-direction: column;

    .manual_wage {
      .ant-form-item-control {
        justify-content: center;
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      gap: 25px;

      .left {
        flex: 1;
        display: grid;
        grid-template-columns: 48% 48%;
        grid-column-gap: 25px;
      }

      .right {
        .identity-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          div {
            max-width: 200px;
          }
        }

        .id-hidden {
          display: none;
        }

        .sync-button {
          background-color: ${colors.ayBrand};
          border-color: ${colors.ayBrand};
          width: 100%;
          text-transform: uppercase;
        }

        .ant-upload-picture-card-wrapper {
          width: 150px;
          max-width: 150px;

          .ant-upload-list-picture-card-container,
          .ant-upload-select {
            width: 100%;
          }
        }
      }
    }
  }
`;
