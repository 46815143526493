import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDocument } from '@/types/document.model';
import { BackTop, Button, Checkbox, message, Modal, Table } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from './Header';

import { FEATURES } from '@/types/features.model';
import { handleError, isFeatureEnabled } from '@/utils';
import axios from 'axios';
import styled from 'styled-components';
import TableRowActions from './TableRowActions';

moment.tz.setDefault('Atlantic/Reykjavik');

export interface IContract extends IDocument {
  infos: {
    end?: number;
    renew: boolean;
    signed: number;
    start: number;
  };
}

interface Props {
  className?: string;
  userRecordId?: string;
}

const ContractList: React.FC<Props> = ({ className, userRecordId }) => {
  const {
    state: { activeDepartmentId: departmentId, activeSection, userCategories, users, features, activeDepartment },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const [columns, setColumns] = useState<any[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [autoContracts, setAutoContracts] = useState<number>(0);
  const [loadingContracts, setLoadingContracts] = useState<boolean>(false);
  const [filteringContracts, setFilteringContracts] = useState<boolean>(false);
  const [filteredContracts, setFilteredContracts] = useState<IContract[]>([]);
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [filterFilter, setFilterFilter] = useState<string>('ACTIVE');

  const contractsRef = useRef<any[]>([]);
  contractsRef.current = contracts;

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.NUMBER_SHORT'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: IContract, b: IContract) => +a.id - +b.id,
      },
      {
        title: 'Template',
        dataIndex: 'templateName',
        key: 'templateName',
        hidden: true,
        className: 'template-hidden',
      },
      ...(!userRecordId
        ? [
            {
              title: t('GLOBAL.NAME'),
              key: 'user',
              // eslint-disable-next-line react/display-name
              sorter: (a: IContract, b: IContract) => a.user.displayName?.localeCompare(b.user.displayName || ''),
              render: (text: string, record: IContract) => {
                return (
                  <Button
                    type="text"
                    style={{ color: colors.green }}
                    onClick={() => {
                      history.push(`/app/team/collaborators/${record.user?.recordId}`);
                    }}
                  >
                    {record.user?.displayName}
                  </Button>
                );
              },
            },
          ]
        : []),
      {
        title: t('GLOBAL.START'),
        key: 'start',
        sorter: (a: IContract, b: IContract) => b.infos?.start - a.infos?.start,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IContract) => {
          const start = record.infos?.start;
          if (start) {
            return <span>{moment.unix(start).format('L')}</span>;
          } else {
            return null;
          }
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        sorter: (a: IContract, b: IContract) => (b.infos?.end || 0) - (a.infos?.end || 0),
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IContract) => {
          const end = record.infos?.end;
          if (end) {
            return <span>{moment.unix(end).format('L')}</span>;
          } else {
            return null;
          }
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        key: 'status',
        dataIndex: 'status',
        onFilter: (value: any, record: IContract) => {
          if (!record.user) return false;
          const user = users.find((user) => user.recordId == record.user.recordId);
          if (!user || !user.category) return false;
          return user.category.id == value;
        },
        filters: userCategories.map((status) => ({
          value: status.id,
          text: status.name,
        })),
        render: (text: string, record: IContract) => {
          if (!record.user) return;
          const user = users.find((user) => user.recordId == record.user.recordId);
          if (!user || !user.category) return;
          return <span>{user.category.name}</span>;
        },
      },
      {
        title: t('CONTRACTS.COLUMNS.RENEW_SHORT'),
        key: 'renew',
        sorter: (a: IContract, b: IContract) => (a.infos?.renew === b.infos?.renew ? 0 : a.infos?.renew ? -1 : 1),
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IContract) => {
          return <Checkbox checked={record.infos?.renew} disabled={true} />;
        },
      },
      {
        title: t('CONTRACTS.COLUMNS.SIGNATURE_DATE'),
        key: 'signatureDate',
        sorter: (a: IContract, b: IContract) => b.infos?.signed - a.infos?.signed,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IContract) => {
          const signed = record.infos?.signed;
          if (signed) {
            return <span>{moment.unix(signed).format('L HH:mm')}</span>;
          } else {
            return null;
          }
        },
      },
      ...(!userRecordId
        ? [
            {
              title: t('GLOBAL.CREATION'),
              key: 'createdAt',
              sorter: (a: IContract, b: IContract) => b.itemDate - a.itemDate,
              // eslint-disable-next-line react/display-name
              render: (text: string, record: IContract) => {
                const itemDate = record.itemDate;
                if (itemDate) {
                  return <span>{moment.unix(itemDate).format('L HH:mm')}</span>;
                } else {
                  return null;
                }
              },
            },
          ]
        : []),
      {
        title: t('GLOBAL.CREATOR'),
        key: 'creator',
        // eslint-disable-next-line react/display-name
        sorter: (a: IContract, b: IContract) => a.creator.displayName?.localeCompare(b.creator.displayName || ''),
        render: (text: string, record: IContract) => {
          return (
            <Button
              type="text"
              style={{ color: colors.green }}
              onClick={() => {
                history.push(`/app/team/collaborators/${record.creator?.recordId}`);
              }}
            >
              {record.creator?.displayName}
            </Button>
          );
        },
      },
      {
        align: 'right',
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: string, contract: IContract) => {
          if (activeDepartment?.accountType == 'RGPD') return;
          return (
            <TableRowActions
              contract={contract}
              userRecordId={userRecordId}
              onEditContract={onEditContract}
              onDuplicateContract={onDuplicateContract}
              onWantToDeleteContract={onWantToDeleteContract}
              onWantToSendContract={onWantToSendContract}
            />
          );
        },
      },
    ]);
  }, [i18n.language, userRecordId]);

  useEffect(() => {
    if (!users) return;
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingContracts(true);
    setContracts([]);
    setAutoContracts(0);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/documents/all`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId,
          docType: 'CONTRACT',
          userRecordId,
          sectionId: activeSection,
          filter: filterFilter
            ? !location?.pathname.startsWith('/app/team/collaborators')
              ? filterFilter
              : undefined
            : undefined,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          let contracts = data.documents;
          if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
            if (activeSection) {
              const usersInSection = users
                .filter((user) => (user.sections ? (user.sections as any).split(',').includes(activeSection) : false))
                .map((user) => user.recordId);
              contracts = (contracts as IContract[]).filter((contract) =>
                usersInSection.includes(contract.user.recordId),
              );
            } else if (activeDepartment?.role !== 'ADMIN') {
              const activeUser = users.find((user) => user.recordId == activeDepartment?.userRecordId);
              if (activeUser) {
                const activeUserSections: string[] = (activeUser.sections as any).split(',');
                const usersInSection = users
                  .filter((user) =>
                    user.sections ? haveCommonElement((user.sections as any).split(','), activeUserSections) : false,
                  )
                  .map((user) => user.recordId);
                contracts = (contracts as IContract[]).filter((contract) => {
                  if (usersInSection.includes(contract.user.recordId)) return true;
                  return false;
                });
              }
            }
          }
          setContracts(contracts);
          setAutoContracts(data.automaticContract);
          setLoadingContracts(false);
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingContracts(false);
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [filterFilter, userRecordId, activeSection, users]);

  useEffect(() => {
    setFilteringContracts(true);
    if (contracts) {
      const filteredDocuments = contracts.filter((contract) => {
        let showContract = true;
        if (filterSearch) {
          const {
            id,
            user: { displayName = '' },
            templateName,
          } = contract;
          const name = templateName || '';
          showContract = [id, displayName, name].some((key) => {
            return key.toLowerCase().includes(filterSearch.toLowerCase());
          });
        }
        return showContract;
      });
      setFilteredContracts(filteredDocuments);
    } else {
      setFilteredContracts([]);
    }
    setTimeout(() => {
      setFilteringContracts(false);
    }, 0);
  }, [contracts, filterSearch]);

  const onEditContract = (contract: IContract) => {
    history.push('/app/documents/contracts/' + contract!.id, { userRecordId: contract!.user!.recordId });
  };

  const onDuplicateContract = (contract: IContract) => {
    history.push('/app/documents/contracts/new', { contractId: contract!.id });
  };

  const onDeleteContract = (contractId: string) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/documents/contracts/${contractId}`, {
        params: {
          departmentId,
        },
      })
      .then(() => {
        const contractsCopy = [...contractsRef.current];
        const index = contractsCopy.findIndex((x) => x.id === contractId);
        contractsCopy.splice(index, 1);
        setContracts(contractsCopy);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSendContract = (contractId: string, sendForSignature = false) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/documents/contracts/${contractId}/send`, null, {
        params: {
          departmentId,
          docType: 'CONTRACT',
          redosignature: sendForSignature ? '1' : undefined,
        },
      })
      .then(() => {
        message.success(t('CONTRACTS.MESSAGE_SEND_SUCCESS'));
      })
      .catch(() => {
        message.error(t('CONTRACTS.MESSAGE_SEND_ERROR'));
      });
  };

  const onWantToDeleteContract = (contractId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('CONTRACTS.MODAL_DELETE_CONTRACT.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteContract(contractId);
      },
      onCancel: () => {},
    });
  };

  const onWantToSendContract = (contractId: string, sendForSignature = false) => {
    Modal.confirm({
      title: sendForSignature ? t('CONTRACTS.MODAL_RESEND_CONTRACT_FOR_SIGN.TITLE') : t('GLOBAL.SENDING'),
      icon: null,
      content: sendForSignature
        ? t('CONTRACTS.MODAL_RESEND_CONTRACT_FOR_SIGN.CONTENT')
        : t('CONTRACTS.MODAL_SEND_CONTRACT.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.SEND'),
      onOk: () => {
        onSendContract(contractId, sendForSignature);
      },
      onCancel: () => {},
    });
  };

  return (
    <TableView className={className}>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        filterFilter={filterFilter}
        setFilterFilter={setFilterFilter}
        setFilterSearch={setFilterSearch}
        disabled={loadingContracts}
        userRecordId={userRecordId}
      />
      {autoContracts > 0 && (
        <h3
          style={{
            marginLeft: 10,
            marginBottom: 10,
            padding: 0,
            fontSize: 24,
            fontWeight: 'bold',
            color: colors.green,
          }}
        >
          {t('CONTRACTS.AUTO_CONTRACTS', { count: autoContracts })}
        </h3>
      )}
      <Table
        dataSource={filteredContracts}
        columns={columns}
        rowKey="id"
        loading={loadingContracts || filteringContracts}
        onChange={() => {
          window.scrollTo(0, 0);
        }}
        pagination={{ position: ['bottomCenter'] }}
      />
    </TableView>
  );
};

function haveCommonElement(arr1: string[], arr2: string[]) {
  return arr1.some((item) => arr2.includes(item));
}

export default styled(ContractList)`
  .template-hidden {
    display: none;
  }
`;
