import { IConstraint } from '@/types/constraint.model';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  message: IConstraint[];
  oneMessage?: boolean;
}

const Constraints: React.FC<Props> = ({ message, oneMessage }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  if (oneMessage) {
    return <span>{message}</span>;
  }

  return (
    <ul>
      {message.map((constraint, index) => (
        <li key={`constraint_${index}`}>{t(`SCHEDULE.CONSTRAINTS.${constraint.title}`, constraint.details)}</li>
      ))}
    </ul>
  );
};

export default Constraints;
