import i18n from '@/i18n.js';
import produce from 'immer';
import { ActionsType } from './actions';
import { getInitialState, InitialStateType } from './context';

export default function reducer(state: InitialStateType, action: ActionsType): InitialStateType {
  switch (action.type) {
    case 'RESET': {
      return getInitialState();
    }
    case 'SET_ACTIVE_SECTION': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.activeSection = payload;
        if (payload) {
          localStorage.setItem('activeSection', payload);
        } else {
          localStorage.removeItem('activeSection');
        }
      });
    }
    case 'SET_ACTIVE_DEPARTMENT': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.activeDepartmentId = payload;

        if (payload) {
          const department = state.departments.find((x) => x.id === payload);
          draftState.activeDepartment = department;
          localStorage.setItem('activeDepartmentId', department!.id!);
          setTimeout(() => {
            i18n.changeLanguage(department?.userLanguageCode);
          }, 0);
        } else {
          draftState.activeDepartment = undefined;
          localStorage.removeItem('activeDepartment');
        }

        if (payload && state.activeDepartmentId && state.activeDepartmentId !== payload) {
          draftState.activeSection = undefined;
          localStorage.removeItem('activeSection');
        }
      });
    }
    case 'SET_WEEKLY_VIEWS': {
      return produce(state, (draftState) => {
        draftState.weeklyViews = action.payload;
      });
    }
    case 'SET_LOADING_WEEKLY_VIEWS': {
      return produce(state, (draftState) => {
        draftState.loadingWeeklyViews = action.payload;
      });
    }
    case 'SET_SECTIONS': {
      return produce(state, (draftState) => {
        draftState.sections = action.payload;
      });
    }
    case 'SET_LOADING_SECTIONS': {
      return produce(state, (draftState) => {
        draftState.loadingSections = action.payload;
      });
    }
    case 'SET_DEPARTMENTS': {
      return produce(state, (draftState) => {
        draftState.departments = action.payload;
      });
    }
    case 'SET_LOADING_DEPARTMENTS': {
      return produce(state, (draftState) => {
        draftState.loadingDepartments = action.payload;
      });
    }
    case 'SET_TASKS': {
      return produce(state, (draftState) => {
        draftState.tasks = action.payload;
      });
    }
    case 'SET_LOADING_TASKS': {
      return produce(state, (draftState) => {
        draftState.loadingTasks = action.payload;
      });
    }
    case 'SET_SKILLS': {
      return produce(state, (draftState) => {
        draftState.skills = action.payload;
      });
    }
    case 'SET_LOADING_SKILLS': {
      return produce(state, (draftState) => {
        draftState.loadingSkills = action.payload;
      });
    }
    case 'SET_COSTS': {
      return produce(state, (draftState) => {
        draftState.costs = action.payload;
      });
    }
    case 'SET_LOADING_COSTS': {
      return produce(state, (draftState) => {
        draftState.loadingCosts = action.payload;
      });
    }
    case 'SET_USERS': {
      return produce(state, (draftState) => {
        draftState.users = action.payload;
      });
    }
    case 'SET_LOADING_USERS': {
      return produce(state, (draftState) => {
        draftState.loadingUsers = action.payload;
      });
    }
    case 'SET_USER_CATEGORIES': {
      return produce(state, (draftState) => {
        draftState.userCategories = action.payload;
      });
    }
    case 'SET_LOADING_USER_CATEGORIES': {
      return produce(state, (draftState) => {
        draftState.loadingUserCategories = action.payload;
      });
    }
    case 'SET_USERS_FIELDS': {
      return produce(state, (draftState) => {
        draftState.usersFields = action.payload;
      });
    }
    case 'SET_RESOURCES': {
      return produce(state, (draftState) => {
        draftState.resources = action.payload;
      });
    }
    case 'SET_LOADING_RESOURCES': {
      return produce(state, (draftState) => {
        draftState.loadingResources = action.payload;
      });
    }
    case 'UPDATE_RESOURCE': {
      return produce(state, (draftState) => {
        const { id } = action.payload;
        const resourceIndex = draftState.resources.findIndex((x) => x.id === id);
        if (~resourceIndex) {
          draftState.resources[resourceIndex] = { ...action.payload };
        }
      });
    }
    case 'UPDATE_RESOURCE_ATTRIBUT': {
      return produce(state, (draftState) => {
        const { id: resourceId, attributes } = action.payload;
        if (attributes?.[0]) {
          const resourceIndex = draftState.resources.findIndex((x) => x.id === resourceId);
          if (~resourceIndex) {
            const attributIndex =
              draftState.resources[resourceIndex].attributes?.findIndex((x) => x.id === attributes[0].id || '') || -1;
            if (~attributIndex) {
              draftState.resources[resourceIndex!]!.attributes![attributIndex!] = attributes[0];
            }
          }
        }
      });
    }
    case 'SET_DAYOFFS': {
      return produce(state, (draftState) => {
        draftState.dayoffs = action.payload;
      });
    }
    case 'SET_DAYOFFS_TYPES': {
      return produce(state, (draftState) => {
        draftState.dayoffTypes = action.payload;
      });
    }
    case 'SET_LOADING_DAYOFFS': {
      return produce(state, (draftState) => {
        draftState.loadingDayoffs = action.payload;
      });
    }
    case 'SET_PACKAGES': {
      return produce(state, (draftState) => {
        draftState.packages = action.payload;
      });
    }
    case 'SET_LOADING_PACKAGES': {
      return produce(state, (draftState) => {
        draftState.loadingPackages = action.payload;
      });
    }
    case 'SET_SHORTCUTS': {
      return produce(state, (draftState) => {
        draftState.shortcuts = action.payload;
      });
    }
    case 'SET_LOADING_SHORTCUTS': {
      return produce(state, (draftState) => {
        draftState.loadingShortcuts = action.payload;
      });
    }
    case 'SET_CUSTOM_HR_CODES': {
      return produce(state, (draftState) => {
        draftState.customHrCodes = action.payload;
      });
    }
    case 'SET_LOADING_CUSTOM_HR_CODES': {
      return produce(state, (draftState) => {
        draftState.loadingCustomHrCodes = action.payload;
      });
    }
    case 'SET_SHOW_HELP_VIDEO': {
      return produce(state, (draftState) => {
        draftState.showHelpVideo = action.payload;
      });
    }
    case 'SET_BADGES': {
      return produce(state, (draftState) => {
        draftState.badges = action.payload;
      });
    }
    case 'SET_LOADING_HR_PARTNERS': {
      return produce(state, (draftState) => {
        draftState.loadingHrPartners = action.payload;
      });
    }
    case 'SET_HR_PARTNERS': {
      return produce(state, (draftState) => {
        draftState.hrPartners = action.payload;
      });
    }
    case 'SET_OPS_FILTER': {
      return produce(state, (draftState) => {
        draftState.opsFilter = action.payload;
      });
    }
    case 'SET_LOADING_HR_FIELDS': {
      return produce(state, (draftState) => {
        draftState.loadingHrFields = action.payload;
      });
    }
    case 'SET_HR_FIELDS': {
      return produce(state, (draftState) => {
        draftState.hrFields = action.payload;
      });
    }
    case 'SET_LOADING_PC_CATEGORIES': {
      return produce(state, (draftState) => {
        draftState.loadingPcCategories = action.payload;
      });
    }
    case 'SET_PC_CATEGORIES': {
      return produce(state, (draftState) => {
        draftState.pcCategories = action.payload;
      });
    }
    case 'SET_LOADING_AY_WORKER_TYPES': {
      return produce(state, (draftState) => {
        draftState.loadingPcCategories = action.payload;
      });
    }
    case 'SET_AY_WORKER_TYPES': {
      return produce(state, (draftState) => {
        draftState.ayWorkerTypes = action.payload;
      });
    }
    case 'SET_LOADING_QUOTA_TEMPLATES': {
      return produce(state, (draftState) => {
        draftState.loadingQuotaTemplates = action.payload;
      });
    }
    case 'SET_QUOTA_TEMPLATES': {
      return produce(state, (draftState) => {
        draftState.quotaTemplates = action.payload;
      });
    }
    case 'SET_LOADING_QUOTA_CUSTOM': {
      return produce(state, (draftState) => {
        draftState.loadingQuotaCustom = action.payload;
      });
    }
    case 'SET_QUOTA_CUSTOM': {
      return produce(state, (draftState) => {
        draftState.quotaCustom = action.payload;
      });
    }
    case 'SET_LOADING_QUOTA_CUSTOM_TEMPLATES': {
      return produce(state, (draftState) => {
        draftState.loadingQuotaCustomTemplates = action.payload;
      });
    }
    case 'SET_QUOTA_CUSTOM_TEMPLATES': {
      return produce(state, (draftState) => {
        draftState.quotaCustomTemplates = action.payload;
      });
    }
    case 'SET_LOADING_SETTINGS': {
      return produce(state, (draftState) => {
        draftState.loadingSettings = action.payload;
      });
    }
    case 'SET_LOADING_FEATURES': {
      return produce(state, (draftState) => {
        draftState.loadingFeatures = action.payload;
      });
    }
    case 'SET_FEATURES': {
      return produce(state, (draftState) => {
        draftState.features = action.payload;
      });
    }
    case 'SET_LOADING_MARKETPLACE_APPS': {
      return produce(state, (draftState) => {
        draftState.loadingMarketplaceApps = action.payload;
      });
    }
    case 'SET_MARKETPLACE_APPS': {
      return produce(state, (draftState) => {
        draftState.marketplaceApps = action.payload;
      });
    }
    case 'SET_GROUP_ADMIN_START': {
      return produce(state, (draftState) => {
        draftState.groupAdminStart = action.payload;
      });
    }
    case 'SET_GROUP_ADMIN_USERS': {
      return produce(state, (draftState) => {
        draftState.groupAdminUsers = action.payload;
      });
    }
    case 'SET_GROUP_ADMIN_SORT': {
      return produce(state, (draftState) => {
        draftState.groupAdminSort = action.payload;
      });
    }
    case 'SET_GROUP_ADMIN_OPTIONS': {
      return produce(state, (draftState) => {
        draftState.groupAdminSections = action.payload.sections;
        draftState.groupAdminStatus = action.payload.status;
        draftState.groupAdminSkills = action.payload.skills;
        draftState.groupAdminAttributes = action.payload.attributes;
      });
    }
    case 'SET_GROUP_ADMIN_ACTIVE_USER': {
      return produce(state, (draftState) => {
        draftState.groupAdminActiveUser = action.payload;
      });
    }
    case 'SET_LOADING_PAY_PERIODS': {
      return produce(state, (draftState) => {
        draftState.loadingPayPeriods = action.payload;
      });
    }
    case 'SET_PAY_PERIODS': {
      return produce(state, (draftState) => {
        draftState.payPeriods = action.payload;
      });
    }
  }
}
