import AppContext from '@/pages/app/context';
import { PickerType } from '@/pages/app/hours/manage/redux/store';
import Colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled, isNullOrUndefined } from '@/utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import NoSectionSelected from '../components/NoSectionSelected';
import OpenShiftRow from '../components/OpenShiftRow';
import UserShiftLoadingRow from '../components/UserShiftLoadingRow';
import UserShiftRow from '../components/UserShiftRow';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import ModalAyError from './components/ModalAyError';
import Shifts from './components/Shifts';
import TotalHours from './components/TotalHours';

interface Props {
  className?: string;
  key?: string;
  refTotalHours: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  activeSection: string | undefined;
  department: IDepartment | null;
  turnover: any;
}

export type DailyUserShiftsType = {
  user: IUser;
  days: {
    date: number;
    shifts: IShift[];
  }[];
  totalMinutes: number;
  totalPrice: number;
};

export interface ITotalRole {
  id: string | null;
  name: string;
  color: string | undefined;
  background: string | undefined;
  minutes: number;
  price: number;
}

const AppHoursManageDaily: React.FC<Props> = ({
  className,
  key,
  refTotalHours,
  refPageContainer,
  activeSection,
  department,
  turnover,
}) => {
  const {
    filteredShiftsByUsers,
    days,
    picker,
    selectedDate,
    showOpenShifts,
    isLoading,
    comparisonTemplate,
    ayErrors,
    showShifts,
    showAbsences,
    showOtherDepartments,
    showProductivityIndex,
    filterOptions,
  } = useSelector(
    ({
      filteredShiftsByUsers,
      days,
      picker,
      selectedDate,
      showOpenShifts,
      isLoading,
      comparisonTemplate,
      ayErrors,
      showShifts,
      showAbsences,
      showOtherDepartments,
      showProductivityIndex,
      filterOptions,
    }: InitialStateType) => ({
      filteredShiftsByUsers,
      days,
      picker,
      selectedDate,
      showOpenShifts,
      isLoading,
      comparisonTemplate,
      ayErrors,
      showShifts,
      showAbsences,
      showOtherDepartments,
      showProductivityIndex,
      filterOptions,
    }),
    shallowEqual,
  );
  const {
    state: { features },
  } = useContext(AppContext);

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const routeMatch = useRouteMatch();
  const paths = routeMatch.path.split('/');
  const currentPicker = paths[paths.length - 2] as PickerType;
  const { formatedDate } = routeMatch.params as any;

  useEffect(() => {
    if (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate) {
      let startDate;
      let endDate;

      switch (currentPicker) {
        case 'week':
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
          break;
        case 'month':
          startDate = moment(formatedDate).startOf('month');
          endDate = moment(formatedDate).endOf('month');
          break;
        default:
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
      }

      hoursDispatch({
        type: 'SET_PICKER_AND_SELECTED_DATE',
        payload: {
          picker: currentPicker,
          selectedDate: moment(formatedDate).valueOf(),
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
        },
      });
    }
    // eslint-disable-next-line
  }, [currentPicker, formatedDate]);

  const [shifts, setShifts] = useState<DailyUserShiftsType[]>([]);
  const [openShifts, setOpenShifts] = useState<DailyUserShiftsType | null>(null);
  const [totalByRole, setTotalByRole] = useState<ITotalRole[][]>([]);
  const [selectedHeadcounts, setSelectedHeadcounts] = useState<number[]>([]);

  useEffect(() => {
    // CHECK IF THE DATE AND INTERVAL ARE CORRECTS
    if (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate) {
      return;
    }

    const totalByRole: ITotalRole[][] = [];
    const selectedHeadcounts: number[] = [];
    const dailyUsersShifts: DailyUserShiftsType[] = [];
    const dailyOpenShifts: DailyUserShiftsType = {
      user: {
        recordId: null,
      },
      days: [],
      totalMinutes: 0,
      totalPrice: 0,
    };

    for (let i = 0; i < days.length; i++) {
      const currentDay = moment.unix(days[i].date);
      const workingUser = new Set();

      dailyOpenShifts.days.push({
        date: currentDay.valueOf(),
        shifts: [],
      });

      const totalDayByRole: ITotalRole[] = [];

      // eslint-disable-next-line
      filteredShiftsByUsers.forEach((shiftsByUser) => {
        let totalUserMinute = 0;
        let totalUserPrice = 0;

        let totalUserDayMinute = 0;
        let totalUserDayPrice = 0;

        if (shiftsByUser.user.recordId) {
          // USERS SHIFTS
          let userDailyShifts = dailyUsersShifts.find((x) => x.user?.recordId === shiftsByUser.user.recordId);
          if (!userDailyShifts) {
            const length = dailyUsersShifts.push({
              user: shiftsByUser.user,
              days: [
                {
                  date: currentDay.valueOf(),
                  shifts: [],
                },
              ],
              totalMinutes: 0,
              totalPrice: 0,
            });
            userDailyShifts = dailyUsersShifts[length - 1];
          } else {
            userDailyShifts.days.push({
              date: currentDay.valueOf(),
              shifts: [],
            });
          }

          const shiftsOfDayOfUser: IShift[] = [];
          shiftsByUser.shifts.forEach((shift) => {
            const startShiftMoment = moment.unix(shift.start!);
            const endShiftMoment = moment.unix(shift.end!);

            if (startShiftMoment.isBetween(currentDay, moment(currentDay).add(1, 'day'), undefined, '[)')) {
              let hourlyRate = userDailyShifts?.user.hourlyRate;
              const duration = moment.duration(endShiftMoment.diff(startShiftMoment));
              duration.subtract(shift.pause!.unpaid, 'seconds');
              const durationAsMinutes = duration.asMinutes();

              if (isNullOrUndefined(hourlyRate) && shift.skills && shift.skills.length > 0) {
                const maxSkillPrice = Math.max(...shift.skills.map((skill) => skill.price || 0));
                hourlyRate = maxSkillPrice;
              }

              const shiftPrice = shift.price!;
              if (shift.shyftType !== 3) {
                // shiftPrice = (durationAsMinutes / 60) * (hourlyRate || 0);
                const shiftSectionId = shift?.section?.id;
                if (!activeSection || (activeSection && shiftSectionId === activeSection)) {
                  if (!shift.dayoff) {
                    totalUserDayMinute += durationAsMinutes;
                    workingUser.add(shiftsByUser.user.recordId);
                  }
                  totalUserMinute += durationAsMinutes;
                  if (!shift.dayoff || shift?.dayoff?.paid) {
                    totalUserPrice += shiftPrice;
                    totalUserDayPrice += shiftPrice;
                  }
                }
              }

              shiftsOfDayOfUser.push({
                ...shift,
                price: shiftPrice,
              });
            }
          });

          userDailyShifts.days[i].shifts = shiftsOfDayOfUser;
          userDailyShifts.totalMinutes += totalUserMinute;
          userDailyShifts.totalPrice += totalUserPrice;

          const role = userDailyShifts.user.userType || {
            id: null,
            name: 'Autres',
            color: '#ffffff',
            background: '#666666',
          };
          const totalDayRole = totalDayByRole.find((x) => x.id! === role.id!);

          if (totalDayRole) {
            totalDayRole.minutes += totalUserDayMinute;
            totalDayRole.price += totalUserDayPrice;
          } else {
            totalDayByRole.push({
              id: role.id!,
              name: role.name!,
              color: role.color!,
              background: role.background!,
              minutes: totalUserDayMinute,
              price: totalUserDayPrice,
            });
          }
        } else {
          // OPEN SHIFTS
          shiftsByUser.shifts.forEach((shift) => {
            const startShiftMoment = moment.unix(shift.start!);
            if (startShiftMoment.isBetween(currentDay, moment(currentDay).add(1, 'day'), undefined, '[)')) {
              dailyOpenShifts.days[i].shifts.push(shift);
              if (department?.scheduleParams?.count_free_shift_in_head_count) {
                workingUser.add('free-shifts');
              }
            }
          });
        }
      });

      totalByRole.push(totalDayByRole);
      selectedHeadcounts.push(workingUser.size);
    }
    setTotalByRole(totalByRole);
    setShifts(dailyUsersShifts);
    setOpenShifts(dailyOpenShifts);
    setSelectedHeadcounts(selectedHeadcounts);
    // eslint-disable-next-line
  }, [filteredShiftsByUsers]);

  if (department?.scheduleParams?.force_section && !activeSection) {
    return <NoSectionSelected />;
  }

  if (isLoading) {
    return (
      <React.Fragment>
        {[...new Array(3)].map((loadingRow, loadingRowIndex) => (
          <UserShiftLoadingRow
            key={`userLoading_${loadingRowIndex}`}
            baseClassName={`${className} ${picker}`}
          ></UserShiftLoadingRow>
        ))}
      </React.Fragment>
    );
  }

  const hideTotalHours = !department?.scheduleParams?.sidebarCounters && !department?.scheduleParams?.global_stats;
  const filtering = !!activeSection || showOtherDepartments || !showAbsences || !showShifts || !!filterOptions?.length;

  return (
    <React.Fragment key={key}>
      {isFeatureEnabled(features, FEATURES.FREESHIFTS) && openShifts && (
        <OpenShiftRow baseClassName={`${className} ${picker} no-print`} key={`userShifts_null`}>
          <Shifts userRecordId={null} days={openShifts.days} hideShifts={!showOpenShifts} />
        </OpenShiftRow>
      )}
      {filteredShiftsByUsers.some(
        (shiftByUser) =>
          shiftByUser.shifts.filter((shift) => !shift.dayoff || (shift.dayoff && shift.dayoff.paid)).length > 0,
      ) &&
        (!hideTotalHours || filtering) && (
          <TotalHours
            activeSection={activeSection}
            days={days}
            totalByRole={totalByRole}
            refTotalHours={refTotalHours}
            refPageContainer={refPageContainer}
            currency={department?.currency || ''}
            comparison={!isEmpty(comparisonTemplate)}
            department={department}
            selectedHeadcounts={selectedHeadcounts}
            turnover={turnover}
          />
        )}
      {shifts.map((dailyUserShifts) => {
        const { user, totalPrice, days, totalMinutes } = dailyUserShifts;
        const { recordId: userRecordId } = user;
        return (
          <UserShiftRow
            key={`userShifts_${userRecordId}`}
            baseClassName={`${className} ${picker}`}
            totalMinutes={totalMinutes}
            totalPrice={totalPrice}
            user={user}
            activeSection={activeSection}
            activeDepartment={department || undefined}
            showSkillInsteadOfStatus={department?.scheduleParams?.orderByWeek === 'SKILL'}
          >
            <Shifts userRecordId={userRecordId!} days={days} />
          </UserShiftRow>
        );
      })}
      <ModalAyError errors={ayErrors} visible={ayErrors != null} />
    </React.Fragment>
  );
};

const AppHoursManageDailyStyled = styled(AppHoursManageDaily)`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 100%;

  &.month {
    width: fit-content;
  }

  > .left {
    position: sticky;
    z-index: 1;
    left: 0;
    padding: 10px;
    border-right: 1px solid ${Colors.blueLight};
    border-bottom: 1px solid ${Colors.blueLight};
    background: white;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #f9f9f9;
    @media print {
      width: 120px;
    }
  }

  > .right {
    flex: 1;
    width: auto;
  }

  @media screen and (max-width: 900px) {
    > .left {
      width: 150px;
      overflow: hidden;
    }
  }
`;

export default AppHoursManageDailyStyled;
