import Error404 from '@/components/Error404';
import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Signatures from '../signatures';
import Templates from '../templates';
import Contract from './components/Contract';
import ContractsList from './components/ContractsList';

const AppDocumentsContracts: React.FC = () => {
  const { path, url } = useRouteMatch();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
      <Route path={`${path}/all`}>
        <ContainerView>
          <ContractsList />
        </ContainerView>
      </Route>
      {activeDepartment?.accountType !== 'RGPD' && (
        <Route path={`${path}/templates`}>
          <Templates documentType="CONTRACT" />
        </Route>
      )}
      {activeDepartment?.accountType !== 'RGPD' && (
        <Route path={`${path}/signatures`}>
          <Signatures />
        </Route>
      )}
      {activeDepartment?.accountType !== 'RGPD' && (
        <Route path={`${path}/:contractId`}>
          <Contract />
        </Route>
      )}
      <Route component={() => <Error404 />} />
    </Switch>
  );
};

export default AppDocumentsContracts;
