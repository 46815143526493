import AppContext from '@/pages/app/context';
import AddShift from '@/pages/app/hours/manage/components/AddShift';
import Colors from '@/styles/colors';
import { IShift } from '@/types/shift.model';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { Dispatch, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ActionType, updateShiftAndContext } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import Shift from './Shift';
import Unaivailability from './Unaivailability';

interface Props {
  className?: string;
  userRecordId: string | null;
  hideShifts?: boolean;
  days: {
    date: number;
    shifts: IShift[];
  }[];
}

const Shifts: React.FC<Props> = ({ className, hideShifts, userRecordId, days }) => {
  const {
    state: { activeDepartmentId, activeDepartment, activeSection, skills, userCategories },
  } = useContext(AppContext);
  const { shiftsByUsers, shiftsByUsersMapWithShiftId, picker, startDate, endDate, usersMap } = useSelector(
    ({ shiftsByUsers, shiftsByUsersMapWithShiftId, picker, startDate, endDate, usersMap }: InitialStateType) => ({
      shiftsByUsers,
      shiftsByUsersMapWithShiftId,
      picker,
      startDate,
      endDate,
      usersMap,
    }),
    shallowEqual,
  );

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { pathname } = useLocation();
  const month_view = pathname.startsWith('/app/hours/manage/month');

  const onDrop = (e: React.DragEvent, dayIndex: number) => {
    (e.target as HTMLElement).style.opacity = '1';
    (e.target as HTMLElement).classList.remove('onDragOver');

    const source = {
      shiftId: e.dataTransfer.getData('shiftId'),
      userRecordId: e.dataTransfer.getData('userRecordId') === 'null' ? null : e.dataTransfer.getData('userRecordId'),
      dayIndex: e.dataTransfer.getData('dayIndex'),
    };

    if (
      `${dayIndex}` === source.dayIndex &&
      (`${userRecordId}` === source.userRecordId || (!userRecordId && !source.userRecordId))
    ) {
      return;
    }

    const shiftsByUsersMapWithShiftIdResult = shiftsByUsersMapWithShiftId.get(source.shiftId);
    if (!shiftsByUsersMapWithShiftIdResult) {
      return console.log(`Shift not found ${JSON.stringify(source)}`);
    }

    const { userIndex, shiftIndex } = shiftsByUsersMapWithShiftIdResult;
    const shift = shiftsByUsers[userIndex].shifts[shiftIndex];

    if (!shift) return;

    if (shift.start && shift.end) {
      const newStart = moment
        .unix(shift.start!)
        .subtract(+source.dayIndex - dayIndex, 'day')
        .unix();

      const newEnd = moment
        .unix(shift.end!)
        .subtract(+source.dayIndex - dayIndex, 'day')
        .unix();

      const oldValues: IShift = {
        id: source.shiftId,
        start: shift.start,
        end: shift.end,
        userRecordId: shift.userRecordId,
      };

      const newValues: IShift = {
        id: source.shiftId,
        start: newStart,
        end: newEnd,
        userRecordId,
      };

      if (shift.tasks && shift.tasks.length) {
        oldValues.tasks = shift.tasks.map((task) => ({
          ...task,
        }));
        const difference = newStart - shift.start!;
        newValues.tasks = shift.tasks.map((task) => ({
          ...task,
          start: task.start! + difference,
          end: task.end! + difference,
        }));
      }

      updateShiftAndContext(hoursDispatch, oldValues, newValues, {
        picker,
        startDate,
        endDate: endDate.unix(),
        departmentId: activeDepartmentId,
        sectionId: activeSection,
        department: activeDepartment,
        activeSection,
        skills,
        userStatus: userCategories,
      });
    }
  };

  const onDragEnter = (e: React.DragEvent) => {
    (e.target as HTMLElement).classList.add('onDragOver');
  };

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const onDragLeave = (e: React.DragEvent) => {
    (e.target as HTMLElement).classList.remove('onDragOver');
  };

  const onDragStart = (e: React.DragEvent, shiftId: string, dayIndex: number) => {
    (e.target as HTMLElement).style.opacity = '0.3';
    e.dataTransfer.setData('shiftId', `${shiftId}`);
    e.dataTransfer.setData('dayIndex', `${dayIndex}`);
    e.dataTransfer.setData('userRecordId', `${userRecordId}`);
    e.dataTransfer.dropEffect = 'move';
  };

  const onDragEnd = (e: React.DragEvent) => {
    (e.target as HTMLElement).style.opacity = '1';
  };

  return (
    <div className={className}>
      {days.map((day, dayIndex) => {
        let identifier = `sgi_${userRecordId}_day_${dayIndex}`;
        return (
          <div
            key={identifier}
            onDrop={(e) => onDrop(e, dayIndex)}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={`droppable-zone ${picker}`}
          >
            {!hideShifts && (
              <React.Fragment>
                <React.Fragment>
                  {day.shifts.map((shift) => {
                    if (shift) {
                      identifier += `_si_${shift.id}`;
                      if (shift.shyftType === 3) {
                        return <Unaivailability shift={shift} key={identifier} />;
                      } else {
                        return (
                          <Shift
                            shift={shift}
                            key={identifier}
                            onDragStartHandler={(e: any) => onDragStart(e, shift.id!, dayIndex)}
                            onDragEndHandler={onDragEnd}
                            activeSection={activeSection}
                            activeDepartment={activeDepartment}
                          />
                        );
                      }
                    }
                    return null;
                  })}
                </React.Fragment>
                <AddShift
                  size="large"
                  shift={{
                    timestamp: day.date,
                    userRecordId: userRecordId ? userRecordId : null,
                    section: activeSection ? { id: activeSection } : undefined,
                    start: moment(day.date)
                      .set({ hour: activeDepartment?.scheduleParams?.default_shift_start_time || 0 })
                      .unix(),
                    end: moment(day.date)
                      .set({ hour: activeDepartment?.scheduleParams?.default_shift_end_time || 0 })
                      .unix(),
                  }}
                />
              </React.Fragment>
            )}
            {hideShifts && day.shifts.length > 0 && <div className="shift-counter">{day.shifts.length}</div>}
          </div>
        );
      })}
      {!month_view && !!activeDepartment?.scheduleParams?.sidebarCounters && (
        <div
          className={`droppable-zone ${picker} counter-column`}
          style={!userRecordId ? { backgroundColor: 'white' } : { backgroundColor: '#F7F8FB' }}
        >
          {!!userRecordId && (
            <React.Fragment>
              <div className="default">
                {usersMap.get(userRecordId)?.counters?.default?.map((counter, idx) => {
                  const { background, label, shortcode, foreground, value, showShortCode } = counter;
                  return (
                    <Tooltip key={`counter-${idx}`} title={label}>
                      <span
                        className={`tag`}
                        style={{
                          background,
                          color: foreground,
                        }}
                      >
                        {!!showShortCode && (
                          <React.Fragment>
                            {shortcode} : {value}
                          </React.Fragment>
                        )}
                        {!showShortCode && <React.Fragment>{value}</React.Fragment>}
                      </span>
                    </Tooltip>
                  );
                })}
              </div>
              <div className="extra">
                {usersMap.get(userRecordId)?.counters?.extra?.map((counter, idx) => {
                  const { background, label, shortcode, foreground, value, showShortCode } = counter;
                  return (
                    <Tooltip key={`counter-${idx}`} title={label}>
                      <span
                        className={`tag`}
                        style={{
                          background,
                          color: foreground,
                        }}
                      >
                        {!!showShortCode && (
                          <React.Fragment>
                            {shortcode} : {value}
                          </React.Fragment>
                        )}
                        {!showShortCode && <React.Fragment>{value}</React.Fragment>}
                      </span>
                    </Tooltip>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

const ShiftsStyled = styled(React.memo(Shifts))`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
  text-align: center;
  border-bottom: 1px solid ${Colors.blueLight};
  width: auto;
  @media print {
    align-items: stretch !important;
  }

  > .droppable-zone {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    box-sizing: border-box;
    flex-direction: column;
    padding: 5px 10px;
    overflow: hidden;
    width: 50px;

    &.counter-column {
      display: inline-block;
      text-align: right;

      .tag {
        border-radius: 3px;
        font-size: 10px;
        padding: 3px 5px;
      }

      .tag:nth-of-type(n + 2) {
        margin-left: 5px;
      }
    }

    @media print {
      height: 100%;
      padding: 2px 4px;
    }

    &.month {
      width: 180px;
    }

    &.onDragOver  {
      background: rgba(196, 199, 208, 0.2);
      * {
        pointer-events: none;
      }
    }

    &:nth-child(n + 2) {
      border-left: 1px solid ${Colors.blueLight};
    }

    > .shift-counter {
      background: rgba(0, 0, 0, 0.1);
      padding: 0px 10px;
      border-radius: 5px;
    }
  }
`;

export default ShiftsStyled;
