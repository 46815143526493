import AppContext from '@/pages/app/context';
import { Button, Input, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const { Option } = Select;
interface Props {
  className?: string;
  disabled: boolean;
  userRecordId?: string;
  filterFilter: string;
  setFilterFilter: React.Dispatch<React.SetStateAction<string>>;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<Props> = ({
  className,
  disabled,
  userRecordId,
  filterFilter,
  setFilterFilter,
  setFilterSearch,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <header className={className}>
      <div className="left">{!userRecordId && <h2>{t('DOCUMENTS.CONTRACTS.TITLE')}</h2>}</div>
      <div className="right">
        <div className="actions">
          {!userRecordId && (
            <Select className="select-type" value={filterFilter} onChange={(value: string) => setFilterFilter(value)}>
              <Option value={'ACTIVE'}>{t('CONTRACTS.FILTERS.SHOW_ACTIVE')}</Option>
              <Option value={'SIGNED'}>{t('CONTRACTS.FILTERS.SHOW_SIGNED')}</Option>
              <Option value={'UNSIGNED'}>{t('CONTRACTS.FILTERS.SHOW_UNSIGNED')}</Option>
              <Option value={'INACTIVE'}>{t('CONTRACTS.FILTERS.SHOW_INACTIVE')}</Option>
              <Option value={''}>{t('CONTRACTS.FILTERS.SHOW_ALL')}</Option>
            </Select>
          )}
          {!userRecordId && (
            <Input
              disabled={disabled}
              allowClear
              placeholder={t('GLOBAL.SEARCH')}
              suffix={<i className="icon-search"></i>}
              width={190}
              onChange={(e) => {
                setFilterSearch(e.target.value);
              }}
            />
          )}
          {activeDepartment?.accountType !== 'RGPD' && (
            <Button
              className="add"
              type="primary"
              onClick={() => history.replace('/app/documents/contracts/new', { userRecordId })}
            >
              {t('GLOBAL.ADD')}
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default styled(Header)`
  .add {
    width: 160px;
  }

  .right {
    .actions  {
      display: flex;
      gap: 15px;

      .select-type {
        width: 250px;
      }

      button {
        margin-left: 0 !important;
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;

    .add {
      width: 100%;
    }

    .left {
      h2 {
        margin-left: 0 !important;
      }
    }

    .right {
      .actions  {
        flex-direction: column;
        margin-top: 15px;

        .select-type {
          width: 100%;
        }
      }
    }
  }
`;
