import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IHrSheet } from '@/types/reports/timesheets/sheet.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled } from '@/utils';
import { Button, DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IHrSheet as IHrSheetOld } from '..';

interface Props {
  className?: string;
  fullmonth: boolean;
  format: 'hh:mm' | 'h,m';
  datePickerValue: moment.Moment;
  hrSheets?: IHrSheetOld[];
  preventBack: boolean;
  formHasChanged: boolean;
  newCodes: any[];
  sheet: IHrSheet;
  userOnly?: boolean;
  setFullmonth: React.Dispatch<React.SetStateAction<boolean>>;
  setFormat: React.Dispatch<React.SetStateAction<'hh:mm' | 'h,m'>>;
  setDatePickerValue: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setPreventBack: React.Dispatch<React.SetStateAction<boolean>>;
  setFormHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setNewCodes: React.Dispatch<React.SetStateAction<any[]>>;
  setNewUrl: React.Dispatch<React.SetStateAction<string | null>>;
  getHrSheet: (type?: 'planned' | 'clocking' | null) => void;
}

const HrSheetHeader: React.FC<Props> = ({
  className,
  fullmonth,
  format,
  datePickerValue,
  hrSheets,
  formHasChanged,
  newCodes,
  sheet,
  userOnly,
  setFullmonth,
  setFormat,
  setDatePickerValue,
  setPreventBack,
  setNewUrl,
  getHrSheet,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeDepartmentId, users, features },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const { search } = useLocation();
  const { userRecordId, start } = useParams() as any;
  const [user, setUser] = useState<IUser | null>(null);
  const savedFilters = localStorage.getItem(`table-filters-${activeDepartmentId}`);

  useEffect(() => {
    if (!userRecordId) return;

    const user = users.find((user) => user.recordId == userRecordId);
    if (!user) return;

    setUser(user);
  }, [userRecordId, users]);

  const onBack = () => {
    const newUrl = '/app/reports/hr-sheets/all';
    if (formHasChanged || newCodes.length > 1) {
      setPreventBack(true);
      setNewUrl(newUrl);
    } else {
      history.push(newUrl);
    }
  };

  const onSetType = (value: 'planned' | 'clocking') => {
    if (formHasChanged || newCodes.length > 1) {
      setPreventBack(true);
      setNewUrl(null);
      return;
    }
    getHrSheet(value);
  };

  const onSetFullmonth = (value: boolean) => {
    if (formHasChanged || newCodes.length > 1) {
      setPreventBack(true);
      setNewUrl(null);
      return;
    }
    localStorage.setItem('timesheets-fullmonth', JSON.stringify(value));
    setFullmonth(value);
  };

  const onSetFormat = (value: 'hh:mm' | 'h,m') => {
    if (formHasChanged || newCodes.length > 1) {
      setPreventBack(true);
      setNewUrl(null);
      return;
    }
    localStorage.setItem('timesheets-format', value);
    setFormat(value);
  };

  const onPrevious = () => {
    if (!hrSheets) return;
    let filters: any = null;
    let filteredHrSheets = [...hrSheets];
    if (savedFilters) {
      filters = JSON.parse(savedFilters);
      filteredHrSheets = filteredHrSheets.filter((sheet) =>
        filters && filters.status && sheet.status ? filters.status.includes(sheet.status.id) : true,
      );
    }

    const index = filteredHrSheets.findIndex((sheet) => sheet.recordId == userRecordId);

    if (index == -1) return;
    let newIndex = index - 1;

    if (newIndex == -1) {
      newIndex = filteredHrSheets.length - 1;
    }

    const sheet = filteredHrSheets[newIndex];
    const newUrl = `/app/reports/hr-sheets/${sheet.recordId}/${start}?version=v2`;
    if (formHasChanged || newCodes.length > 1) {
      setPreventBack(true);
      setNewUrl(newUrl);
      return;
    } else {
      history.push(newUrl);
    }
  };

  const onNext = () => {
    if (!hrSheets) return;
    let filters: any = null;
    let filteredHrSheets = [...hrSheets];
    if (savedFilters) {
      filters = JSON.parse(savedFilters);
      filteredHrSheets = filteredHrSheets.filter((sheet) =>
        filters && filters.status && sheet.status ? filters.status.includes(sheet.status.id) : true,
      );
    }

    const index = filteredHrSheets.findIndex((sheet) => sheet.recordId == userRecordId);
    if (index == -1) return;
    let newIndex = index + 1;

    if (newIndex == filteredHrSheets.length) {
      newIndex = 0;
    }

    const sheet = filteredHrSheets[newIndex];
    const newUrl = `/app/reports/hr-sheets/${sheet.recordId}/${start}?version=v2`;
    if (formHasChanged || newCodes.length > 1) {
      setPreventBack(true);
      setNewUrl(newUrl);
      return;
    } else {
      history.push(newUrl);
    }
  };

  return (
    <div className={className}>
      <div className="left">
        <Button type="link" style={{ transform: 'rotate(-90deg' }} size="small" onClick={onBack}>
          <i className="icon-up-big previous-icon" style={{ fontSize: 16 }} />
        </Button>
        <div>
          <DatePicker
            className="picker"
            picker="month"
            format="MMMM YYYY"
            value={datePickerValue}
            allowClear={false}
            onChange={(e) => {
              if (e) {
                setDatePickerValue(e);
              }
            }}
          />
        </div>
        {isFeatureEnabled(features, FEATURES.CLOCKING) ? (
          <div className="selector">
            <button
              type="button"
              className={`${sheet.PARAMS.type == 'SHIFT' ? 'active' : ''}`}
              onClick={() => onSetType('planned')}
            >
              {t('REPORTS.TIMESHEETS.PLANNED')}
            </button>
            <button
              type="button"
              className={`${sheet.PARAMS.type == 'CLOCKING' ? 'active' : ''}`}
              onClick={() => onSetType('clocking')}
            >
              {t('REPORTS.TIMESHEETS.CLOCKING')}
            </button>
          </div>
        ) : (
          <div className="empty-selector"></div>
        )}
      </div>
      {!userOnly && (
        <div className="center">
          <Button type="link" onClick={onPrevious}>
            <i className="icon-angle-left" />
          </Button>
          <span className="username">
            {sheet.user.name}
            {user?.userType?.name && <div className="worker-type">{user?.userType?.name}</div>}
          </span>
          <Button type="link" onClick={onNext}>
            <i className="icon-angle-right" />
          </Button>
        </div>
      )}
      <div className="right">
        <div className="selector">
          <button type="button" className={`${!fullmonth ? 'active' : ''}`} onClick={() => onSetFullmonth(false)}>
            {t('REPORTS.TIMESHEETS.FULLMONTH')}
          </button>
          <Tooltip overlay={<span> {t('REPORTS.TIMESHEETS.FULL_WEEKS')}</span>}>
            <button type="button" className={`${fullmonth ? 'active' : ''}`} onClick={() => onSetFullmonth(true)}>
              {t('REPORTS.TIMESHEETS.FULL_WEEKS_SHORT')}
            </button>
          </Tooltip>
        </div>
        <div className="selector">
          <button type="button" className={`${format == 'hh:mm' ? 'active' : ''}`} onClick={() => onSetFormat('hh:mm')}>
            hh:mm
          </button>
          <Tooltip overlay={<span>{t('GLOBAL.DECIMALS')}</span>}>
            <button type="button" className={`${format == 'h,m' ? 'active' : ''}`} onClick={() => onSetFormat('h,m')}>
              {t('GLOBAL.DECIMALS_SHORT')}
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default styled(HrSheetHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .left {
    display: flex;
    align-items: center;
    gap: 10px;

    .picker {
      width: 150px;
    }

    .empty-selector {
      width: 150px;
    }
  }

  .center {
    display: flex;
    align-items: center;

    .username {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 10px;

      .worker-type {
        border: 1px solid #ccc;
        padding: 2px 7px;
        border-radius: 5px;
        font-size: 11px;
        font-weight: normal;
        color: ${colors.grey};
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .selector {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 50px;

    button {
      padding: 5px 10px;

      &.active {
        background-color: ${colors.bluePastel};
        border-radius: 50px;
        color: #fff;
      }

      &.v2 {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
`;
