import { IDocumentPlaceholder } from '@/types/document.model';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ActionsType } from './actions';
import i18n from './i18n';
import colors from './styles/colors';
import { IDepartment } from './types/department.model';
import { FEATURES, IFeature } from './types/features.model';
import { RESOURCE_TYPES } from './types/field.model';
import { USER_ROLES } from './types/user-role.model';

export const lioWeekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saterday', 'sunday'];

export const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const PROFILE_TABS = ['/dashboard', '/profile', '/activity', '/counters', '/reports', '/documents', '/notes'];

export const SCHEDULE_VIEWS = ['day', 'ops', 'week', 'month'];

export const OPS_INTERVALS = [
  ['00:00'],
  ['01:00'],
  ['02:00'],
  ['03:00'],
  ['04:00'],
  ['05:00'],
  ['06:00'],
  ['07:00'],
  ['08:00'],
  ['09:00'],
  ['10:00'],
  ['11:00'],
  ['12:00'],
  ['13:00'],
  ['14:00'],
  ['15:00'],
  ['16:00'],
  ['17:00'],
  ['18:00'],
  ['19:00'],
  ['20:00'],
  ['21:00'],
  ['22:00'],
  ['23:00'],
];

export const REGIME_TYPES = ['FULL', 'PART'];

export const MONTHS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const ORDINAL_NUMBERS = [
  'ZEROTH',
  'FIRST',
  'SECOND',
  'THIRD',
  'FOURTH',
  'FIFTH',
  'SIXTH',
  'SEVENTH',
  'EIGHTH',
  'NINTH',
  'TENTH',
  'ELEVENTH',
  'TWELFTH',
  'THIRTEENTH',
  'FOURTEENTH',
  'FIFTEENTH',
  'SIXTEENTH',
  'SEVENTEENTH',
  'EIGHTEENTH',
  'NINETEENTH',
];

export function minutesToHoursAndOrMinutes(_minutes: number): string {
  let result = '';
  const minutesAbs = Math.abs(_minutes);
  let hours = Math.trunc(minutesAbs / 60);
  let minutes = Math.round(minutesAbs % 60);

  // Handle the case where minutes round up to 60
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (_minutes < 0) {
    result += '-';
  }
  if (hours) {
    result += `${hours}${i18n.t('GLOBAL.HOUR_UNIT')}`;
  }
  if (minutes || hours === 0) {
    // Ensure minutes are shown when there are no hours
    result += `${hours ? minutes.toString().padStart(2, '0') : minutes}${hours ? '' : i18n.t('GLOBAL.MINUTE_UNIT')}`;
  }
  return result;
}

export function minutesToHours(_minutes: number, withSymbol = false): string {
  const result = Math.round((_minutes / 60 + Number.EPSILON) * 10) / 10;
  if (withSymbol) {
    return `${result}${i18n.t('GLOBAL.HOUR_UNIT')}`;
  }
  return `${result}`;
}

export function minutesToHoursAndMinutes(_minutes: number, withSymbol = false): string {
  const totalMinutes = Math.abs(_minutes);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = (totalMinutes % 60).toString().padStart(2, '0');
  if (withSymbol) {
    return `${_minutes < 0 ? '-' : _minutes > 0 ? '+' : ''}${hours}:${minutes}`;
  }
  return `${hours}:${minutes}`;
}

export function roundNumber(x: number, decimal = 2): number {
  return Math.round((x + Number.EPSILON) * 10 ** decimal) / 10 ** decimal;
}

export function minutesToHoursAndMinutes2(_minutes: number, withSymbol = false): string {
  const totalMinutes = Math.abs(_minutes);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60)
    .toString()
    .padStart(2, '0');
  if (withSymbol) {
    return `${_minutes < 0 ? '-' : _minutes > 0 ? '+' : ''}${hours}${i18n.t('GLOBAL.HOUR_UNIT')}${minutes}`;
  }
  return `${hours}${i18n.t('GLOBAL.HOUR_UNIT')}${minutes}`;
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function localDateToUnixUTC(date: Date): number {
  const local = moment(date);
  return moment(local.format('YYYY-MM-DD HH:mm:ss')).unix();
}

export function localMomentToUnixUTC(m: Moment): number {
  return moment(m.local().format('YYYY-MM-DD HH:mm:ss')).unix();
}

export function nowUnix(): number {
  const now = moment();
  return moment(now.format('YYYY-MM-DD HH:mm:ss')).unix();
}

export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function getBlackOrWhiteContrast(hex: string): string {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt('0x' + hex[1] + hex[1]) / 255;
    g = parseInt('0x' + hex[2] + hex[2]) / 255;
    b = parseInt('0x' + hex[3] + hex[3]) / 255;

    // 6 digits
  } else if (hex.length === 7) {
    r = parseInt('0x' + hex[1] + hex[2]) / 255;
    g = parseInt('0x' + hex[3] + hex[4]) / 255;
    b = parseInt('0x' + hex[5] + hex[6]) / 255;
  }

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);

  const L = (max + min) / 2;
  return L > 0.65 ? '#000000' : '#FFFFFF';
}

export function valueForSearch(value: string): string {
  return value
    ? value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : value;
}

export function difference(object: any, base: any): any {
  function changes(object: any, base: any) {
    return _.transform(object, function (result: any, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export function getDisplayName(WrappedComponent: any): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function isDescendant(parent: HTMLElement, child: HTMLElement): boolean {
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export function getHtmlWithCodes(html: string, placeholders: IDocumentPlaceholder[]): string {
  const regex = /\{\{(.*?)\}\}/g;
  const placeholdersMap = new Map<string, string>();

  for (const placeholder of placeholders) {
    const { id, label } = placeholder;
    placeholdersMap.set(id, label);
  }

  const htmlWithCodes = html.replace(regex, (match: any, capture: string) => {
    if (capture) {
      return `<code data-key="${capture}" class="mceNonEditable" contenteditable="false">${placeholdersMap.get(
        capture,
      )}</code>`;
    } else {
      return match;
    }
  });
  return htmlWithCodes;
}

export const TINYMCE_PLUGINS = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount',
  'noneditable textpattern table advtable fullscreen code',
];

export const TINYMCE_TOOLBAR = [
  'undo redo | formatselect | fontsizeselect | fontselect |' +
    'forecolor | bold italic | backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | table |' +
    'removeformat | placeholders | code | fullscreen | help',
];

export const TINYMCE_CONTENT_STYLE = 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }';

export function getResourceTranslated(resource: {
  name?: string | undefined;
  resourceType?: string | undefined;
}): string {
  let name = '';
  switch (resource?.resourceType) {
    case RESOURCE_TYPES.PHONE:
      name = i18n.t('GLOBAL.PHONE');
      break;
    case RESOURCE_TYPES.FIRSTNAME:
      name = i18n.t('GLOBAL.FIRSTNAME');
      break;
    case RESOURCE_TYPES.LASTNAME:
      name = i18n.t('GLOBAL.LASTNAME');
      break;
    case RESOURCE_TYPES.EMAIL:
      name = i18n.t('GLOBAL.EMAIL');
      break;
    default:
      name = resource?.name || '';
      break;
  }
  return name;
}

export function convertSomePropertiesToMoment(data: any, properties: string[]): any {
  const newData = { ...data };
  for (const property of properties) {
    const [rootProperty, ...rest] = property.split('.');
    newData[rootProperty] = rest.length
      ? convertSomePropertiesToMoment(newData[rootProperty], [rest.join('.')])
      : newData[rootProperty]
      ? moment(newData[rootProperty])
      : null;
  }
  return newData;
}

export function convertSomeMomentPropertiesToStringDate(data: any, properties: string[]): any {
  const newData = { ...data };
  for (const property of properties) {
    const [rootProperty, ...rest] = property.split('.');
    newData[rootProperty] = rest.length
      ? convertSomeMomentPropertiesToStringDate(newData[rootProperty], [rest.join('.')])
      : newData[rootProperty]?.format('YYYY-MM-DD');
  }
  return newData;
}

export const lighten = (hex: string, amt: number) => {
  if (hex.includes('rgb')) {
    hex = rgbToHex(hex);
  }

  if (hex === 'black') {
    hex = '#000001';
  }
  if (hex === 'white') {
    hex = '#ffffff';
  }
  var usePound = false;

  if (hex[0] == '#') {
    hex = hex.slice(1);
    usePound = true;
  }

  var num = parseInt(hex, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const hexToRgb = (hex: string) => {
  // Remove the "#" if it exists
  hex = hex.replace(/^#/, '');

  // Parse the hex color into RGB values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGB values as an object
  return { r, g, b };
};

export const rgbToHex = (rgbString: string) => {
  // Extract the RGB values from the string
  const match = rgbString.match(/(\d+),\s*(\d+),\s*(\d+)/);

  if (!match) {
    // Handle invalid input
    throw new Error('Invalid RGB string format');
  }

  // Extract RGB values from the regex match
  const [, rStr, gStr, bStr] = match;
  const r = parseInt(rStr, 10);
  const g = parseInt(gStr, 10);
  const b = parseInt(bStr, 10);

  // Ensure that the values are within the valid range (0-255)
  const clamp = (value: number) => Math.min(255, Math.max(0, value));
  const clampedR = clamp(r);
  const clampedG = clamp(g);
  const clampedB = clamp(b);

  // Convert RGB to hex
  const hex = ((clampedR << 16) + (clampedG << 8) + clampedB).toString(16).padStart(6, '0');

  // Prepend "#" to the hex value
  return `#${hex}`;
};

export const getContrast = (hex: string) => {
  if (!hex) {
    hex = colors.green;
  }
  if (hex.includes('rgb')) {
    hex = rgbToHex(hex);
  }

  if (hex === 'transparent') {
    hex = '#000';
  }

  if (hex === 'white') {
    hex = '#ffffff';
  }

  if (hex === 'black') {
    hex = '#000000';
  }

  if (hex) {
    hex = hex.replace('#', '');
    var r = parseInt(hex.substr(0, 2), 16);
    var g = parseInt(hex.substr(2, 2), 16);
    var b = parseInt(hex.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }

  return 'black';
};

export const saveBlob = (blob: Blob, fileName: string) => {
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';

  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const handleError = (err: any, dispatch?: React.Dispatch<ActionsType>, key?: string) => {
  if (key && (errorHasKey(err, key) || errorHasStartingWithKey(err, key))) {
    return true;
  } else {
    if (err && err.response && err.response.data && err.response.data.errors) {
      if (dispatch) {
        dispatch({
          type: 'SET_ERRORS',
          payload: Object.values(err.response.data.errors).flat(1) as string[],
        });
      } else {
        message.error(Object.values(err.response.data.errors).flat(1)[0]);
      }
    } else {
      if (err) {
        Sentry.captureException(err);
      }
      if (err && err.name !== 'AxiosError' && err.request && err.request.status !== 0) {
        message.error(i18n.t('GLOBAL.GENERAL_ERROR'));
      }
    }
    return false;
  }
};

const errorHasKey = (err: any, key: string) => {
  if (err && err.response.status == 422 && err.response && err.response.data && err.response.data.errors) {
    const errorKeys = Object.keys(err.response.data.errors);
    if (!errorKeys.includes(key)) return false;
    return true;
  } else {
    return false;
  }
};

const errorHasStartingWithKey = (err: any, key: string) => {
  if (err && err.response.status == 422 && err.response && err.response.data && err.response.data.errors) {
    const filteredValuesArray = Object.keys(err.response.data.errors)
      .filter((key) => key.startsWith(key))
      .map((key) => err.response.data.errors[key]);

    if (filteredValuesArray.length <= 0) return false;
    return true;
  } else {
    return false;
  }
};

export const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const createNumberRangeArray = (start: number, end: number) => {
  if (start > end) {
    start = 0;
    end = 23;
  }

  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }

  return result;
};

export const convertDecimalHourToTime = (decimalHour: number, format?: 'hhmm' | 'hh:mm' | 'h,m') => {
  // Calculate hours and minutes
  let hours = Math.floor(decimalHour);
  let minutes = Math.round((decimalHour - hours) * 60);

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  // Format the time as "hh:mm"
  let timeFormatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  if (format == 'hhmm') {
    timeFormatted = minutesToHoursAndOrMinutes(decimalHour * 60);
  }

  if (format == 'h,m') {
    timeFormatted = `${decimalHour.toFixed(2)}`;
  }

  if (timeFormatted == '0.00' || timeFormatted == '') {
    timeFormatted = '-';
  }

  return timeFormatted;
};

export const getDatesInWeekWithinMonth = (year: number, month: number, weekNumber: number) => {
  const firstDayOfMonth = moment().year(year).month(month).date(1).startOf('day');
  const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
  const startOfWeek = firstDayOfWeek.clone().add(weekNumber - 1, 'weeks');
  const endOfWeek = startOfWeek.clone().endOf('week');
  const dates = [];

  for (let current = startOfWeek.clone(); current.isSameOrBefore(endOfWeek); current.add(1, 'day')) {
    dates.push(current.format('YYYY-MM-DD'));
  }

  return dates;
};

export const getDateInMonth = (year: number, month: number) => {
  const daysInMonth = moment().month(month).daysInMonth();
  const datesInMonth = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const date = moment()
      .year(year)
      .month(month - 1)
      .date(day);
    datesInMonth.push(date.format('YYYY-MM-DD'));
  }

  return datesInMonth;
};

export const getDatesBetweenDates = (start: moment.Moment, end: moment.Moment) => {
  const dates = [];
  const current = moment(start);

  while (current.isSameOrBefore(end, 'day')) {
    dates.push(current.format('YYYY-MM-DD'));
    current.add(1, 'day');
  }

  return dates;
};

export const createNumberArray = (start: number, end: number) => {
  if (start > end) {
    return [];
  }
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const getWeeksInYear = (year: number) => {
  const lastDay = moment().year(year).month(11).date(31);
  return lastDay.isoWeek();
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const isAdmin = (role: string) => {
  return role == 'ADMIN' || role == USER_ROLES.ADMIN;
};

export const isAdminOrHr = (role: string) => {
  return role == 'ADMIN' || role == USER_ROLES.ADMIN || role == 'HR' || role == USER_ROLES.HR;
};

export const isAdminOrHrOrPlanning = (role: string) => {
  return (
    role == 'ADMIN' ||
    role == USER_ROLES.ADMIN ||
    role == 'HR' ||
    role == USER_ROLES.HR ||
    role == 'PLANNING' ||
    role == USER_ROLES.PLANNING
  );
};

export const isFeatureEnabled = (features: IFeature[], module: FEATURES[keyof FEATURES]) => {
  const feature = features.find((feature) => feature.code === module);
  return feature != undefined && feature.installed;
};

export const isEmptyAccount = (features: IFeature[]) => {
  return !isFeatureEnabled(features, FEATURES.CLOCKING) && !isFeatureEnabled(features, FEATURES.SCHEDULE);
};

export const isClockingAccount = (features: IFeature[]) => {
  return isFeatureEnabled(features, FEATURES.CLOCKING) && !isFeatureEnabled(features, FEATURES.SCHEDULE);
};

export const isCountry = (department: IDepartment, countries: string[]) => {
  return countries.includes(department.countryCode!);
};

export const isFree = (department: IDepartment) => {
  return ['FREE-CLOCKING', 'FREE-SCHEDULE'].includes(department.accountType!);
};

export const isTrial = (department: IDepartment) => {
  return ['TRIAL'].includes(department.accountType!);
};

export const formatPrice = (number: number, activeDepartment?: IDepartment) => {
  // Determine the currency symbol
  const currencySymbol =
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: activeDepartment?.currency || 'EUR',
    })
      .formatToParts(1)
      .find((part) => part.type === 'currency')?.value || '€';

  // Format the number with spaces for thousands and commas for decimals
  const formattedNumber = new Intl.NumberFormat('fr-BE', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(Number(number))
    .replace(/\s/g, '.'); // Replace dot with comma for decimal separator

  return `${formattedNumber} ${currencySymbol}`;
};

export const now = (timezone?: string | null) => {
  return moment(
    moment()
      .tz(timezone || 'Europe/Brussels')
      .format('YYYY-MM-DD HH:mm:ss'),
  );
};

export const getHoursBefore = (momentDate: moment.Moment) => {
  const currentMoment = moment(momentDate);
  const hoursBeforeCurrent = [];

  for (let i = 0; i < currentMoment.hours(); i++) {
    hoursBeforeCurrent.push(i);
  }

  return hoursBeforeCurrent;
};

export const getMinutesBefore = (momentDate: moment.Moment) => {
  const currentMoment = moment(momentDate);
  const minutesBeforeCurrent = [];

  for (let i = 0; i < currentMoment.minutes(); i++) {
    minutesBeforeCurrent.push(i);
  }

  return minutesBeforeCurrent;
};

export const getFullWeeksBetween = (startDate: moment.Moment, endDate: moment.Moment) => {
  const daysDiff = endDate.diff(startDate, 'days');
  const fullWeeks = Math.ceil(daysDiff / 7);
  return fullWeeks;
};

export const DAYS_MAPPING: Record<string, number> = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const METRILIO_ALLOWED_DEPTS = [
  '3101',
  '3148',
  '4072',
  '4073',
  '4074',
  '4075',
  '4076',
  '4077',
  '4078',
  '4079',
  '4080',
  '4081',
  '4160',
  '5616',
];

export const GROUP_ADMIN_ALLOWED_ACCOUNTS = [
  '35',
  '20',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '37',
  '38',
  '39',
  '183',
  '517',
  '518',
  '628',
  '1012',
  '2346',
  '2347',
  '2555',
  '4205',
  '4699',
];

export const SUPER_ADMIN_ACCOUNTING_ALLOWED_EMAILS = [
  'emile.lahdo@shyfter.co',
  'lionel.hermans@gmail.com',
  'aw@freshnchic.be',
];

export const SUPER_ADMIN_DELETED_PROFILES_EMAILS = [
  'emile.lahdo@shyfter.co',
  'vincent.costens@shyfter.co',
  'lionel.hermans@gmail.com',
];

export const convertUtcToTimezoneDateAndTime = (dateString: string, activeDepartment?: IDepartment) => {
  return moment(dateString).isUTC()
    ? moment
        .utc(dateString)
        .tz(activeDepartment?.timezone || 'Europe/Brussels')
        .format('L HH:mm')
    : moment(dateString).format('L HH:mm');
};

export const shouldApplyBelgianVAT = (
  countryCode: string,
  vatValidated: boolean,
): { apply: boolean; europe: boolean } => {
  // EU countries (add or modify as needed)
  const euCountries: string[] = [
    'AL',
    'AD',
    'AM',
    'AT',
    'AZ',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'GE',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'KZ',
    'XK',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'MD',
    'MC',
    'ME',
    'NL',
    'MK',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'TR',
    'UA',
    'VA',
  ];

  // Check if the country is Belgium
  if (countryCode === 'BE') {
    return { apply: true, europe: true };
  }

  // Check if the country is outside the EU
  if (!euCountries.includes(countryCode)) {
    return { apply: false, europe: false };
  }

  // Check if the VAT number is validated
  if (vatValidated) {
    return { apply: false, europe: true };
  }

  // If the VAT number is not validated, apply the 21% VAT
  return { apply: true, europe: true };
};

export type Language = 'fr' | 'nl' | 'en' | 'es';

export type AccountType =
  | 'ONBOARDING'
  | 'ONBOARDING FINISHED'
  | 'PAID'
  | 'BLOCKED'
  | 'CHURN'
  | 'INACTIVE'
  | 'FREE'
  | 'RGPD'
  | 'TRIAL'
  | 'FREE TRIAL';
