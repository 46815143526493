import ModalProvisional from '@/pages/app/components/ModalProvisional';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IShift } from '@/types/shift.model';
import { Affix } from 'antd';
import moment, { Moment } from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../../redux/store';

interface Props {
  className?: string;
  offsetTop: number;
  department: IDepartment | null;
  startDate: Moment;
  totalBudget: number | null;
  totalBudgets: (number | null)[];
  columnWidth: number;
  refPageContainer: React.MutableRefObject<null> | null;
  quotas: any;
  turnover: any;
}

const Insights: React.FC<Props> = ({
  className,
  offsetTop,
  department,
  startDate,
  totalBudget,
  totalBudgets,
  columnWidth,
  refPageContainer,
  quotas,
  turnover,
}) => {
  const [affixed, setAffixed] = useState(false);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const { hours, loading_elements, shiftsMap } = useSelector(
    ({ hours, loading_elements, shiftsMap }: InitialStateType) => ({
      hours,
      loading_elements,
      shiftsMap,
    }),
  );

  const handleAffixed = (affixed: boolean) => {
    setAffixed(affixed);
    setTimeout(() => {
      const element = refPageContainer!.current! as HTMLElement;
      element.dispatchEvent(new Event('scroll'));
    });
  };

  const turnoverForDay =
    turnover &&
    turnover[startDate.startOf('day').unix()] &&
    (turnover[startDate.startOf('day').unix()].value || turnover[startDate.startOf('day').unix()].provisional);

  const filteredShifts = Array.from(shiftsMap.values()).filter(
    (shift: IShift) => moment.unix(shift.start!).day() == startDate.day() && shift.shyftType == 1,
  );
  const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
    const shiftStartDate = moment.unix(currentShift.start!);
    const shiftEndDate = moment.unix(currentShift.end!);

    // Check if the start date is earlier than the current earliest shift
    if (shiftStartDate.isBefore(moment.unix(earliestShift.start!))) {
      return currentShift;
    }

    return earliestShift;
  }, filteredShifts[0]);
  const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
    const shiftStartDate = moment.unix(currentShift.start!);
    const shiftEndDate = moment.unix(currentShift.end!);

    // Check if the start date is today and the end date is greater than the current latest shift
    if (shiftEndDate.isAfter(moment.unix(latestShift.end!))) {
      return currentShift;
    }

    return latestShift;
  }, filteredShifts[0]);
  const startHour = activeDepartment?.scheduleParams?.startHour || 0;
  const endHour = activeDepartment?.scheduleParams?.endHour || 24;
  const dynamicHours = activeDepartment?.scheduleParams?.dynamicHour;

  let nextDay = false;
  if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
    nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
  }

  const start = dynamicHours
    ? earliestShift && earliestShift.start
      ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
      : 9
    : startHour;
  const end = dynamicHours
    ? latestShift && latestShift.end
      ? moment.unix(latestShift.end).add(1, 'hour').hours()
      : 24
    : endHour;
  const tz = activeDepartment?.timezone || 'Europe/Brussels';

  return (
    <Affix
      target={() => document.getElementsByClassName('app-hours-container')[0] as HTMLElement}
      onChange={(affixed) => handleAffixed(!!affixed)}
      offsetTop={offsetTop}
    >
      <div>
        <div className={`${className} ${affixed ? 'affixed' : ''}`}>
          <div className="left">
            {t('SETTINGS.INSIGHTS.TITLE')}{' '}
            {turnoverForDay ? (
              <button
                onClick={() => {
                  setTimestamp(moment(startDate).startOf('day').unix());
                }}
              >
                <>
                  {new Intl.NumberFormat(language, {
                    style: 'currency',
                    currency: department?.currency || 'EUR',
                  }).format(turnover[startDate.startOf('day').unix()].value)}
                </>
              </button>
            ) : (
              <button
                onClick={() => {
                  setTimestamp(moment(startDate).startOf('day').unix());
                }}
              >
                {t('GLOBAL.ADD')}
              </button>
            )}
          </div>
          <div className={`right`}>
            {quotas &&
              turnover &&
              hours.map((hour, index) => {
                const quotasForDay = quotas[moment(hour.dateHuman).local().locale('en').format('dddd').toLowerCase()];
                const hours = moment.unix(hour.date).hours();

                if (!quotasForDay) return <div key={`budget_${index}`} style={{ width: columnWidth }}></div>;

                const found = quotasForDay.find((quota: any) => quota.start == hours);
                if (!found) return <div key={`budget_${index}`} style={{ width: columnWidth }}></div>;

                let date = moment.unix(hour.date).startOf('day').unix();
                if (moment.unix(hour.date).day() != startDate.day()) {
                  date = startDate.startOf('day').unix();
                }

                const turnoverForDay = turnover[date];
                if (!turnoverForDay) return <div key={`budget_${index}`} style={{ width: columnWidth }}></div>;

                const diffInHours = found.start > found.end ? found.end + 24 - found.start : found.end - found.start;

                if (hours == 22) {
                  console.log(moment(hour.dateHuman).local().locale('en').format('dddd').toLowerCase());
                }

                return (
                  <div
                    key={`budget_${index}`}
                    style={{ width: columnWidth, fontSize: 10 }}
                    className={`day-insights hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                  >
                    {turnoverForDay.value || turnoverForDay.provisional
                      ? new Intl.NumberFormat(language, {
                          style: 'currency',
                          currency: department?.currency || 'EUR',
                        })
                          .format(Number((turnoverForDay.value * found.percentage) / 100 / diffInHours))
                          .replace(/\D00(?=\D*$)/, '')
                          .replace(/\s/g, '')
                      : '-'}
                  </div>
                );
              })}
            {/* {totalBudgets?.map((budget, index) => (
              <div key={`budget_${index}`} style={{ width: columnWidth }}>
                {budget &&
                  new Intl.NumberFormat(language, { style: 'currency', currency: department?.currency || 'EUR' })
                    .format(budget)
                    .replace(/\D00(?=\D*$)/, '')}
              </div>
            ))} */}
          </div>
        </div>
        {department?.role === 'ADMIN' && (
          <ModalProvisional
            departmentId={department.id}
            timestamp={timestamp}
            value={turnoverForDay}
            onClose={() => setTimestamp(null)}
            onSave={() => location.reload()}
          />
        )}
      </div>
    </Affix>
  );
};

const InsightsStyled = styled(Insights)`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${colors.blueLight};
  background-color: #ffe9d0;
  width: 100%;

  @media (max-width: 1440px) {
    min-width: 100%;
  }

  &.affixed {
    max-width: 100%;
    overflow: hidden;
  }

  > div {
    &.left {
      flex-shrink: 0;
      position: sticky;
      left: 0;
      border-right: 1px solid ${colors.blueLight};
      width: 210px;
      background-color: #ffe9d0;
      padding: 10px;

      button {
        margin-left: 10px;
        color: ${colors.red};
        font-weight: 800;
        background-color: #ffcf97;
        border-radius: 3px;
      }
    }

    &.right {
      display: flex;
      flex-grow: 1;

      > div {
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0px;
        flex-shrink: 0;

        &:nth-child(n + 2) {
          border-left: 1px solid ${colors.blueLight};
        }
      }
    }
  }
`;

export default InsightsStyled;
