import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { IQuotaTemplateDetail } from '@/types/insights/quota-template-detail.model';
import { IQuotaTemplate } from '@/types/insights/quota-template.model';
import { WEEKDAYS, createNumberRangeArray, handleError, isFeatureEnabled } from '@/utils';
import { Button, Form, Input, InputNumber, Table, Tabs, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table/interface';
import axios from 'axios';
import { uniqueId } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Tab from '../components/Tab';
import QuotaTemplateBreadcrumb from './QuotaTemplateBreadcrumb';

interface Props {
  className?: string;
  activeTemplate: IQuotaTemplate | null;
}

const QuotaTemplate: React.FC<Props> = ({ className, activeTemplate }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeDepartmentId, sections, quotaTemplates, features },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const [columns, setColumns] = useState<ColumnsType<any[]>>();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [newValues, setNewValues] = useState<any>({});
  const history = useHistory();

  const start = activeDepartment?.scheduleParams?.startHour;
  const end = activeDepartment?.scheduleParams?.endHour;
  const hours =
    start !== null && start !== undefined && end !== null && end !== undefined
      ? createNumberRangeArray(start, end)
      : [];

  useEffect(() => {
    if (activeKey) return;
    if (!isFeatureEnabled(features, FEATURES.SECTIONS)) return;
    if (sections.length > 0) {
      setActiveKey(sections[0].id!);
    }
  }, [sections, activeKey]);

  useEffect(() => {
    let result: ColumnsType<any[]> = [];

    if (isFeatureEnabled(features, FEATURES.SECTIONS) && sections.length > 0) {
      hours.forEach((hour, index) => {
        const fullHour = `${hour <= 9 ? `0${hour}` : hour}:00`;
        result.push({
          className: 'input-cell',
          key: uniqueId(),
          title: `${fullHour.split(':')[0]}${t('GLOBAL.HOURS_SHORT')}`,
          render(value, record, index) {
            return (
              <Form.Item name={['details', `${activeKey}`, `${index}`, `${fullHour}`]} style={{ width: 30 }}>
                <InputNumber id={uniqueId()} min={0} maxLength={3} />
              </Form.Item>
            );
          },
        });
      });
    } else {
      hours.forEach((hour, index) => {
        const fullHour = `${hour}`;
        result.push({
          className: 'input-cell',
          key: uniqueId(),
          title: `${fullHour.split(':')[0]}${t('GLOBAL.HOURS_SHORT')}`,
          render(value, record, index) {
            return (
              <Form.Item name={['details', 'global', `${index}`, `${fullHour}`]} style={{ width: 30 }}>
                <InputNumber min={0} maxLength={3} />
              </Form.Item>
            );
          },
        });
      });
    }

    setColumns([
      ...[
        {
          key: 'day',
          dataIndex: 'day',
        },
      ],
      ...result,
    ]);
  }, [activeKey, quotaTemplates]);

  useEffect(() => {
    const data = WEEKDAYS.map((day) => {
      return {
        id: day,
        day: t(`GLOBAL.${day.toUpperCase()}`),
      };
    });
    setData(data);
  }, []);

  useEffect(() => {
    if (!activeTemplate) return;
    const details: any = {};
    if (isFeatureEnabled(features, FEATURES.SECTIONS) && sections.length > 0) {
      sections.forEach((section) => {
        activeTemplate.details.forEach(({ section_id, day, hour, value }) => {
          if (!details[section_id]) {
            details[section_id] = {};
          }
          if (!details[section_id][day - 1]) {
            details[section_id][day - 1] = {};
          }
          details[section_id][day - 1][hour] = value;
        });
      });
    } else {
      activeTemplate.details.forEach(({ day, hour, value }) => {
        if (!details['global']) {
          details['global'] = {};
        }
        if (!details['global'][day - 1]) {
          details['global'][day - 1] = {};
        }
        details['global'][day - 1][hour] = value;
      });
    }

    form.setFieldsValue({
      ...activeTemplate,
      details,
    });
  }, [activeTemplate, sections]);

  const onFinish = (values: any) => {
    const details: any[] = [];
    const allDetails = { ...values.details, ...newValues.details };

    Object.entries(allDetails).forEach(([id, days]: any) => {
      Object.entries(days).forEach(([day, hoursByDay]: any, index) => {
        if (hoursByDay) {
          const hourEntries = Object.entries(hoursByDay);
          const validHours = hourEntries.filter(([_, value]) => value !== undefined);

          details.push(
            ...validHours.map(([hour, value]) => ({
              section_id: Number(id),
              day: Number(day) + 1,
              hour: hour,
              value: Number(value),
            })),
          );
        }
      });
    });

    values.details = details;
    setLoading(true);
    if (activeTemplate) {
      editTemplate(values);
    } else {
      createTemplate(values);
    }
  };

  const createTemplate = (values: any) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/quotas/quota-templates`,
        {
          ...values,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        history.push(`/app/settings/insights/quota-templates`);
        dispatch({
          type: 'SET_QUOTA_TEMPLATES',
          payload: [...quotaTemplates, { ...data }]
            .filter((item: IQuotaTemplate) =>
              !isFeatureEnabled(features, FEATURES.SECTIONS)
                ? item.details.every((detail: IQuotaTemplateDetail) => detail.section_id === null)
                : true,
            )
            .sort((a: IQuotaTemplate, b: IQuotaTemplate) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        });
      })
      .catch((err) => {
        if (values.details && values.details.length == 0) {
          message.error(t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.EMPTY_ERROR_MESSAGE'));
        } else {
          handleError(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editTemplate = (values: any) => {
    if (!activeTemplate) return;
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/quotas/quota-templates/${activeTemplate.id}`,
        {
          ...values,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        history.push(`/app/settings/insights/quota-templates`);
        dispatch({
          type: 'SET_QUOTA_TEMPLATES',
          payload: quotaTemplates
            .map((template) => (template.id == data.id ? data : template))
            .filter((item: IQuotaTemplate) =>
              !isFeatureEnabled(features, FEATURES.SECTIONS)
                ? item.details.every((detail: IQuotaTemplateDetail) => detail.section_id === null)
                : true,
            )
            .sort((a: IQuotaTemplate, b: IQuotaTemplate) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        });
      })
      .catch((err) => {
        if (values.details && values.details.length == 0) {
          message.error(t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.EMPTY_ERROR_MESSAGE'));
        } else {
          handleError(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeTab = (key: string) => {
    setActiveKey(key);
    const data = form.getFieldsValue();

    if (data) {
      setNewValues((prevValues: any) => {
        form.setFieldsValue(prevValues);
        const newValues = { ...prevValues };
        if (data.details) {
          if (newValues.details) {
            Object.keys(data.details).forEach((key) => {
              newValues.details[key] = data.details[key];
            });
          } else {
            newValues.details = { ...data.details };
          }
        }

        return newValues;
      });
    }
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewValues({ ...newValues, name: value });
  };

  return (
    <div className={className}>
      <QuotaTemplateBreadcrumb activeTemplate={activeTemplate} />
      <div className="container">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item className="name-container" name="name" label="Name" rules={[{ required: true }]}>
            <Input
              placeholder={t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.TEMPLATE_NAME')}
              size="large"
              allowClear
              onChange={onChangeTitle}
            />
          </Form.Item>
          {isFeatureEnabled(features, FEATURES.SECTIONS) ? (
            <Tabs onChange={onChangeTab}>
              {sections.map((section) => {
                const filledSections = [...new Set(activeTemplate?.details.map((detail) => String(detail.section_id)))];
                const hasValues = filledSections.includes(section.id!);
                return (
                  <Tabs.TabPane
                    key={section.id}
                    tab={<Tab section={section} hasValues={hasValues} activeKey={activeKey} />}
                  >
                    {section.id == activeKey && (
                      <Table columns={columns} dataSource={data} rowKey="id" pagination={false} className="table" />
                    )}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          ) : (
            <Table columns={columns} dataSource={data} rowKey="id" pagination={false} className="table" />
          )}
          <Button
            className="submit-btn"
            htmlType="submit"
            size="large"
            type="primary"
            style={{ marginBottom: 16 }}
            loading={loading}
            disabled={loading}
          >
            {activeTemplate
              ? t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.SAVE_TEMPLATE')
              : t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.CREATE_TEMPLATE')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default styled(QuotaTemplate)`
  .container {
    margin-top: 25px;

    .input-cell {
      width: 50px;
      text-align: center;
      padding: 0;

      .ant-input-number {
        width: 100%;
      }

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input-wrap {
        input {
          text-align: center;
          padding: 0 !important;
        }
      }
    }

    .submit-btn {
      margin-top: 25px;
    }

    .table {
      .ant-table-thead {
        .ant-table-cell {
          padding: 10px 0;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 5px;
        }
      }
      .ant-form-item {
        margin: 0;
      }
    }
  }
`;
